import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const ScopeTwoPreferenceToggler = ({
  scopeTwoPreference,
  setScopeTwoPreference,
  ...props
}) => {
  return (
    <Form.Group {...props}>
      <Form.Select
        aria-label="preference-filter"
        size="md"
        className="py-1"
        onChange={(ev) =>
          setScopeTwoPreference(Number.parseInt(ev.target.value))
        }
        value={scopeTwoPreference}
        id="preferenceFilter"
      >
        {[
          { label: "Location-Based", value: 0 },
          { label: "Market-Based", value: 1 },
        ].map((filter) => (
          <option key={filter.value} value={filter.value}>
            {filter.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

ScopeTwoPreferenceToggler.propTypes = {
  scopeTwoPreference: PropTypes.number.isRequired,
  setScopeTwoPreference: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default ScopeTwoPreferenceToggler;
