import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import "./SiteCreate.scss";

const SiteCreate = ({ organization, onSiteCreated }) => {
  const subscribedPromises = useRef([]);

  const [autocomplete, setAutocomplete] = useState(null);

  const [cordinates, setCordinates] = useState({});

  const [libraries] = useState(["places"]);

  const [siteLocation, setSiteLocation] = useState("");
  const countryName = siteLocation?.address_components?.find((component) =>
    component?.types?.includes("country")
  );

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries,
  });

  const handleClose = () => {
    setShow(false);
    setSiteLocation();
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required("This is a required field"),
    description: yup.string().min(2, "Too Short!").max(256, "Too Long!"),
    address: yup.string().required("This is a required field"),
  });

  const createSite = ({ name, description }) => {
    setError(null);
    setIsLoading(true);

    const sitePromise = post(`organizations/${organization.id}/sites`, {
      name: name,
      description: description,
      address: siteLocation?.formatted_address,
      latitude: cordinates.lat,
      longitude: cordinates.lng,
      country: countryName?.long_name,
    });

    sitePromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onSiteCreated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(sitePromise);
  };

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    const lat = autocomplete.getPlace().geometry.location.lat();
    const lng = autocomplete.getPlace().geometry.location.lng();
    const siteAddress = autocomplete.getPlace();
    setSiteLocation(siteAddress);
    setCordinates({ lat, lng });
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        size="sm"
        id="bottom-up-new-site"
      >
        New Site
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Site</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createSite(values)}
          initialValues={{
            name: "",
            description: "",
            address: "",
            country: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="name">
                  <Form.Label>
                    Name <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    placeholder="Enter site's name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="organizationDescription"
                  className="my-3"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={values.description}
                    placeholder="Enter site description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
                {isLoaded && (
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <Form.Group controlId="siteLocation" className="my-3">
                      <Form.Label>
                        Site Location{" "}
                        <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="address"
                        placeholder="Please enter city or country name and then select it from the drop-down list"
                        value={
                          siteLocation === ""
                            ? values.address
                            : siteLocation?.formatted_address
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setSiteLocation("");
                        }}
                        onBlur={handleBlur}
                        isValid={values.address}
                        isInvalid={errors.address && touched.address}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Autocomplete>
                )}
                <Form.Group controlid="siteCountry" className="my-3">
                  <Form.Label>
                    Country <sup className="text-danger ps-1 fs-3 top-0">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    disabled
                    value={
                      siteLocation === ""
                        ? values.country
                        : countryName?.long_name
                    }
                    placeholder="Country name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={countryName?.long_name}
                    isInvalid={errors.country && touched.country}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  disabled={!isValid}
                  id="create-new-site"
                >
                  Create
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

SiteCreate.propTypes = {
  onSiteCreated: PropTypes.func.isRequired,
};

export default SiteCreate;
