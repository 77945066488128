import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { post } from "utils/DeApi";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

const CustomGroupsCreate = ({ fetchCustomGroups, filteredColors }) => {
  const { organizationId } = useParams();

  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();
  const [onSuccess, setOnSuccess] = useState(false);

  const subscribedPromises = useRef([]);

  const colors = [
    "#0A2B14",
    "#019A20",
    "#446D5D",
    "#82A78D",
    "#5BBE00",
    "#00ffbc",
  ];

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required("Name is a required field"),
  });

  const handleClose = () => {
    setShow(false);
    setOnSuccess(false);
    setError(null);
  };
  const handleShow = () => setShow(true);

  const createCustomGroup = ({ name, color }) => {
    setError(null);
    setIsLoading(true);

    const customGroupPromise = post(
      `organizations/${organizationId}/opportunity-groups`,
      {
        name: name,
        color: color,
      }
    );

    customGroupPromise.promise
      .then((response) => {
        setError(null);
        setOnSuccess(true);
        setIsLoading(false);
        fetchCustomGroups();
        handleClose();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(customGroupPromise);
  };

  return (
    <>
      <Button size="sm" className="" onClick={handleShow}>
        New Opportunity Grouping
      </Button>

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Opportunity Grouping</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => createCustomGroup(values)}
          initialValues={{
            name: "",
            color: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="groupNName">
                  <Form.Label>
                    Name <sup className="text-danger ps-1 fs-3 top-0">*</sup>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter opportunity grouping name"
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                {colors
                  .map((item) => filteredColors.includes(item))
                  .every(Boolean) ? (
                  <Alert variant="info" className="mt-3">
                    Default colors are in use.
                  </Alert>
                ) : (
                  <Form.Group className="my-2" controlId="formColorInput">
                    <Form.Label>Choose Color</Form.Label>
                    <div
                      id="color-popover"
                      className="border border p-2 rounded"
                    >
                      <div className="d-flex">
                        {colors
                          .filter((color) => !filteredColors?.includes(color))
                          .map((color) => (
                            <div
                              key={color}
                              className="mx-1"
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "5px",
                                backgroundColor: color,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setFieldValue("color", color);
                              }}
                              role="button"
                              tabIndex={0}
                              onKeyPress={(e) => {
                                // Handle keyboard events if needed
                                if (e.key === "Enter" || e.key === " ") {
                                  setFieldValue("color", color);
                                }
                              }}
                            ></div>
                          ))}
                      </div>
                    </div>
                  </Form.Group>
                )}

                <Button
                  size="sm"
                  className="mt-2 border-white"
                  style={{
                    backgroundColor: values.color ? values.color : "",
                  }}
                  onClick={() => setFieldValue("color", "")}
                >
                  <span className="material-icons-outlined fs-4 me-1">
                    restart_alt
                  </span>
                  Reset Color
                </Button>

                {isLoading && <Loader />}
                {error && (
                  <ErrorHandler
                    message={error?.data?.message}
                    showMsgOnly={true}
                  />
                )}
                {onSuccess && (
                  <Alert className="mt-2" variant="info">
                    Opportunity Grouping Created Successfully
                  </Alert>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  disabled={!isValid || isLoading}
                >
                  Create Opportunity Grouping
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CustomGroupsCreate;
