import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Alert, ButtonGroup } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import { getAllYearsBetweenDates } from "utils/dateUtils";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const CreateUpdateGWPModel = ({
  organization,
  gwpModels,
  onGWPModelUpdated,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [latestYear] = useState((new Date().getFullYear() - 1).toString());

  const [currentAR, setCurrentAR] = useState();

  const [isDisableBtn, setIsDisableBtn] = useState(true);

  const handleClose = () => {
    setError(null);
    setIsLoading(false);
    setShow(false);
    setIsDisableBtn(true);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    year: yup.string().required("Reporting year is required"),
    gwpModel: yup.string().required("GWP Basis is required"),
  });

  useEffect(() => {
    setCurrentAR(
      organization.gwpModels?.find(
        (model) => Number.parseInt(model.year) === Number.parseInt(latestYear)
      )
    );
  }, [organization.gwpModels, show]);

  const updateAccountGwpPreference = useCallback(
    ({ gwpModel, year }) => {
      setError(null);
      setIsLoading(true);

      const updateGwpPreference = post(
        `/organizations/${organization.id}/gwp-preference`,
        {
          gwpModelId: gwpModel,
          year: `${year}-12-31`,
        }
      );

      updateGwpPreference.promise
        .then((response) => {
          onGWPModelUpdated(response.data);
          handleClose();
          setIsDisableBtn(true);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
    },
    [organization, onGWPModelUpdated]
  );

  return (
    <>
      <Button
        variant="primary"
        id="updateGWPBasisBtn"
        onClick={handleShow}
        size="sm"
      >
        Update GWP Basis
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Update GWP Basis for <i>{organization.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateAccountGwpPreference(values)}
          initialValues={{
            year: latestYear,
            gwpModel: organization.gwpModels?.find(
              (model) =>
                Number.parseInt(model.year) === Number.parseInt(latestYear)
            )?.id,
          }}
          validateOnMount
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlId="year">
                  <Form.Label>Reporting Year</Form.Label>
                  <Form.Select
                    name="year"
                    value={values.year}
                    onChange={(e) => {
                      setFieldValue("year", e.target.value);

                      setFieldValue(
                        "gwpModel",
                        organization.gwpModels?.find(
                          (model) =>
                            Number.parseInt(model.year) ===
                            Number.parseInt(e.target.value)
                        )?.id
                      );
                      setCurrentAR(
                        organization.gwpModels?.find(
                          (model) =>
                            Number.parseInt(model.year) ===
                            Number.parseInt(e.target.value)
                        )
                      );
                    }}
                    onBlur={handleBlur}
                    isValid={values.year}
                    isInvalid={errors.year && touched.year}
                  >
                    <option value={""} disabled>
                      Select from options
                    </option>
                    {getAllYearsBetweenDates().map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.year}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-4">
                  <Form.Label controlid="gwpModel">
                    Select GWP Basis to use for this year
                  </Form.Label>
                  <Form.Select
                    name="gwpModel"
                    aria-label="organization-gwp"
                    size="md"
                    className="py-1"
                    value={values.gwpModel}
                    onChange={(e) => {
                      handleChange(e);
                      setIsDisableBtn(true);
                    }}
                    onBlur={handleBlur}
                    isValid={values.gwpModel}
                    isInvalid={errors.gwpModel && touched.gwpModel}
                  >
                    <option value={""} disabled>
                      Select from options
                    </option>
                    {gwpModels.map((model) => (
                      <option key={model.id} value={model.id}>
                        {model.name} - {model.description}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.gwpModel}
                  </Form.Control.Feedback>

                  {currentAR?.id !== values.gwpModel && (
                    <WarningAlert
                      gwpModels={gwpModels}
                      values={values}
                      defaultData={currentAR?.name}
                      isDisableBtn={isDisableBtn}
                      setIsDisableBtn={setIsDisableBtn}
                      handleClose={handleClose}
                    />
                  )}
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  id="cancelGWPBasisBtn"
                  size="sm"
                  variant="link"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  id="updateGWPBasisBtn"
                  onClick={handleSubmit}
                  size="sm"
                  disabled={!isValid || isDisableBtn}
                >
                  Update GWP Basis{" "}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

function WarningAlert({
  defaultData,
  gwpModels,
  values,
  isDisableBtn,
  setIsDisableBtn,
  handleClose,
}) {
  return (
    <div className="mt-3 mb-3">
      <p className="text-danger">
        There is inventory data using {defaultData} for reporting year{" "}
        {values.year}. If you change the GWP basis to{" "}
        {gwpModels?.find((item) => item.id === values.gwpModel)?.name}, you will
        need to re-calculate the existing emissions.
      </p>
      <small>Are you sure you would like to proceed?</small>
      <ButtonGroup className="mx-2">
        <Button
          variant={isDisableBtn ? "outline-danger" : "danger"}
          id="yesProceedBtn"
          size="sm"
          onClick={() => setIsDisableBtn(false)}
        >
          Yes
        </Button>
        <Button
          className="ms-2"
          id="noProceedBtn"
          variant="outline-primary"
          size="sm"
          onClick={handleClose}
        >
          No
        </Button>
      </ButtonGroup>
    </div>
  );
}

WarningAlert.propTypes = {
  defaultData: PropTypes.string.isRequired,
  gwpModels: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired,
  isDisableBtn: PropTypes.bool.isRequired,
  setIsDisableBtn: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

CreateUpdateGWPModel.propTypes = {
  organization: PropTypes.object.isRequired,
  gwpModels: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGWPModelUpdated: PropTypes.func.isRequired,
};

export default CreateUpdateGWPModel;
