import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Alert, Dropdown } from "react-bootstrap";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams, useLocation } from "react-router-dom";
import { getMergedSTBi } from "../../forecastingModels";
import SBTiOverviewChart from "../SBTiOverviewChart/SBTiOverviewChart";

const SBTiOverview = ({
  organization,
  breadcrumbs,
  scopeOne = 0,
  scopeTwo = 0,
  scopeThree = 0,
  sbtiConfig,
  isTargetUpdate,
  isShow,
  onChartRendered,
  orgTargetEmissions,
  setIsTargetUpdate,
}) => {
  const [emission, setEmission] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedScope, setSelectedScope] = useState("Scope 1+2+3");
  const [selectedTarget, setSelectedTarget] = useState("Near-Term Targets");
  const [sbtiData, setSbtiData] = useState([]);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();
  const location = useLocation();
  const currentType = decodeURIComponent(location?.pathname?.split("=")?.[1]);
  const currentPath = window.location.pathname.split("/").pop();

  useEffect(() => {
    const fetchEmission = (organizationId) => {
      setError(null);
      setIsLoading(true);
      const organizationPromise = get(
        `organizations/${organizationId}/science-based-target`
      );
      organizationPromise.promise
        .then((response) => {
          setIsLoading(false);
          const data = response.data || [];
          const filteredData = data.at(0) || {};
          setEmission(filteredData);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(organizationPromise);
    };

    // if (isTargetUpdate && currentPath === isTargetUpdate?.path) {
    fetchEmission(organizationId);
    // } else {
    //   const filteredData = orgTargetEmissions || {};
    //   setIsTargetUpdate();
    //   setEmission(filteredData);
    // }
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organizationId,
    scopeOne,
    scopeTwo,
    scopeThree,
    currentType,
    isTargetUpdate,
  ]);

  useEffect(() => {
    const _scopeOne = selectedScope === "Scope 3" ? 0 : scopeOne;
    const _scopeTwo = selectedScope === "Scope 3" ? 0 : scopeTwo;
    const _scopeThree = selectedScope === "Scope 1+2" ? 0 : scopeThree;

    let sbtiEmissions = {};
    let sbtiEmissionsThree = {};

    sbtiEmissions = {
      limitReductionPercentage: emission?.limitReductionPercentage,
      reductionPercentageOne: emission?.reductionPercentageOne,
      reductionPercentageTwo: emission?.reductionPercentageTwo,
      data: emission?.data,
    };

    sbtiEmissionsThree = {
      limitReductionPercentage: emission?.sopeThreeLimitReductionPercentage,
      reductionPercentageOne: emission?.sopeThreeReductionPercentageOne,
      reductionPercentageTwo: emission?.sopeThreeReductionPercentageTwo,
      data: emission?.scopeThreeData,
    };

    const scienceBasedTargetChunk = getMergedSTBi(
      sbtiEmissions,
      _scopeOne,
      _scopeTwo,
      0
    );

    const scienceBasedTargetChunkScopeThree = getMergedSTBi(
      sbtiEmissionsThree,
      0,
      0,
      _scopeThree
    );

    if (selectedScope === "Scope 1+2+3") {
      const targetSettingData = [];

      for (let index = 0; index < scienceBasedTargetChunk.length; index++) {
        const element = scienceBasedTargetChunk[index];
        const elementThree = scienceBasedTargetChunkScopeThree[index];
        let obj = {};
        for (const key in element) {
          if (element.hasOwnProperty.call(element, key)) {
            const val = element[key];
            const val2 = elementThree[key];
            if (
              key !== "percentageReduction" &&
              key !== "percentageReduction25" &&
              key !== "year" &&
              key !== "year25"
            ) {
              obj[key] = val + val2;
            } else {
              obj[key] = val;
            }
          }
        }
        targetSettingData.push(obj);
      }
      setSbtiData(targetSettingData);
    } else if (selectedScope === "Scope 3") {
      setSbtiData(scienceBasedTargetChunkScopeThree);
    } else {
      setSbtiData(scienceBasedTargetChunk);
    }
  }, [organizationId, emission, scopeOne, scopeTwo, scopeThree, selectedScope]);

  const memoizedConfig = useMemo(() => {
    if (sbtiConfig) {
      return JSON.parse(sbtiConfig)?.filter(
        (data) => data?.type === currentType
      );
    } else {
      return [];
    }
  }, [sbtiConfig]);

  return (
    <div>
      {isShow && (
        <div className="my-3">
          <BreadCrumbs
            breadcrumbs={[
              ...breadcrumbs,
              {
                name: "sbti-overview",
                link: `/organizations/${organization.id}/sbti-overview`,
                active: true,
              },
            ]}
          />
        </div>
      )}
      {isShow && <h2 className="mb-4 pt-2">Overview</h2>}
      {!isLoading && !error && (emission?.sbtiData || emission?.settings) && (
        <div className="my-3 p-3 border">
          <h3 className="mb-4">
            {isShow ? "Targets" : "SBTi Overview"}
            <Dropdown className="float-end me-3">
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                size="sm"
                className="bg-primary bg-opacity-25 text-dark border-0"
              >
                {selectedScope === "All"
                  ? "Filter by Scope 1+2+3"
                  : `Filter by ${selectedScope}`}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[
                  "Scope 1+2+3",
                  "Scope 1+2",
                  "Scope 1",
                  "Scope 2",
                  "Scope 3",
                ].map((scope, index) => (
                  <Dropdown.Item
                    key={index}
                    className={selectedScope === scope ? "active" : ""}
                    onClick={() =>
                      setSelectedScope((prev) => {
                        if (prev === scope) return scope;

                        return scope;
                      })
                    }
                  >
                    {scope}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="float-end me-3">
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                size="sm"
                className="bg-primary bg-opacity-25 text-dark border-0"
              >
                Filter by {selectedTarget}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {["Near-Term Targets", "Net-Zero Targets"].map(
                  (target, index) => (
                    <Dropdown.Item
                      key={index}
                      className={selectedTarget === target ? "active" : ""}
                      onClick={() =>
                        setSelectedTarget((prev) => {
                          if (prev === target) return target;

                          return target;
                        })
                      }
                    >
                      {target}
                    </Dropdown.Item>
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          </h3>
          <hr />
          <SBTiOverviewChart
            data={sbtiData}
            sbtiConfig={memoizedConfig}
            emission={emission}
            selectedTarget={selectedTarget}
            selectedScope={selectedScope}
            onChartRendered={onChartRendered}
          />
        </div>
      )}
      {error && !isLoading && <ErrorHandler error={error} />}
      {isLoading && <Loader />}
      {!error && !isLoading && (!emission?.sbtiData || !emission?.settings) && (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
    </div>
  );
};

SBTiOverview.propTypes = {
  organization: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  scopeOne: PropTypes.number,
  scopeTwo: PropTypes.number,
  scopeThree: PropTypes.number,
};

export default SBTiOverview;
