import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Link, useParams } from "react-router-dom";
import { Alert, Form, Col, Row, Dropdown, Table } from "react-bootstrap";

import { get } from "utils/DeApi";
import { getFormattedEmission } from "utils/StringUtils";
import { getAllYearsBetweenDates } from "utils/dateUtils";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";

import PortfolioExport from "../PortfolioExport/PortfolioExport";
import PortfolioOrganizationsChart from "./PortfolioOrganizationsChart/PortfolioOrganizationsChart";
import PortfolioOrganizationList from "./PortfolioOrganizationList/PortfolioOrganizationList";
import PortfolioOrganizationUpdate from "./PortfolioOrganizationUpdate/PortfolioOrganizationUpdate";
import { AccountContext } from "contexts/AccountProvider";
import PortfolioOrgNzifChart from "./PortfolioOrgNzifChart/PortfolioOrgNzifChart";
import ScopeTwoPreferenceToggler from "components/Organization/Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";
import { getContentId } from "utils/contentUtil";
import PortfolioOrgScopeEmissions from "./PortfolioOrgScopeEmissionsChart/PortfolioOrgScopeEmissionsChart";

function PortfolioDetail() {
  const subscribedPromises = useRef([]);

  const [portfolio, setPortfolio] = useState();
  const [totalEmissions, setTotalEmissions] = useState();
  const [nzifStatistics, setNzifStatistics] = useState([]);
  const [portfolioOrganizationChart, setPortfolioOrganizationChart] =
    useState();
  const [portfolioOrganizationScopeChart, setPortfolioOrganizationScopeChart] =
    useState();
  const [scopeTwoPreference, setScopeTwoPreference] = useState(0);
  const [selectedScope, setSelectedScope] = useState("Scope 1+2+3");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [emissionYear, setEmissionYear] = useState(
    new Date().getFullYear() - 1
  );
  const params = useParams();
  const { portfolioId } = params;
  const account = useContext(AccountContext);

  const fetchPortfolioDetails = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const portfolioPromise = get(`portfolios/${portfolioId}`, {
      params: {
        yearEnded: emissionYear + "-12-31",
        "filter[preference]": scopeTwoPreference,
      },
    });
    const statisticPromise = get("/nzif-statistics", {
      params: {
        yearEnded: emissionYear + "-12-31",
        accountId: account.id,
        portfolioId: portfolioId,
      },
    });

    Promise.all([portfolioPromise.promise, statisticPromise.promise])
      .then((responses) => {
        setPortfolio(responses[0].data);
        setNzifStatistics(responses[1].data);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(portfolioPromise, statisticPromise);
  }, [portfolioId, emissionYear, scopeTwoPreference]);

  useEffect(() => {
    fetchPortfolioDetails();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchPortfolioDetails]);

  useEffect(() => {
    const orgSummary = portfolio?.organizations
      ?.map((org) => org?.summary)
      ?.flat();

    const totalEmissions = orgSummary?.reduce(
      (acc, curr) => {
        acc.scopeOneEmissions += curr.scopeOneEmissions;
        acc.scopeTwoEmissions += curr.scopeTwoEmissions;
        acc.scopeThreeEmissions += curr.scopeThreeEmissions;
        return acc;
      },
      {
        scopeOneEmissions: 0,
        scopeTwoEmissions: 0,
        scopeThreeEmissions: 0,
      }
    );
    setTotalEmissions(totalEmissions);
  }, [portfolio]);

  return (
    <>
      {isLoading && <Loader />}
      {error && <ErrorHandler error={error} />}
      {portfolio && !error && !isLoading && (
        <>
          <div className="my-3">
            <BreadCrumbs
              breadcrumbs={[
                { name: "Dashboard", link: "/" },
                {
                  name: "Portfolios",
                  link: `/portfolios`,
                },
                {
                  name: `${portfolio.name}`,
                  link: `/portfolios/${portfolio.id}`,
                  active: true,
                },
              ]}
            />
          </div>

          <div>
            <span className="float-end">
              <PortfolioOrganizationUpdate
                portfolio={portfolio}
                portfolioOrganizations={portfolio.organizations}
                onPortfolioOrganizationUpdated={fetchPortfolioDetails}
                emissionYear={emissionYear}
              />
            </span>

            <h2>{portfolio.name}</h2>
            <p>{portfolio.description}</p>
            <hr />
          </div>
          <div className="text-end">
            <div className="d-inline-flex me-2">
              <ScopeTwoPreferenceToggler
                scopeTwoPreference={scopeTwoPreference}
                setScopeTwoPreference={setScopeTwoPreference}
              />
            </div>
            <div className="d-inline-flex">
              <Form.Label className="text-nowrap pt-2 me-1">
                <small>Reporting Year</small>
              </Form.Label>
              <Form.Select
                aria-label="year-filter"
                size="sm"
                className="ps-2"
                value={emissionYear}
                onChange={(ev) => {
                  let selectedYear = ev.target.value;
                  setEmissionYear(selectedYear);
                }}
                id="yearFilter"
              >
                {getAllYearsBetweenDates().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </div>{" "}
            <div className="d-inline-flex align-items-center my-3 px-1 pb-1 bg-info text-nowrap text-primary bg-opacity-10 ">
              <span className="fs-3 mx-1">{portfolio?.organizationsCount}</span>{" "}
              Organizations{" "}
            </div>
            <div className="d-inline-flex align-items-center mx-1 my-3 px-1 pb-1 bg-danger text-danger bg-opacity-10 text-nowrap">
              <span className="fs-3 mx-1">
                {getFormattedEmission(portfolio?.totalFinancedEmissions)}
              </span>
              &nbsp;
              <small className="">
                tCO<sub>2</sub>e
              </small>{" "}
              {emissionYear} Emissions
            </div>
            <div className="d-inline-flex align-items-center text-nowrap my-3 mx-1">
              <PortfolioExport
                portfolioOrganizationChart={portfolioOrganizationChart}
                portfolioOrganizationScopeChart={
                  portfolioOrganizationScopeChart
                }
                portfolio={portfolio}
                emissionYear={emissionYear}
                scopeTwoPreference={scopeTwoPreference}
                contentId={getContentId("portfolio")}
              />
            </div>
          </div>
          {!portfolio.organizationsCount ? (
            <Alert variant="info">
              There are currently no organizations in this portfolio.
            </Alert>
          ) : (
            <>
              <div className="d-flex">
                <Col md={6} className="p-3 me-2 my-3 border text-center">
                  <h4 className="my-3">
                    {emissionYear} Financed Emissions by Portfolio Organization
                  </h4>
                  <h5>&nbsp;</h5>
                  <PortfolioOrganizationsChart
                    organizations={portfolio?.organizations}
                    emissionYear={emissionYear}
                    onChartRendered={(chart) =>
                      setPortfolioOrganizationChart(chart)
                    }
                  />
                </Col>
                <Col md={6} className="p-3 my-3 border text-center">
                  <h4 className="my-3">
                    {emissionYear} NZIF Alignment for {portfolio?.name}
                  </h4>
                  <h5>
                    (Total AUM: USD{" "}
                    {getFormattedEmission(nzifStatistics?.totalAum || 0)})
                  </h5>
                  <PortfolioOrgNzifChart
                    nzifStatistics={nzifStatistics?.nzifCategories}
                  />
                </Col>
              </div>
              <Col md={12} className="p-3 border">
                <Row>
                  <Col md={11} className="fs-4 text-center">
                    Attributable Emissions from Portfolio Organizations by Scope
                  </Col>
                  <Col md={1}>
                    <Dropdown className="float-end">
                      <Dropdown.Toggle
                        variant="light"
                        id="dropdown-basic"
                        size="sm"
                        className="bg-primary bg-opacity-25 text-dark border-0"
                      >
                        {selectedScope === "All"
                          ? "Filter by Scope 1+2+3"
                          : `Filter by ${selectedScope}`}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {[
                          "Scope 1+2+3",
                          "Scope 1+2",
                          "Scope 1",
                          "Scope 2",
                          "Scope 3",
                        ].map((scope, index) => (
                          <Dropdown.Item
                            key={index}
                            className={selectedScope === scope ? "active" : ""}
                            onClick={() =>
                              setSelectedScope((prev) => {
                                if (prev === scope) return scope;

                                return scope;
                              })
                            }
                          >
                            {scope}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                <Col md={6} className="mx-auto">
                  <PortfolioOrgScopeEmissions
                    portfolio={[portfolio]}
                    selectedScope={selectedScope}
                    onChartRendered={(chart) =>
                      setPortfolioOrganizationScopeChart(chart)
                    }
                  />
                </Col>
              </Col>
              <PortfolioOrganizationList
                portfolio={portfolio}
                emissionYear={emissionYear}
                fetchPortfolioDetails={fetchPortfolioDetails}
              />
              <h3 className="mt-4">
                Attributable Emissions from Portfolio Organizations by Scope
              </h3>
              <hr />
              <Table
                hover
                size="sm"
                responsive
                className="mb-3 first-column-fixed"
              >
                <thead>
                  <tr>
                    <th>Organization</th>
                    <th className="text-end">Scope 1</th>
                    <th className="text-end">Scope 2</th>
                    <th className="text-end">Scope 3</th>
                    <th className="text-end">Total Attributable Emissions</th>
                  </tr>
                </thead>
                <tbody>
                  {portfolio?.organizations?.map((org) => (
                    <tr key={org?.id}>
                      <td
                        className="text-nowrap text-truncate align-middle"
                        title={org?.name}
                      >
                        <Link
                          className="text-decoration-none"
                          to={`/organizations/${org.id}`}
                        >
                          {org.name}
                        </Link>
                      </td>
                      <td className="text-end align-middle">
                        {getFormattedEmission(
                          org?.summary?.[0]?.scopeOneEmissions || 0
                        )}
                      </td>
                      <td className="text-end align-middle">
                        {getFormattedEmission(
                          org?.summary?.[0]?.scopeTwoEmissions || 0
                        )}
                      </td>
                      <td className="text-end align-middle">
                        {getFormattedEmission(
                          org?.summary?.[0]?.scopeThreeEmissions || 0
                        )}
                      </td>
                      <td className="text-end align-middle">
                        {getFormattedEmission(
                          (org?.summary?.[0]?.scopeOneEmissions || 0) +
                            (org?.summary?.[0]?.scopeTwoEmissions || 0) +
                            (org?.summary?.[0]?.scopeThreeEmissions || 0)
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-nowrap px-3">Total</td>
                    <td className="text-end align-middle">
                      {getFormattedEmission(
                        totalEmissions?.scopeOneEmissions || 0
                      )}
                    </td>
                    <td className="text-end align-middle">
                      {getFormattedEmission(
                        totalEmissions?.scopeTwoEmissions || 0
                      )}
                    </td>
                    <td className="text-end align-middle">
                      {getFormattedEmission(
                        totalEmissions?.scopeThreeEmissions || 0
                      )}
                    </td>
                    <td className="text-end align-middle">
                      {getFormattedEmission(
                        (totalEmissions?.scopeOneEmissions || 0) +
                          (totalEmissions?.scopeTwoEmissions || 0) +
                          (totalEmissions?.scopeThreeEmissions || 0)
                      )}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </>
          )}
        </>
      )}
      <div className="border my-3 p-3">
        <ContentDetails contentId={getContentId("portfolio")} view={"full"} />
      </div>
    </>
  );
}

export default PortfolioDetail;
