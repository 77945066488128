import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import "./ErrorHandler.scss";

const ErrorHandler = ({ error, message, showMsgOnly, className = "" }) => {
  const [status, setStatus] = useState("");
  const [statusText, setStatusText] = useState("Oh snap! You got an error!");
  const [errorMsg, setErrorMsg] = useState(
    "Something unexpected happened. Please try again."
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      if (error.status) {
        setStatus(error.status);
      }
      if (error.statusText) {
        setStatusText(error.statusText);
      }

      let allowedPaths = ["/login", "/two-factor-code"];

      if (
        error.status === 401 &&
        allowedPaths.indexOf(location.pathname) === -1
      ) {
        navigate("/logout");
      }
      if (error.data && error.data.error) {
        if ("message" in error.data.error) {
          setErrorMsg(error.data.error.message);
        } else {
          setErrorMsg(error.data.error[Object.keys(error.data.error)[0]]);
        }
      }
    }
  }, [error]);
  if (status === 403)
    return (
      <Alert variant="success" className={`bg-white mt-5 mb-3 ${className}`}>
        <h4>Unauthorized</h4>
        <p>Sorry, you do not have access rights!</p>
        <small>{status}</small>
      </Alert>
    );
  if (showMsgOnly)
    return (
      <Alert variant="warning" className={`mt-3 mb-3 ${className}`}>
        <p>{message}</p>
      </Alert>
    );
  else
    return (
      <Alert variant="warning" className={`mt-3 mb-3 ${className}`}>
        <h4>{statusText}</h4>
        <p>{message || errorMsg}</p>
        <small>{status}</small>
      </Alert>
    );
};

export default ErrorHandler;
