import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, ButtonGroup } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import { getAllYearsBetweenDates } from "utils/dateUtils";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const CreateUpdateGWPModel = ({ account, gwpModels, onGWPModelUpdated }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [currentAR, setCurrentAR] = useState();

  const [isDisableBtn, setIsDisableBtn] = useState(true);

  const handleClose = () => {
    setError(null);
    setIsLoading(false);
    setShow(false);
    setIsDisableBtn(true);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    year: yup.string().required("Reporting year is required"),
    gwpModel: yup.string().required("GWP Basis is required"),
    shouldUpdateAllOrgs: yup.bool(),
  });

  useEffect(() => {
    setCurrentAR(
      account.gwpModels?.find((model) => Number.parseInt(model.year) === 2022)
    );
  }, [account.gwpModels, show]);

  const updateAccountGwpPreference = useCallback(
    ({ gwpModel, year, shouldUpdateAllOrgs }) => {
      setError(null);
      setIsLoading(true);

      const updateGwpPreference = post(
        `/accounts/${account.id}/gwp-preference`,
        {
          gwpModelId: gwpModel,
          year: `${year}-12-31`,
          shouldUpdateAllOrgs,
        }
      );

      updateGwpPreference.promise
        .then((response) => {
          onGWPModelUpdated(response.data);
          setIsDisableBtn(true);
          handleClose();
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
    },
    [account, onGWPModelUpdated]
  );

  return (
    <>
      <Button
        id="updateGWPBasisRedirectBtn"
        variant="primary"
        onClick={handleShow}
        size="sm"
      >
        Update GWP Basis
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Update GWP Basis for <i>{account.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateAccountGwpPreference(values)}
          initialValues={{
            year: "2022",
            gwpModel: account.gwpModels?.find(
              (model) => Number.parseInt(model.year) === Number.parseInt("2022")
            )?.id,
            shouldUpdateAllOrgs: false,
          }}
          validateOnMount
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlid="year">
                  <Form.Label>Reporting Year</Form.Label>
                  <Form.Select
                    name="year"
                    value={values.year}
                    onChange={(e) => {
                      setFieldValue("year", e.target.value);
                      setFieldValue(
                        "gwpModel",
                        account.gwpModels.find(
                          (model) =>
                            Number.parseInt(model.year) ===
                            Number.parseInt(e.target.value)
                        )?.id
                      );
                      setCurrentAR(
                        account.gwpModels.find(
                          (model) =>
                            Number.parseInt(model.year) ===
                            Number.parseInt(e.target.value)
                        )
                      );
                    }}
                    onBlur={handleBlur}
                    isValid={values.year}
                    isInvalid={errors.year && touched.year}
                  >
                    <option value={""} disabled>
                      Select from options
                    </option>
                    {getAllYearsBetweenDates().map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.year}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-4">
                  <Form.Label controlid="gwpModel">
                    Select GWP Basis to use for this year
                  </Form.Label>
                  <Form.Select
                    name="gwpModel"
                    aria-label="account-gwp"
                    size="md"
                    className="py-1"
                    value={values.gwpModel}
                    onChange={(e) => {
                      handleChange(e);
                      setIsDisableBtn(true);
                    }}
                    onBlur={handleBlur}
                    isValid={values.gwpModel}
                    isInvalid={errors.gwpModel && touched.gwpModel}
                  >
                    <option value={""} disabled>
                      Select from options
                    </option>
                    {gwpModels.map((model) => (
                      <option key={model.id} value={model.id}>
                        {model.name} - {model.description}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.gwpModel}
                  </Form.Control.Feedback>

                  {currentAR?.id !== values.gwpModel && (
                    <WarningAlert
                      gwpModels={gwpModels}
                      values={values}
                      defaultData={currentAR?.name}
                      isDisableBtn={isDisableBtn}
                      setIsDisableBtn={setIsDisableBtn}
                      handleClose={handleClose}
                      accountName={account.name}
                      setShouldUpdateAllOrgs={(newValue) =>
                        setFieldValue("shouldUpdateAllOrgs", newValue)
                      }
                    />
                  )}
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  id="cancelGWPBasisBtn"
                  size="sm"
                  variant="link"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  id="updateGWPBasisBtn"
                  onClick={handleSubmit}
                  size="sm"
                  disabled={!isValid || isDisableBtn}
                >
                  Update GWP Basis{" "}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

function WarningAlert({
  defaultData,
  gwpModels,
  values,
  isDisableBtn,
  setIsDisableBtn,
  handleClose,
  accountName,
  setShouldUpdateAllOrgs,
}) {
  const gwpModelName = gwpModels?.find(
    (item) => item.id === values.gwpModel
  )?.name;
  return (
    <div className="mt-3 mb-3">
      <p className="text-danger">
        There is inventory data using {defaultData} for reporting year{" "}
        {values.year}. If you change the GWP basis to {gwpModelName}, you will
        need to re-calculate the existing emissions.
      </p>
      <small>Are you sure you would like to proceed?</small>
      <ButtonGroup className="mx-2">
        <Button
          id="yesBtn"
          variant={isDisableBtn ? "outline-danger" : "danger"}
          size="sm"
          onClick={() => setIsDisableBtn(false)}
        >
          Yes
        </Button>
        <Button
          id="noBtn"
          className="ms-2"
          variant="outline-primary"
          size="sm"
          onClick={handleClose}
        >
          No
        </Button>
      </ButtonGroup>
      <br />
      <small>
        Update the <strong>GWP basis</strong> for all the organizations within
        the account for year <strong>{values.year}</strong>?
      </small>
      <Form.Check
        type="switch"
        className="mx-2 d-inline"
        value={values.shouldUpdateAllOrgs}
        onChange={(e) => setShouldUpdateAllOrgs(e.target.checked)}
      />
    </div>
  );
}

WarningAlert.propTypes = {
  defaultData: PropTypes.string.isRequired,
  gwpModels: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired,
  isDisableBtn: PropTypes.bool.isRequired,
  setIsDisableBtn: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  accountName: PropTypes.string.isRequired,
  setShouldUpdateAllOrgs: PropTypes.func.isRequired,
};

CreateUpdateGWPModel.propTypes = {
  account: PropTypes.object.isRequired,
  gwpModels: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGWPModelUpdated: PropTypes.func.isRequired,
};

export default CreateUpdateGWPModel;
