import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Alert, Button, Collapse, Form } from "react-bootstrap";
import { useSearchParams, useLocation } from "react-router-dom";

import { get, put } from "utils/DeApi";
import { getFormattedEmission } from "utils/StringUtils";
import { getAllYearsBetweenDates } from "utils/dateUtils";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import ReportActivity from "../ReportActivity/ReportActivity";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ActivityList from "../../ActivityList/ActivityList";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import useContents from "components/hooks/useContents";
import ScopeTwoPreferenceToggler from "../../ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";
import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";

const ScopeTwo = ({
  site,
  breadcrumbs,
  siteAccess,
  siteYearsUnderReview,
  organization,
}) => {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const activityTypeId = searchParams.get("activityCategoryId");
  const activityCategory = searchParams.get("activityCategory");

  const [activityTypes, setActivityTypes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [scopeAllEmissions, setScopeAllEmissions] = useState();

  const [yearFilter, setYearFilter] = useState(
    location?.state?.year || undefined
  );

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [helpContents, setHelpContents] = useState();
  const [openHelpContents, setOpenHelpContents] = useState(false);
  const [isContentError, setIsContentError] = useState(false);
  const contentId = useContents();

  const [isExpanding, setIsExpanding] = useState(false);
  const [expandingError, setExpandingError] = useState();
  const [showingAllActivities, setShowingAllActivities] = useState(false);
  const [offset, setOffset] = useState(0);

  const [scopeTwoPreference, setScopeTwoPreference] = useState(
    site.scopeTwoPreference
  ); // market based is preference 1 or true and location based is preference 0 or false

  const handleSetScopeTwoPreference = (choice) => {
    setError(null);
    setIsLoading(true);
    const scopeTwoPreferencePromise = put(
      `/sites/${site.id}/scope-two-preference`,
      {
        scopeTwoPreference: choice,
      }
    );
    scopeTwoPreferencePromise.promise
      .then((response) => {
        setScopeTwoPreference(response.data.scopeTwoPreference);
        fetchActivities();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const fetchActivities = useCallback(() => {
    setError(null);
    setIsLoading(true);
    setOpenHelpContents(false);

    const activitiesPromise = get(`sites/${site.id}/activities`, {
      params: {
        scope: [2],
        activityTypeId: activityTypeId,
        offset: 0,
        limit: 100,
        precalc: true,
      },
    });

    const activityTypesPromise = get(`activity-types`, {
      params: {
        scope: 2,
      },
    });

    const activitiesEmissions = get("total-emissions-activities", {
      params: {
        organizationId: organization?.id,
        siteId: site?.id,
        activityTypeId: activityTypeId,
        ...(yearFilter && { year: yearFilter }),
        scope: 2,
      },
    });

    Promise.all([
      activitiesPromise.promise,
      activityTypesPromise.promise,
      activitiesEmissions.promise,
    ])
      .then((responses) => {
        setActivities(responses[0].data);
        setOffset((prevState) => prevState + responses[0].data.length);
        setScopeAllEmissions(responses[2]?.totalEmissions);
        setActivityTypes(responses[1].data);

        setIsLoading(false);
        setError(null);
        if (responses[0].data.length < 100) {
          setShowingAllActivities(true);
        } else {
          setShowingAllActivities(false);
        }
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(activitiesPromise, activityTypesPromise);
  }, [activityTypeId, site.id]);

  const fetchYearEmissions = () => {
    setError(null);
    setIsLoading(true);

    const activitiesEmissionsPromise = get("total-emissions-activities", {
      params: {
        organizationId: organization?.id,
        siteId: site?.id,
        activityTypeId: activityTypeId,
        ...(yearFilter && { year: yearFilter }),
        scope: 2,
      },
    });
    activitiesEmissionsPromise.promise
      .then((response) => {
        setScopeAllEmissions(response?.totalEmissions);
        setError(null);
        setIsLoading(false);
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(activitiesEmissionsPromise);
  };

  const fetchYearActivities = () => {
    setError(null);
    setIsLoading(true);

    const activitiesYearPromise = get(`sites/${site.id}/activities`, {
      params: {
        scope: [2],
        activityTypeId: activityTypeId,
        offset: 0,
        limit: 100,
        precalc: true,
        ...(yearFilter && { yearEnded: yearFilter }),
      },
    });
    activitiesYearPromise.promise
      .then((response) => {
        setActivities(response.data);
        setOffset((prevState) => prevState + response.data.length);
        setError(null);
        setIsLoading(false);
        if (response.data.length < 100) {
          setShowingAllActivities(true);
        } else {
          setShowingAllActivities(false);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(activitiesYearPromise);
  };

  useEffect(() => {
    if (yearFilter || yearFilter === "") {
      fetchYearEmissions();
      fetchYearActivities();
      setShowingAllActivities(false);
      setOffset(0);
    }
  }, [yearFilter, activityTypeId]);

  const showMoreActivities = useCallback(
    (offset) => {
      setExpandingError(null);
      setIsExpanding(true);

      const params = {
        params: {
          scope: [2],
          activityTypeId: activityTypeId,
          offset: offset,
          limit: 100,
          precalc: true,
          ...(yearFilter && { yearEnded: yearFilter }),
        },
      };

      const activitiesPromise = get(`sites/${site.id}/activities`, params);

      activitiesPromise.promise
        .then((responses) => {
          setActivities((prevState) => [...prevState, ...responses.data]);
          setOffset((prevState) => prevState + responses.data.length);
          setExpandingError(null);
          setIsExpanding(false);
          if (responses.data.length < 100) {
            setShowingAllActivities(true);
          } else {
            setShowingAllActivities(false);
          }
        })

        .catch((error) => {
          if (!error.isCanceled) {
            setExpandingError(error);
            setIsExpanding(false);
          }
        });
      subscribedPromises.current.push(activitiesPromise);
    },
    [yearFilter, organization.id]
  );

  useEffect(() => {
    setYearFilter(location?.state?.year || undefined);
    fetchActivities();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchActivities, activityTypeId]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!activities || !activityTypes) return <span />;

  return (
    <div id={userCategory}>
      <DataLayer />
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: `Scope 2 - ${searchParams.get("activityCategory")}`,
              link: location.pathname + location.search,
              active: true,
            },
          ]}
        />
      </div>
      <div className="d-flex float-end">
        {siteAccess && (
          <>
            <ReportActivity
              scope={2}
              site={site}
              activityTypes={activityTypes.filter((type) =>
                type.label.includes(activityCategory)
              )}
              onActivityReported={fetchActivities}
              siteYearsUnderReview={siteYearsUnderReview}
              organization={organization}
            />
            <span className="me-1"> </span>
          </>
        )}
      </div>
      <h2
        id={`Scope2-${searchParams
          .get("activityCategory")
          .replace(/\s+/g, "")}-heading`}
      >
        Scope 2 - {searchParams.get("activityCategory")}
      </h2>

      {helpContents && (
        <>
          <p className="my-3">{helpContents?.description}</p>
          <Button
            variant="link"
            onClick={() => {
              setOpenHelpContents(!openHelpContents);
            }}
            className="p-0 mt-n3"
          >
            {helpContents.title}
          </Button>
        </>
      )}
      {!isContentError && (
        <Collapse in={openHelpContents}>
          <div>
            <Alert
              variant="dark"
              className="bg-light"
              dismissible
              onClose={() => setOpenHelpContents(!openHelpContents)}
            >
              <ContentDetails
                contentId={contentId}
                onContentFetched={setHelpContents}
                setIsContentError={setIsContentError}
              />
            </Alert>
          </div>
        </Collapse>
      )}
      <hr />

      {(() => {
        let filteredActivities = [...activities];
        return (
          <>
            <div className="text-end">
              {activityCategory
                .toLowerCase()
                .includes("electricity import") && (
                <ScopeTwoPreferenceToggler
                  scopeTwoPreference={scopeTwoPreference}
                  setScopeTwoPreference={handleSetScopeTwoPreference}
                  className="my-3 ms-2 d-inline-flex"
                />
              )}
              <div className="my-3 ms-2 d-inline-flex">
                <Form.Select
                  aria-label="year-filter"
                  size="sm"
                  value={yearFilter || ""}
                  onChange={(ev) => setYearFilter(ev.target.value)}
                >
                  <option key={"All"} value={""}>
                    All Years
                  </option>
                  {getAllYearsBetweenDates().map((filter) => (
                    <option key={filter} value={filter}>
                      {filter}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="my-3 ms-2 d-inline-flex">
                <div className="mx-1 px-3 bg-danger text-danger bg-opacity-10">
                  <span className="fs-1">
                    {getFormattedEmission(scopeAllEmissions || 0)}{" "}
                  </span>
                  <small>
                    tCO<sub>2</sub>e
                  </small>{" "}
                  Emissions
                </div>
              </div>
            </div>
            {filteredActivities?.length ? (
              <>
                <ActivityList
                  activities={filteredActivities}
                  activityTypes={activityTypes}
                  onActivityUpdated={fetchActivities}
                  siteAccess={siteAccess}
                  siteYearsUnderReview={siteYearsUnderReview}
                  isException={location?.state?.year ? true : false}
                  organization={organization}
                />
                <div className="my-3">
                  {isExpanding && <Loader />}
                  {expandingError && <ErrorHandler error={expandingError} />}
                  {!showingAllActivities && (
                    <p className="text-center">
                      <Button
                        size="sm"
                        variant="primary"
                        className="px-3 my-2 bg-opacity-25"
                        onClick={() => showMoreActivities(offset)}
                        disabled={isExpanding}
                      >
                        Show More
                      </Button>
                    </p>
                  )}
                </div>
              </>
            ) : (
              <Alert variant="info" className="my-3">
                There is currently nothing to show here.
              </Alert>
            )}
          </>
        );
      })()}
    </div>
  );
};

ScopeTwo.propTypes = {
  site: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  siteAccess: PropTypes.bool.isRequired,
  siteYearsUnderReview: PropTypes.array.isRequired,
  organization: PropTypes.object.isRequired,
};

export default ScopeTwo;
