import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Modal, Button, Form } from "react-bootstrap";

import { post, destroy, put } from "utils/DeApi";

import { AccountContext } from "contexts/AccountProvider";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

const UpdateComments = ({ attachment, onCommentsUpdated }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [comments, setComments] = useState([...attachment.comments]);

  const [showConfirmDeleteComment, setShowConfirmDeleteComment] = useState();
  const [showEditComment, setShowEditComment] = useState();
  const [selectedComment, setSelectedComment] = useState();

  const accountContext = useContext(AccountContext);

  const handleClose = () => {
    setShow(false);
    onCommentsUpdated();
  };
  const handleShow = () => setShow(true);

  const createComment = ({ comment }, resetForm) => {
    setError(null);
    setIsLoading(true);

    const createCommentPromise = post(`attachments/${attachment.id}/comments`, {
      body: comment,
    });

    createCommentPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        setComments([...comments, response.data]);
        resetForm();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const updateComment = ({ comment, commentId }, resetForm) => {
    setError(null);
    setIsLoading(true);

    const updateActivityCommentPromise = put(`comments/${commentId}`, {
      body: comment,
    });

    updateActivityCommentPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        setComments([
          ...comments.filter((cm) => cm.id !== commentId),
          response.data,
        ]);
        resetForm();
        setShowEditComment();
        setSelectedComment();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const deleteComment = (comment) => {
    setError(null);
    setIsLoading(true);

    const deleteCommentPromise = destroy(`comments/${comment.id}`);

    deleteCommentPromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        setComments([...comments.filter((cm) => cm.id !== comment.id)]);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button
        variant="outline-info"
        id="commentIconBtn"
        size="sm"
        className="py-0"
        onClick={handleShow}
      >
        <span className="material-icons md-18 material-icons-outlined">
          comment
        </span>
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="addOrUpdateComment">
            Add/Update comments for this document
          </Modal.Title>
        </Modal.Header>
        <Formik
          onSubmit={(values, { resetForm }) => createComment(values, resetForm)}
          initialValues={{ comment: "", updatedComment: "" }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            resetForm,
            setFieldValue,
          }) => (
            <Form>
              <Modal.Body>
                {comments?.map((comment) => (
                  <div
                    className="d-flex align-items-start mb-3 border-bottom pb-2"
                    key={comment.id}
                  >
                    <div
                      className="bg-dark text-white rounded-circle d-flex align-items-center justify-content-center"
                      style={{ width: "40px", height: "40px" }}
                    >
                      {comment.subscriber?.firstName?.charAt(0) || "U"}
                      {comment.subscriber?.lastName?.charAt(0) || "K"}
                    </div>
                    {showConfirmDeleteComment &&
                      selectedComment === comment.id && (
                        <div className="flex-grow-1">
                          <h3 className="px-3 mb-1">
                            Are you sure you want to delete this comment?
                          </h3>
                          <p className="ps-3">{comment.body}</p>
                          <span className="float-end">
                            <Button
                              variant="outline-secondary"
                              onClick={() => {
                                setShowConfirmDeleteComment();
                                setSelectedComment();
                              }}
                              size="sm"
                            >
                              Cancel
                            </Button>{" "}
                            <Button
                              variant="outline-danger"
                              onClick={() => deleteComment(comment)}
                              size="sm"
                            >
                              Delete
                            </Button>
                          </span>
                        </div>
                      )}
                    {showEditComment && selectedComment === comment.id && (
                      <div className="flex-grow-1">
                        <Form.Group className="ps-2 mb-1 mt-2">
                          <Form.Control
                            type="text"
                            id="commentInput"
                            name="updatedComment"
                            placeholder="Type comment.."
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.updatedComment}
                          />
                          <Form.Control.Feedback>
                            {touched.setShowEditComment &&
                              errors.setShowEditComment}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <span className="float-end">
                          <Button
                            variant="outline-secondary"
                            onClick={() => {
                              setShowEditComment();
                              setSelectedComment();
                            }}
                            size="sm"
                          >
                            Cancel
                          </Button>{" "}
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              updateComment(
                                {
                                  comment: values.updatedComment,
                                  commentId: comment.id,
                                },
                                resetForm
                              )
                            }
                            size="sm"
                            disabled={!isValid}
                          >
                            Update
                          </Button>
                        </span>
                      </div>
                    )}
                    {selectedComment !== comment.id && (
                      <div className="flex-grow-1">
                        <h3 className="px-3 mb-1">
                          {comment.subscriber?.firstName +
                            " " +
                            comment.subscriber?.lastName}
                        </h3>
                        <span className="ps-3">{comment.body}</span>
                        {accountContext?.member?.id ===
                          comment.subscriber?.id && (
                          <span className="float-end">
                            <Button
                              variant="outline-primary"
                              onClick={() => {
                                setShowEditComment(true);
                                setSelectedComment(comment.id);
                                setFieldValue("updatedComment", comment.body);
                              }}
                            >
                              <span className="material-icons md-18 material-icons-outlined">
                                edit
                              </span>
                            </Button>{" "}
                            <Button
                              variant="outline-danger"
                              onClick={() => {
                                setShowConfirmDeleteComment(true);
                                setSelectedComment(comment.id);
                              }}
                            >
                              <span className="material-icons md-18 material-icons-outlined">
                                delete
                              </span>
                            </Button>
                          </span>
                        )}
                        <p className="ps-5 mb-0">
                          <small className="text-muted">
                            {new Date(comment.createdAt).toLocaleString([], {
                              dateStyle: "short",
                              timeStyle: "short",
                            })}
                          </small>{" "}
                          {new Date(comment.updatedAt) >
                            new Date(comment.createdAt) && (
                            <small className="text-muted">Edited</small>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
                <Form.Group className="mt-2">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    type="text"
                    id="commentInput"
                    name="comment"
                    placeholder="Type comment.."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.comment}
                  />
                  <Form.Control.Feedback>
                    {touched.comment && errors.comment}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  id="cancelCommentBtn"
                  size="sm"
                  variant="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  id="addCommentBtn"
                  onClick={handleSubmit}
                  size="sm"
                  disabled={!isValid}
                >
                  Add Comment
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

UpdateComments.propTypes = {
  attachment: PropTypes.object.isRequired,
  onCommentsUpdated: PropTypes.func.isRequired,
};

export default UpdateComments;
