import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ReductionPotentialChart from "../ReductionPotentialChart/ReductionPotentialChart";
import { convertNegativeToZero } from "components/Organization/OrganizationDetail/Forecasting/helper";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import CsvDownloader from "components/CsvDownloader/CsvDownloader";
import { post, put } from "utils/DeApi";

const ReductionPotenialChartModal = ({
  baselineYear,
  decarbEmissions,
  orgActions,
  forecastingConfig,
  organization,
  sbtiConfig,
  targetBaselineYear,
  checkIfAnyPercentsGreaterThan100,
  orgTargetEmissions,
  orgBauDecarb,
  isForecastingUpdate,
  isTargetUpdate,
  setIsForecastingUpdate,
  setIsTargetUpdate,
}) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [showOpportunities, setShowOpportunities] = useState(true);
  const [opportunitiesPercent, setOpportunitiesPercent] = useState({});
  const [bauDecarb, setBauDecarb] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [wedgeScenarios, setWedgeScenarios] = useState([]);
  const [scenarioId, setScenarioId] = useState();
  const [sbtiEmissions, setSbtiEmissions] = useState({});
  const [allYearsData, setAllYearsData] = useState([]);
  const subscribedPromises = useRef([]);
  const [scenarioName, setScenarioName] = useState("");
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const { organizationId } = useParams();

  const currentPath = window.location.pathname.split("/").pop();

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const memoizedConfig = useMemo(() => {
    if (forecastingConfig) {
      return JSON.parse(forecastingConfig);
    } else {
      return [];
    }
  }, [forecastingConfig]);

  const sbtiMemoizedConfig = useMemo(() => {
    if (sbtiConfig) {
      return JSON.parse(sbtiConfig);
    } else {
      return [];
    }
  }, [sbtiConfig]);

  useEffect(() => {
    function fetchDecarbBAU(organizationId) {
      const bauPromise = get(
        `organizations/${organizationId}/bau-decarbonization`
      );
      Promise.all([bauPromise.promise])
        .then((responses) => {
          setBauDecarb(responses[0]?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(bauPromise);
    }
    function fetchMiscLever(organizationId) {
      let arr = [];
      if (memoizedConfig.length) {
        const config = memoizedConfig.filter((lever) => lever.type);
        config.forEach((lever) => {
          arr = [
            ...arr,
            get(
              `organizations/${organizationId}/miscellaneous-decarbonization`,
              { params: { type: lever.type } }
            ),
          ];
        });

        if (arr.length) {
          Promise.all(arr.map((promise) => promise.promise))
            .then((responses) => {
              fetchDecarbBAU(organizationId);
              setIsLoading(false);
            })
            .catch((error) => {
              if (!error.isCanceled) {
                setError(error);
                setIsLoading(false);
              }
            });
        } else {
          setIsLoading(false);
        }
      }
      subscribedPromises.current.push(...arr);
    }
    function fetchForecasting(organizationId) {
      setError(null);
      setIsLoading(true);

      const economicGrowthPromise = get(
        `organizations/${organizationId}/economic-growth`
      );

      const gridDecarbonizationPromise = get(
        `organizations/${organizationId}/grid-decarbonization`
      );

      const SBTiPromise = get(
        `organizations/${organizationId}/science-based-target`
      );

      const decarbOfHeatingFuelsPromise = get(
        `organizations/${organizationId}/heating-fuels-decarbonization`
      );

      const decarbOfTransportPromise = get(
        `organizations/${organizationId}/transport-decarbonization`
      );

      Promise.all([
        economicGrowthPromise.promise,
        gridDecarbonizationPromise.promise,
        SBTiPromise.promise,
        decarbOfHeatingFuelsPromise.promise,
        decarbOfTransportPromise.promise,
      ])
        .then((responses) => {
          if (!memoizedConfig?.filter((lever) => lever.type).length) {
            fetchDecarbBAU(organizationId);
          } else {
            fetchMiscLever(organizationId);
          }
          if (responses?.[2]?.data?.length) {
            setSbtiEmissions(responses?.[2]?.data?.[0]);
          }
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(
        economicGrowthPromise,
        gridDecarbonizationPromise,
        SBTiPromise,
        decarbOfHeatingFuelsPromise,
        decarbOfTransportPromise
      );
    }
    if (!!memoizedConfig?.length) {
      // if (
      //   (isForecastingUpdate || isTargetUpdate) &&
      //   (currentPath === isForecastingUpdate?.path ||
      //     currentPath === isTargetUpdate?.path)
      // ) {
      fetchForecasting(organizationId);
      // } else {
      //   setIsForecastingUpdate();
      //   setIsTargetUpdate();
      //   setSbtiEmissions(orgTargetEmissions);
      //   setBauDecarb(orgBauDecarb);
      // }
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    baselineYear,
    // isForecastingUpdate,
    // isTargetUpdate,
    memoizedConfig,
    organizationId,
    targetBaselineYear,
    sbtiMemoizedConfig,
  ]);

  useEffect(() => {
    const debounceFunction = setTimeout(() => {
      const years = Array.from(
        { length: (Number(baselineYear) - 2050) / -1 + 1 },
        (_, i) => Number(baselineYear) + i * 1
      );
      let data = [];

      if (baselineYear && memoizedConfig?.length) {
        data = bauDecarb?.map((val, index) => {
          const reductionPercents = orgActions.reduce(
            (acc, curr) => {
              let opportunitiesReductionPercent =
                (Number(opportunitiesPercent[curr?.id]?.start_year) <=
                  Number(val.year) &&
                  opportunitiesPercent[curr?.id]?.percentage) ||
                0;
              let obj = {
                scopeOneReductionPercent:
                  (Number(
                    curr?.reductions?.find((val) => val.scope === 1)
                      ?.reductionPercentage || 0
                  ) /
                    100) *
                  opportunitiesReductionPercent,
                scopeTwoReductionPercent:
                  (Number(
                    curr?.reductions?.find((val) => val.scope === 2)
                      ?.reductionPercentage || 0
                  ) /
                    100) *
                  opportunitiesReductionPercent,
                scopeThreeReductionPercent:
                  (Number(
                    curr?.reductions?.find((val) => val.scope === 3)
                      ?.reductionPercentage || 0
                  ) /
                    100) *
                  opportunitiesReductionPercent,
              };
              return {
                scopeOneReductionPercent:
                  acc.scopeOneReductionPercent +
                  obj["scopeOneReductionPercent"],
                scopeTwoReductionPercent:
                  acc.scopeTwoReductionPercent +
                  obj["scopeTwoReductionPercent"],
                scopeThreeReductionPercent:
                  acc.scopeThreeReductionPercent +
                  obj["scopeThreeReductionPercent"],
                oppReductionsPercent: [
                  ...acc.oppReductionsPercent,
                  { ...obj, title: curr?.name, id: curr?.id },
                ],
              };
            },
            {
              scopeOneReductionPercent: 0,
              scopeTwoReductionPercent: 0,
              scopeThreeReductionPercent: 0,
              oppReductionsPercent: [],
            }
          );

          const s1Reduction = Number(
            (val.s1Bau / 100) *
              (reductionPercents?.scopeOneReductionPercent > 100
                ? 100
                : reductionPercents?.scopeOneReductionPercent)
          );
          const s2Reduction = Number(
            (val.s2Bau / 100) *
              (reductionPercents?.scopeTwoReductionPercent > 100
                ? 100
                : reductionPercents?.scopeTwoReductionPercent)
          );
          const s3Reduction = Number(
            (val.s3Bau / 100) *
              (reductionPercents?.scopeThreeReductionPercent > 100
                ? 100
                : reductionPercents?.scopeThreeReductionPercent)
          );

          const oppReductions = reductionPercents?.oppReductionsPercent?.reduce(
            (acc, opp) => {
              const s1Reduction = Number(
                (val.s1Bau / 100) *
                  (opp?.scopeOneReductionPercent > 100
                    ? 100
                    : opp?.scopeOneReductionPercent)
              );
              const s2Reduction = Number(
                (val.s2Bau / 100) *
                  (opp?.scopeTwoReductionPercent > 100
                    ? 100
                    : opp?.scopeTwoReductionPercent)
              );
              const s3Reduction = Number(
                (val.s3Bau / 100) *
                  (opp?.scopeThreeReductionPercent > 100
                    ? 100
                    : opp?.scopeThreeReductionPercent)
              );

              return {
                ...acc,
                [`${opp.title}`]:
                  index === 0 ? 0 : s1Reduction + s2Reduction + s3Reduction,
              };
            },
            {}
          );

          return {
            year: val.year,
            bau: val.totalBau,
            emissionsPathway: convertNegativeToZero(
              val.totalBau -
                (index === 0 ? 0 : s1Reduction + s2Reduction + s3Reduction)
            ),
            emissionsScopeOne: convertNegativeToZero(
              val.s1Bau - (index === 0 ? 0 : s1Reduction)
            ),
            emissionsScopeTwo: convertNegativeToZero(
              val.s2Bau - (index === 0 ? 0 : s2Reduction)
            ),
            emissionsScopeThree: convertNegativeToZero(
              val.s3Bau - (index === 0 ? 0 : s3Reduction)
            ),
            emissionsScopeOneReduction: convertNegativeToZero(
              index === 0 ? 0 : s1Reduction
            ),
            emissionsScopeTwoReduction: convertNegativeToZero(
              index === 0 ? 0 : s2Reduction
            ),
            emissionsScopeThreeReduction: convertNegativeToZero(
              index === 0 ? 0 : s3Reduction
            ),
            ...oppReductions,
          };
        });
      } else {
        const emissionsDataWithReduction = orgActions.reduce(
          (prev, curr) => {
            const reductionsObj = curr.reductions.reduce(
              (prevReduction, currReduction) => {
                let obj = {
                  ...prevReduction,
                  [currReduction?.activityType === null
                    ? currReduction?.scope === 1
                      ? "emissionsScopeOneReduction"
                      : currReduction?.scope === 2
                      ? "emissionsScopeTwoReduction"
                      : "emissionsScopeThreeReduction"
                    : `emissions${currReduction?.activityType?.label.replace(
                        /[^a-zA-Z0-9]/g,
                        ""
                      )}Reduction`]: Number(
                    (decarbEmissions?.find((val) =>
                      currReduction?.activityType === null
                        ? currReduction.scope === val?.scope
                        : val?.activityType?.id ===
                          currReduction?.activityType?.id
                    )?.emissions /
                      100) *
                      ((Number(currReduction?.reductionPercentage) / 100) *
                        (opportunitiesPercent[curr.id]?.percentage || 0))
                  ),
                };
                return obj;
              },
              {}
            );
            let obj = { ...prev.withReduction };
            let obj1 = { ...prev.withoutReduction };
            Object.keys(reductionsObj).forEach((val) => {
              obj = {
                ...obj,
                [val]: Number(obj[val] || 0) + Number(reductionsObj[val] || 0),
              };

              obj1 = {
                ...obj1,
                [val]: 0,
              };
            });

            return { withReduction: { ...obj }, withoutReduction: { ...obj1 } };
          },
          { withReduction: {}, withoutReduction: {} }
        );

        const emissionsData = decarbEmissions.reduce(
          (prev, curr) => {
            const key =
              curr?.activityType === null
                ? curr?.scope === 1
                  ? "emissionsScopeOne"
                  : curr?.scope === 2
                  ? "emissionsScopeTwo"
                  : "emissionsScopeThree"
                : `emissions${curr?.activityType?.label.replace(
                    /[^a-zA-Z0-9]/g,
                    ""
                  )}`;
            if (
              curr?.emissions <
              emissionsDataWithReduction?.withReduction?.[`${key}Reduction`]
            ) {
              emissionsDataWithReduction.withReduction[`${key}Reduction`] =
                Number(curr?.emissions);
            }

            return {
              withReduction: {
                ...prev.withReduction,
                [key]: convertNegativeToZero(
                  Number(curr?.emissions) -
                    Number(
                      emissionsDataWithReduction?.withReduction?.[
                        `${key}Reduction`
                      ] || 0
                    )
                ),
              },
              withoutReduction: {
                ...prev.withoutReduction,
                [key]: convertNegativeToZero(Number(curr?.emissions)),
              },
            };
          },
          { withoutReduction: {}, withReduction: {} }
        );

        const bau = decarbEmissions.reduce(
          (prev, curr) =>
            prev +
            (!curr.emissionPreference && curr.scope
              ? Number(curr?.emissions)
              : curr.emissionPreference && curr.activityType
              ? Number(curr?.emissions)
              : 0),
          0
        );

        const emissionsPathway = decarbEmissions.reduce(
          (prev, curr, currIndex) => {
            const key =
              curr?.activityType === null
                ? curr?.scope === 1
                  ? "emissionsScopeOne"
                  : curr?.scope === 2
                  ? "emissionsScopeTwo"
                  : "emissionsScopeThree"
                : `emissions${curr?.activityType?.label.replace(
                    /[^a-zA-Z0-9]/g,
                    ""
                  )}`;

            return {
              withReduction:
                prev.withReduction +
                (!curr.emissionPreference && curr.scope
                  ? convertNegativeToZero(
                      Number(curr?.emissions) -
                        emissionsDataWithReduction?.withReduction?.[
                          `${key}Reduction`
                        ]
                    )
                  : curr.emissionPreference && curr.activityType
                  ? convertNegativeToZero(
                      Number(curr?.emissions) -
                        emissionsDataWithReduction?.withReduction?.[
                          `${key}Reduction`
                        ]
                    )
                  : 0),
              withoutReduction:
                prev.withoutReduction +
                (!curr.emissionPreference && curr.scope
                  ? convertNegativeToZero(Number(curr?.emissions))
                  : curr.emissionPreference && curr.activityType
                  ? convertNegativeToZero(Number(curr?.emissions))
                  : 0),
            };
          },
          { withoutReduction: 0, withReduction: 0 }
        );

        data = years.map((val, index) => ({
          year: val,
          ...(index === 0
            ? emissionsData.withoutReduction
            : emissionsData.withReduction),
          bau,
          emissionsPathway:
            index === 0
              ? emissionsPathway.withoutReduction
              : emissionsPathway.withReduction,
          ...(index === 0
            ? emissionsDataWithReduction.withoutReduction
            : emissionsDataWithReduction.withReduction),
        }));
      }
      setData(data);

      if (allYearsData?.length && Object.keys(opportunitiesPercent)?.length) {
        setTimeout(() => {
          postCalculations();
        }, 1500);
      }
    }, 500);

    return () => {
      clearTimeout(debounceFunction);
    };
  }, [
    bauDecarb,
    baselineYear,
    decarbEmissions,
    orgActions,
    opportunitiesPercent,
    memoizedConfig?.length,
  ]);

  useEffect(() => {
    const getScenario = () => {
      const scenarioPromise = get(
        `/organizations/${organization.id}/wedge-scenarios`
      );

      scenarioPromise.promise
        .then((response) => {
          // setWedgeScenarios(response?.data || []);
          setScenarioName(
            response?.data?.[0]?.scenarioName || organization?.name + "-wedge"
          );
          setUpdatedTitle(
            response?.data?.[0]?.scenarioName || organization?.name + "-wedge"
          );
          if (response?.data?.length) {
            setScenarioId(response?.data?.[0]?.id);
            let percents = {};
            response?.data?.[0]?.breakdowns?.map((val) => {
              percents = {
                ...percents,
                [`${val?.opportunityGroup?.id}`]: {
                  percentage: val?.percentage || 0,
                  start_year:
                    (val?.start_year && val?.start_year?.substring(0, 4)) ||
                    baselineYear,
                },
              };
              return null;
            });
            setOpportunitiesPercent(percents);
          }
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(scenarioPromise);
    };

    getScenario();
  }, [organization.id, baselineYear]);

  // useEffect(() => {
  //   if (!!wedgeScenarios?.length) {
  //     let percents = {};
  //     wedgeScenarios?.[0]?.breakdowns?.map((val) => {
  //       percents = {
  //         ...percents,
  //         [`${val?.opportunityGroup?.id}`]: {
  //           percentage: val?.percentage || 0,
  //           start_year:
  //             (val?.start_year && val?.start_year?.substring(0, 4)) ||
  //             baselineYear,
  //         },
  //       };
  //       return null;
  //     });
  //     setOpportunitiesPercent(percents);
  //   }
  // }, [wedgeScenarios, baselineYear]);

  const updateScenarioName = () => {
    const percentCalculation = orgActions.map((val) => {
      return {
        opportunityGroupId: val.id,
        percentage: opportunitiesPercent[val.id]?.percentage || 0,
        startYear: opportunitiesPercent[val.id]?.start_year || baselineYear,
      };
    });

    const calculationsPromise = scenarioId
      ? put(`/scenario/${scenarioId}/wedge-breakdowns`, {
          wedgeName: updatedTitle,
          calculationBreakdown: percentCalculation,
          calculation: allYearsData,
        })
      : post(`/organizations/${organization.id}/wedge-scenarios`, {
          wedgeName: updatedTitle,
          calculationBreakdown: JSON.stringify(percentCalculation),
          calculation: JSON.stringify(allYearsData),
          scenarioId: null,
        });

    calculationsPromise.promise
      .then((response) => {
        setScenarioId(response?.data?.id || response?.data?.wedgeScenario?.id);
        setScenarioName(
          response?.data?.scenarioName ||
            response?.data?.wedgeScenario?.scenarioName
        );
        setUpdatedTitle(
          response?.data?.scenarioName ||
            response?.data?.wedgeScenario?.scenarioName
        );
        setIsEditing(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const postCalculations = () => {
    const percentCalculation = orgActions.map((val) => {
      return {
        opportunityGroupId: val.id,
        percentage: opportunitiesPercent[val.id]?.percentage || 0,
        startYear: opportunitiesPercent[val.id]?.start_year || baselineYear,
      };
    });

    const calculationsPromise = scenarioId
      ? put(`/scenario/${scenarioId}/wedge-breakdowns`, {
          calculationBreakdown: percentCalculation,
          calculation: allYearsData,
        })
      : post(`/organizations/${organization.id}/wedge-scenarios`, {
          calculationBreakdown: JSON.stringify(percentCalculation),
          calculation: JSON.stringify(allYearsData),
          scenarioId: null,
        });

    calculationsPromise.promise
      .then((response) => {
        setScenarioId(response?.data?.wedgeScenario?.id || response?.data?.id);
        setScenarioName(
          response?.data?.wedgeScenario?.scenarioName ||
            response?.data?.scenarioName
        );
        setUpdatedTitle(
          response?.data?.wedgeScenario?.scenarioName ||
            response?.data?.scenarioName
        );
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(calculationsPromise);
  };

  return (
    <>
      <Button
        size="sm"
        variant="primary"
        disabled={checkIfAnyPercentsGreaterThan100()}
        onClick={handleShow}
        className="mx-1"
      >
        Wedge
      </Button>
      <Modal show={show} fullscreen={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Interactive Wedge</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 overflow-hidden">
          <Row className="m-0">
            <Col lg={9} md={18} xs={18}>
              <div className="d-flex align-items-center justify-content-between mt-4 px-3">
                <div>
                  {isEditing ? (
                    <div className="d-flex">
                      <Form.Control
                        type="text"
                        name="miscLeverName"
                        defaultValue={updatedTitle}
                        placeholder="Enter lever name"
                        onChange={(e) => setUpdatedTitle(e.target.value)}
                        isInvalid={updatedTitle === ""}
                      />
                      <Button
                        onClick={() => {
                          if (updatedTitle !== "") {
                            updateScenarioName();
                          }
                        }}
                      >
                        <span className="material-icons-outlined md-18">
                          check_circle
                        </span>
                      </Button>
                      <Button
                        variant="light"
                        onClick={() => {
                          setIsEditing(false);
                          setUpdatedTitle(scenarioName);
                        }}
                      >
                        <span className="material-icons-outlined md-18">
                          cancel
                        </span>
                      </Button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-start align-items-center">
                      <h2 className="m-0">{scenarioName}</h2>
                      <Button
                        size="sm"
                        variant="outline-primary"
                        className="ms-2"
                        onClick={() => setIsEditing(true)}
                      >
                        <span className="material-icons-outlined md-18">
                          edit
                        </span>
                      </Button>
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <label
                    title=""
                    htmlFor="custom-switch-line"
                    className="me-3 form-check-label"
                  >
                    <small>SHOW BY SCOPES</small>
                  </label>
                  <Form.Check
                    group="switch"
                    id="custom-switch-line"
                    className="form-check form-switch m-0"
                    // label={<small>SHOW BY OPPORTUNITIES</small>}
                    checked={showOpportunities}
                    onChange={(e) => setShowOpportunities(e.target.checked)}
                  />
                  <label
                    title=""
                    htmlFor="custom-switch-line"
                    // className="text-muted me-1 ms-2 fs-5"
                    className="ms-2 form-check-label"
                  >
                    <small>SHOW BY OPPORTUNITIES</small>
                  </label>
                </div>
              </div>
              {isLoading && <Loader />}
              {!isLoading && !error && (
                <ReductionPotentialChart
                  data={data}
                  decarbEmissions={decarbEmissions}
                  orgActions={orgActions}
                  showOpportunities={showOpportunities}
                  organization={organization}
                  emission={sbtiEmissions}
                  setAllYearsData={setAllYearsData}
                  scenarioName={scenarioName}
                />
              )}
            </Col>
            <Col
              lg={3}
              md={6}
              xs={6}
              className="overflow-auto"
              style={{ height: "calc(100vh - 60px)" }}
            >
              {!!allYearsData?.length && (
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <h3 className="mb-0">Applicable Opportunities</h3>
                  <CsvDownloader
                    data={allYearsData}
                    fileName={`${organization?.name} - Reduction Potential Table.csv`}
                    className="downloadChart"
                    title="Export Data To Excel (.csv)"
                  />
                </div>
              )}
              <hr className="mb-4" />
              {orgActions.map((val) => {
                return (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div style={{ width: "calc(100% - 100px)" }}>
                        <h6>{val?.parentCategory?.name}</h6>
                        <h5>{val.name}</h5>
                      </div>
                      <div>
                        <Form.Select
                          as={Form.Select}
                          name="year"
                          value={opportunitiesPercent[val.id]?.start_year || 0}
                          onChange={(ev) =>
                            setOpportunitiesPercent((prev) => ({
                              ...prev,
                              [val.id]: {
                                ...prev[val.id],
                                start_year: ev.target.value,
                              },
                            }))
                          }
                        >
                          {data?.map(({ year }) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                    <Row>
                      <Form.Group
                        controlId={val.id}
                        className="mb-3 px-3"
                        as={Col}
                        xs={12}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className="d-flex align-items-center"
                            style={{ width: "calc(100% - 70px)" }}
                          >
                            <Form.Range
                              type="number"
                              name={val.id}
                              value={
                                opportunitiesPercent[val.id]?.percentage || 0
                              }
                              min={0}
                              max={100}
                              step={5}
                              onChange={(ev) => {
                                setOpportunitiesPercent((prev) => ({
                                  ...prev,
                                  [val.id]: {
                                    percentage: ev.target.valueAsNumber,
                                    start_year:
                                      prev[val?.id]?.start_year || baselineYear,
                                  },
                                }));
                              }}
                            />
                          </div>
                          <div style={{ width: 50 }}>
                            <Form.Control
                              type="number"
                              name={val.id}
                              value={
                                opportunitiesPercent[val.id]?.percentage || 0
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </Row>
                  </>
                );
              })}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReductionPotenialChartModal;
