import React, { Fragment, useEffect, useRef, useState } from "react";
import { Alert, Badge, Table } from "react-bootstrap";

import { getFormattedEmission } from "utils/StringUtils";
import { get } from "utils/DeApi";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import CarbonIntensityUpdate from "./CarbonIntensityUpdate/CarbonIntensityUpdate";
import CarbonIntensityEnergyUpdate from "./CarbonIntensityEnergyUpdate/CarbonIntensityEnergyUpdate";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import UserCategories from "utils/userCategories";

import { groupBy } from "lodash";
import DataLayer from "utils/DataLayer";

const CarbonIntensity = ({
  organization,
  organizationInfo,
  bottomUpSummary,
  carbonIntensity,
  carbonIntensityEnergy,
  onCarbonIntensityUpdated,
  onCarbonIntensityEnergyUpdated,
}) => {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const [carbonIntensityFactors, setCarbonIntensityFactors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchFactors = () => {
      setError(null);
      setIsLoading(true);

      const factorsPromise = get(`carbon-intensity-factors`);
      factorsPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setCarbonIntensityFactors(response.data);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(factorsPromise);
    };

    fetchFactors();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  // let energyByYear = groupBy(carbonIntensityEnergy, (item) => item.yearEnded);
  // let energyYears = Object.keys(energyByYear).sort();

  let fuelGroups = Object.keys(
    groupBy(carbonIntensityFactors, (item) => item.label)
  );

  return (
    <div id={userCategory}>
      <DataLayer />
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            { name: "Dashboard", link: "/" },
            {
              name: organization.name,
              link: `/organizations/${organization.id}`,
            },
            {
              name: "Carbon Intensity",
              link: `/organizations/${organization.id}/carbon-intensity`,
              active: true,
            },
          ]}
        />
      </div>
      <div className="d-inline-flex float-end">
        {!!carbonIntensity?.length &&
          (() => {
            let latestCI = carbonIntensity[carbonIntensity.length - 1];
            return (
              <div className="mx-1 px-3 bg-danger text-danger bg-opacity-10">
                <span className="fs-1">
                  {getFormattedEmission(latestCI.carbonIntensity)}{" "}
                </span>
                <small>
                  tCO<sub>2</sub>e/$MM
                </small>{" "}
                <Badge bg="danger">{latestCI.yearEnded.substring(0, 4)}</Badge>
              </div>
            );
          })()}
      </div>
      <h2 id="carbonIntensity">Carbon Intensity</h2>
      <hr />

      <div className="my-4">
        <span className="float-end">
          <CarbonIntensityUpdate
            organization={organization}
            organizationInfo={organizationInfo}
            bottomUpSummary={bottomUpSummary}
            carbonIntensity={carbonIntensity}
            onCarbonIntensityUpdated={onCarbonIntensityUpdated}
          />
        </span>
        <h4 className="mb-3">Carbon Emissions to Revenue Intensity</h4>

        {!!carbonIntensity?.length ? (
          <div className="table-scroll-end">
            <Table size="sm" striped responsive className="first-column-fixed">
              <thead>
                <tr>
                  <th></th>
                  {carbonIntensity.map((item) => (
                    <th className="text-end" key={item.yearEnded}>
                      {item.yearEnded.substring(0, 4)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-nowrap">Revenue (MM USD)</td>
                  {carbonIntensity.map((item) => (
                    <td
                      className="text-end text-nowrap ps-3"
                      key={item.yearEnded}
                    >
                      {Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(item.revenue / 1000000)}
                      MM
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="text-nowrap">
                    Emissions{" "}
                    <small>
                      (tCO<sub>2</sub>e)
                    </small>
                  </td>
                  {carbonIntensity.map((item) => (
                    <td
                      className="text-end text-nowrap ps-3"
                      key={item.yearEnded}
                    >
                      {getFormattedEmission(item.emissions)}{" "}
                    </td>
                  ))}
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className="text-nowrap">
                    Emissions to Revenue Intensity{" "}
                    <small>
                      (tCO<sub>2</sub>e/$MM)
                    </small>
                  </td>
                  {carbonIntensity.map((item) => (
                    <td
                      className="text-end text-nowrap ps-3"
                      key={item.yearEnded}
                    >
                      <big>
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(item.carbonIntensity)}
                      </big>{" "}
                    </td>
                  ))}
                </tr>
              </tfoot>
            </Table>
          </div>
        ) : (
          <Alert variant="info">There is currently nothing to show here.</Alert>
        )}
      </div>
      {/* <div className="my-4">
        {isLoading && <Loader />}
        {error && <ErrorHandler error={error} />}

        {carbonIntensityFactors?.length && (
          <span className="float-end">
            <CarbonIntensityEnergyUpdate
              organization={organization}
              organizationInfo={organizationInfo}
              bottomUpSummary={bottomUpSummary}
              carbonIntensityEnergy={carbonIntensityEnergy}
              carbonIntensityFactors={carbonIntensityFactors}
              onCarbonIntensityEnergyUpdated={onCarbonIntensityEnergyUpdated}
            />
          </span>
        )}
        <h4 className="mb-3">Carbon Emissions to Energy Intensity</h4>

        {!!energyYears?.length ? (
          <div className="table-scroll-end">
            <Table size="sm" responsive className="first-column-fixed">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center" colSpan={energyYears.length + 1}>
                    Emissions{" "}
                    <small>
                      (gCO<sub>2</sub>e/MJ)
                    </small>
                  </th>
                </tr>
                <tr>
                  <th>Source of Energy</th>
                  {energyYears.map((item) => (
                    <th className="text-end" key={item}>
                      {item.substring(0, 4)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {fuelGroups.map((fuel) => {
                  let cie = carbonIntensityFactors.filter(
                    (factor) => factor.label === fuel
                  );
                  return (
                    <Fragment key={fuel}>
                      <tr>
                        <th className="text-muted bg-light">{fuel}</th>
                        <th
                          className="bg-light"
                          colSpan={energyYears.length}
                        ></th>
                      </tr>
                      {cie.map((factor) => (
                        <tr key={factor.id}>
                          <td className="text-nowrap">{factor.name}</td>
                          {energyYears.map((item) => {
                            let yearEntries = energyByYear[item];
                            let yearFactor = yearEntries.find(
                              (entry) =>
                                entry.carbonIntensityFactorId === factor.id
                            );
                            return (
                              <td
                                className="text-end text-nowrap ps-3"
                                key={item}
                              >
                                {yearFactor?.carbonIntensity
                                  ? Intl.NumberFormat("en-us", {
                                      maximumFractionDigits: 6,
                                      minimumFractionDigits: 2,
                                    }).format(yearFactor.carbonIntensity)
                                  : "n/a"}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </Fragment>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td className="text-nowrap">
                    Emissions to Energy Intensity{" "}
                    <small>
                      (gCO<sub>2</sub>e/MJ)
                    </small>
                  </td>
                  {energyYears.map((item) => {
                    let yearEntries = energyByYear[item];
                    let emissions = yearEntries.reduce(
                      (sum, entry) => sum + entry.emissions || 0,
                      0
                    );
                    let energy = yearEntries.reduce(
                      (sum, entry) => sum + entry.energy || 0,
                      0
                    );
                    return (
                      <td className="text-end text-nowrap ps-3" key={item}>
                        {emissions && energy
                          ? Intl.NumberFormat("en-us", {
                              maximumFractionDigits: 6,
                              minimumFractionDigits: 2,
                            }).format(emissions / energy)
                          : "n/a"}
                      </td>
                    );
                  })}
                </tr>
              </tfoot>
            </Table>
          </div>
        ) : (
          <Alert variant="info">There is currently nothing to show here.</Alert>
        )}
      </div> */}
      <div className="my-4 p-3 border">
        <ContentDetails contentId={"625f0fe540cf4"} view={"full"} />
      </div>
    </div>
  );
};

export default CarbonIntensity;
