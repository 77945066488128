import React, { useCallback, useEffect, useRef, useState } from "react";

import "./CustomGroups.scss";
import { Alert, Badge, ButtonGroup, Col, Row } from "react-bootstrap";
import CustomGroupsCreate from "./CustomGroupsCreate/CustomGroupsCreate";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import CustomGroupsEdit from "./CustomGroupsEdit/CustomGroupsEdit";
import CustomGroupsDelete from "./CustomGroupsDelete/CustomGroupsDelete";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const CustomGroups = ({
  customGroups,
  fetchCustomGroups,
  isLoading,
  error,
}) => {
  const colors = customGroups
    ?.filter((filteredItem) => filteredItem?.color !== null || "")
    .map((item) => item?.color);

  return (
    <Row>
      <Col md={12}>
        <div className="border p-3 mt-2 ms-2 CustomGroups">
          <div className="d-flex align-items-center justify-content-between">
            <h3>Manage Opportunity Groupings</h3>
            <CustomGroupsCreate
              filteredColors={colors}
              fetchCustomGroups={fetchCustomGroups}
            />
          </div>
          <hr />

          {customGroups.length === 0 && (
            <Alert variant="info">
              There is currently nothing to show here.
            </Alert>
          )}
          {isLoading ? (
            <Loader />
          ) : (
            customGroups?.map((group) => (
              <div key={group?.id}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center ">
                    <span
                      style={{
                        backgroundColor: group.color || "",
                      }}
                      className="groupColor rounded-1 "
                    ></span>
                    <h4 className="ms-2 mt-2">{group?.name}</h4>
                  </div>
                  <div className="btnAlignment">
                    <Badge className="bg-light-green text-nowrap text-primary me-1">
                      {!!group?.decarb_action_category?.length ||
                      group?.project_count
                        ? "In Use"
                        : "Not in Use"}
                    </Badge>
                    <ButtonGroup>
                      <CustomGroupsEdit
                        name={group?.name}
                        color={group.color}
                        filteredColors={colors}
                        groupId={group?.id}
                        group={group}
                        fetchCustomGroups={fetchCustomGroups}
                      />
                      <CustomGroupsDelete
                        name={group?.name}
                        groupId={group?.id}
                        group={group}
                        fetchCustomGroups={fetchCustomGroups}
                      />
                    </ButtonGroup>
                  </div>
                </div>

                <hr />
              </div>
            ))
          )}

          {error && <ErrorHandler error={error} />}
        </div>
      </Col>
    </Row>
  );
};

export default CustomGroups;
