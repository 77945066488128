import React, { useEffect, useState, useRef, useCallback } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { getAllYearsBetweenDates } from "utils/dateUtils";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import QodScoreChart from "./QodScoreChart/QodScoreChart";

import { getFormattedEmission } from "utils/StringUtils";

export default function OrganizationQodScoreSummary({
  organization,
  pcafSummaryOrg,
  summaryOrg,
}) {
  const subscribedPromises = useRef([]);

  const [orgSummary, setOrgSummary] = useState();
  const [orgPcafSummary, setOrgPcafSummary] = useState([]);

  const recentYear = new Date().getFullYear() - 1;
  const [year, setYear] = useState(recentYear.toString());

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetchOrganizationDetails = useCallback(
    (organizationId, yearEnded = recentYear.toString()) => {
      setIsLoading(true);
      setError();

      const orgPcafSummary = get(
        `/organizations/${organizationId}/pcaf-rollup`,
        {
          params: {
            yearEnded: `${yearEnded}-12-31`,
          },
        }
      );

      orgPcafSummary.promise
        .then((response) => {
          setOrgSummary({ ...response.summary });
          setOrgPcafSummary([...response.data]);

          setError(null);
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
          console.log(error);
        });
      subscribedPromises.current.push(orgPcafSummary);
    },
    []
  );

  useEffect(() => {
    setOrgPcafSummary(pcafSummaryOrg);
    setOrgSummary(summaryOrg);
  }, [organization.id, summaryOrg, pcafSummaryOrg]);

  return (
    <>
      <Row>
        <Col lg={6} xs={12}>
          <h2>PCAF Data Quality Score</h2>
          <p>For Scope 3 Category 15 - Investments</p>
        </Col>
        <Col lg={6} xs={12}>
          <div className="ms-2 d-inline-flex float-end">
            {!isLoading && orgSummary ? (
              <div className="mx-1 px-3 bg-success text-success bg-opacity-10">
                <span className="fs-1">
                  {getFormattedEmission(orgSummary.totalPcafScore, 2)}
                </span>{" "}
                <small>
                  based on {orgSummary.pcafActivitiesCount} out of{" "}
                  {orgSummary.activitiesCount} activities
                </small>
              </div>
            ) : (
              <Loader customClass="d-inline-flex py-0" />
            )}
            <div className="d-inline-flex align-items-center mx-1 align-center">
              <Form.Select
                aria-label="year-filter"
                size="md"
                value={year}
                onChange={(ev) => {
                  let selectedYear = ev.target.value;
                  setYear(selectedYear);
                  fetchOrganizationDetails(organization.id, selectedYear);
                }}
              >
                {getAllYearsBetweenDates().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </Col>
        <hr />
      </Row>
      {!isLoading && orgPcafSummary ? (
        <Row>
          <QodScoreChart activitiesEmissions={orgPcafSummary} year={year} />
        </Row>
      ) : (
        <Loader />
      )}
    </>
  );
}
