import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Table, ButtonGroup, Button, Alert, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { groupBy } from "lodash";

import { getFormattedEmission } from "utils/StringUtils";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import SiteCreate from "components/Organization/Site/SiteCreate/SiteCreate";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import SiteMap from "./Map/Map";
import { AccountContext } from "contexts/AccountProvider";
import SitesUpload from "components/Organization/Site/SitesUpload/SitesUpload";
import ScopeTwoPreferenceToggler from "components/Organization/Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";
import OrgActivitiesUpload from "./OrgActivitiesUpload/OrgActivitiesUpload";
import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";
import { TableSortArrows } from "components/Shared/TableComponents";
import "./BottomUpAnalysis.scss";

const BottomUpAnalysis = ({
  sites,
  sitesSummary,
  organization,
  onSiteCreated,
  scopeTwoPreference,
  setScopeTwoPreference,
  activityTypes,
}) => {
  const [sitesView, setSitesView] = useState("table_view");
  const emissionsYears = Object.keys(groupBy(sitesSummary, "yearEnded"));
  const account = useContext(AccountContext);

  const [tableData, setTableData] = useState([]);

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updatedAt",
    updatedAt: true,
    sortAsc: false,
  });

  const userCategory = UserCategories();

  useEffect(() => {
    const data = sites.map((site) => {
      let siteTableData = { ...site };
      emissionsYears.forEach((year) => {
        let yearEmissions = sitesSummary.find(
          (item) =>
            String(item.yearEnded) === String(year) && item.siteId === site.id
        );

        siteTableData[year] = yearEmissions?.emissions;
      });
      if (siteTableData.name) {
        siteTableData.name = siteTableData.name.trim();
      }
      return siteTableData;
    });
    data.sort((a, b) => (a["updatedAt"] <= b["updatedAt"] ? 1 : -1));
    setTableData(data);
  }, [sites]);

  const sortSites = (column) => {
    tableData.sort((a, b) => {
      a = a[column];
      b = b[column];
      // if column is a year, convert values to float for comparison
      if (emissionsYears.includes(column)) {
        a = Number.parseFloat(a || 0); // since some years do not have emissions assume 0 for easier sorting
        b = Number.parseFloat(b || 0);
      }

      const isString = typeof a == "string" && typeof b == "string";

      if (!tableSortState[column]) {
        if (isString) {
          return a.localeCompare(b, "en-us") <= 0 ? 1 : -1;
        }
        return a <= b ? 1 : -1;
      } else {
        if (isString) {
          return a.localeCompare(b, "en-us") >= 0 ? 1 : -1;
        }
        return a >= b ? 1 : -1;
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setTableData([...tableData]);
  };

  return (
    <div id={userCategory}>
      <DataLayer />
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            { name: "Dashboard", link: "/" },
            {
              name: `${organization.name}`,
              link: `/organizations/${organization.id}`,
            },
            {
              name: "Bottom-Up GHG Inventory",
              link: `/organizations/${organization.id}/top-down`,
              active: true,
            },
          ]}
        />
      </div>
      <div className="d-flex float-end">
        {sites && (
          <div className="mx-1 px-3 bg-info text-primary bg-opacity-10">
            <span className="fs-1 me-2">{sites.length}</span>
            Sites
          </div>
        )}
        <div className="mx-1 px-3 bg-danger text-danger bg-opacity-10">
          {(() => {
            let latestEmisssions = sitesSummary.filter(
              (item) =>
                item.yearEnded ===
                Math.max(...sitesSummary.map((item) => item.yearEnded))
            );

            return (
              <>
                <span className="fs-1">
                  {getFormattedEmission(
                    latestEmisssions.reduce(
                      (sum, item) => item.emissions + sum,
                      0
                    )
                  )}
                </span>{" "}
                <small>
                  tCO<sub>2</sub>e
                </small>{" "}
                {latestEmisssions[0]?.yearEnded || ""} Emissions
              </>
            );
          })()}
        </div>
      </div>

      <h2 id="bottomUp">Bottom-Up GHG Inventory</h2>
      <hr />

      <Row>
        <Col lg={4}>
          <h4 className="lh-lg">
            Sites GHG Inventory by Year{" "}
            <small>
              ( tCO<sub>2</sub>e )
            </small>
          </h4>
        </Col>

        <Col lg={8} className="text-end">
          <div className="d-inline-flex align-items-center px-1">
            <ScopeTwoPreferenceToggler
              scopeTwoPreference={scopeTwoPreference}
              setScopeTwoPreference={setScopeTwoPreference}
            />
          </div>

          <div className="d-inline-flex align-items-center px-1">
            <ButtonGroup>
              <Button
                variant={
                  sitesView === "table_view" ? "primary" : "outline-primary"
                }
                size="sm"
                onClick={() => setSitesView("table_view")}
              >
                <span className="material-icons fs-4 ">table_rows</span>
              </Button>

              <Button
                variant={
                  sitesView === "map_view" ? "primary" : "outline-primary"
                }
                size="sm"
                onClick={() => setSitesView("map_view")}
              >
                <span className="material-icons fs-4">map</span>
              </Button>
            </ButtonGroup>{" "}
          </div>
          {account.member.role !== "member" && (
            <>
              <div className="d-inline-flex align-items-center px-1">
                <SitesUpload
                  organization={organization}
                  onSitesUploaded={onSiteCreated}
                />{" "}
              </div>
              <div className="d-inline-flex align-items-center px-1">
                <OrgActivitiesUpload
                  activityTypes={activityTypes}
                  organization={organization}
                  onActivitiesUploaded={onSiteCreated}
                />{" "}
              </div>
              <div className="d-inline-flex align-items-center px-1">
                <SiteCreate
                  organization={organization}
                  onSiteCreated={onSiteCreated}
                />
              </div>
            </>
          )}
        </Col>
      </Row>

      {sites && !!sites.length && (
        <div className="my-2 bottomUpTable">
          {sitesView === "table_view" ? (
            <div className="table-scroll-end ">
              <Table hover className="first-column-fixed" responsive>
                <thead>
                  <tr>
                    <th
                      className="table-sorter text-nowrap"
                      onClick={() => sortSites("name")}
                    >
                      Name{" "}
                      <TableSortArrows
                        sortColumn={tableSortState.sortColumn}
                        colName={"name"}
                        sortAsc={tableSortState.sortAsc}
                      />
                    </th>

                    {emissionsYears.map((item) => (
                      <th
                        className="text-end table-sorter text-nowrap"
                        key={item}
                        onClick={() => sortSites(item + "")}
                      >
                        {item}
                        <TableSortArrows
                          sortColumn={tableSortState.sortColumn}
                          colName={item}
                          sortAsc={tableSortState.sortAsc}
                        />
                      </th>
                    ))}
                    <th
                      className="table-sorter ps-3 text-nowrap"
                      onClick={() => sortSites("updatedAt")}
                    >
                      Updated At
                      <TableSortArrows
                        sortColumn={tableSortState.sortColumn}
                        colName={"updatedAt"}
                        sortAsc={tableSortState.sortAsc}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((site) => (
                    <tr key={site.id}>
                      <td className="text-truncate" title={site.name}>
                        <Link
                          to={`/organizations/${organization.id}/sites/${site.id}`}
                          className="siteName"
                        >
                          {site.name}
                        </Link>
                      </td>

                      {emissionsYears.map((year) => {
                        return (
                          <td className="text-end" key={year}>
                            {site[year] !== undefined ? (
                              getFormattedEmission(site[year])
                            ) : (
                              <small className="text-muted">n/a</small>
                            )}
                          </td>
                        );
                      })}

                      <td className="ps-3 text-nowrap">
                        <small>
                          {new Date(site.updatedAt).toLocaleString([], {
                            dateStyle: "short",
                            timeStyle: "short",
                          })}
                        </small>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button
                as={Link}
                size="sm"
                variant="outline-primary"
                className="px-3 my-2"
                to={`/organizations/${organization.id}/activities`}
              >
                See GHG Inventory Details
              </Button>
            </div>
          ) : (
            <SiteMap sites={sites} sitesSummary={sitesSummary} />
          )}
        </div>
      )}

      {sites && !sites.length && (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}

      <div className="my-4 p-3 border">
        <ContentDetails contentId={"6268006b31a3a"} view={"full"} />
      </div>
    </div>
  );
};

BottomUpAnalysis.propTypes = {
  sites: PropTypes.array.isRequired,
  sitesSummary: PropTypes.array.isRequired,
  onSiteCreated: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  scopeTwoPreference: PropTypes.number.isRequired,
  setScopeTwoPreference: PropTypes.func.isRequired,
};

export default BottomUpAnalysis;
