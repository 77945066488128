import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Form,
  Offcanvas,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Field, FieldArray, Formik } from "formik";
import * as yup from "yup";
import { post, put } from "utils/DeApi";
import { useParams } from "react-router-dom";
import { isNegative } from "utils/numerals";

const MAX_YEAR = 2051;

const ManageS2Reductions = ({
  emissions,
  onManageS2ReductionsUpdated,
  baselineYear,
  isForecastingUpdate,
  fetchEmission,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [year, setYear] = useState(2021);
  const [isCopyDown, setIsCopyDown] = useState(false);
  const { organizationId } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    scopeTwoEmissionPercentage: yup
      .number()
      .label("Impacted Emissions")
      .min(0)
      .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`)
      .required(),
    scopeThreeEmissionPercentage: yup
      .number()
      .label("Impacted Emissions")
      .min(0)
      .max(100, `Must not exceed ${Intl.NumberFormat("en-us").format(100)}.`)
      .required(),
    reduction: yup.array().of(
      yup.object().shape({
        year: yup
          .number()
          .label("Year")
          .min(0)
          .max(
            9999999999999,
            `Must not exceed ${Intl.NumberFormat("en-us").format(
              9999999999999
            )}.`
          ),
        s2_reduction: yup
          .number()
          .label("Percentage Increase")
          .max(
            9999999999999,
            `Must not exceed ${Intl.NumberFormat("en-us").format(
              9999999999999
            )}.`
          )
          .required(),
      })
    ),
  });

  const updateS2Reduction = ({
    reduction,
    scopeTwoEmissionPercentage,
    scopeThreeEmissionPercentage,
  }) => {
    setError(null);
    setIsLoading(true);
    const data = {
      organization_id: organizationId,
      reduction,
      scopeTwoEmissionPercentage: scopeTwoEmissionPercentage,
      scopeThreeEmissionPercentage: scopeThreeEmissionPercentage,
    };
    const organizationPromise = emissions?.id
      ? put(`grid-decarbonization/${emissions?.id}`, data)
      : post(`organizations/${organizationId}/grid-decarbonization`, data);
    organizationPromise.promise
      .then((response) => {
        // fetchEmission(organizationId);
        setIsLoading(false);
        handleClose();
        onManageS2ReductionsUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    setYear((prev) => {
      return !isNaN(Number(baselineYear)) ? baselineYear : prev;
    });

    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [baselineYear, isForecastingUpdate]);

  return (
    <>
      <Button size="sm" onClick={handleShow} className="float-end">
        Manage Projections
      </Button>
      <Offcanvas placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header className="border-bottom" closeButton>
          <Offcanvas.Title className="fs-4">Manage Projections</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {error && <ErrorHandler error={error} />}
          <Formik
            validationSchema={schema}
            onSubmit={(values) => updateS2Reduction(values)}
            enableReinitialize={true}
            initialValues={{
              scopeTwoEmissionPercentage: parseFloat(
                emissions?.scopeTwoEmissionPercentage || 0
              ),
              scopeThreeEmissionPercentage: parseFloat(
                emissions?.scopeThreeEmissionPercentage || 0
              ),
              reduction: [...Array(MAX_YEAR - parseInt(year) || 30)]
                .map((item, index) => ({
                  year: (parseInt(year) + index) % MAX_YEAR,
                  s2_reduction: 0,
                }))
                .map(({ year, s2_reduction }) => {
                  const data =
                    Array.isArray(emissions?.data) &&
                    emissions?.data.find(({ year: y }) => year === y);

                  return {
                    year: year,
                    s2_reduction: data
                      ? parseFloat(data.s2_reduction_percent || 0)
                      : 0,
                  };
                })
                .filter((r) => Number(r.year) !== Number(year)),
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              isValid,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} className="mb-5 pb-2">
                <Row className="mb-1">
                  <Col xs={12}>
                    % Impacted Emissions in Scope 2
                    <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                  </Col>
                </Row>
                <Row>
                  <Form.Group
                    controlId={`scopeTwoEmissionPercentage`}
                    className="mb-2"
                    as={Col}
                    xs={12}
                  >
                    <Field
                      name={`scopeTwoEmissionPercentage`}
                      as={Form.Control}
                      type="number"
                      size="sm"
                      onChange={(ev) => {
                        const reduction = ev.target.valueAsNumber;
                        setFieldValue(
                          `scopeTwoEmissionPercentage`,
                          !isNaN(reduction) ? reduction : ""
                        );
                      }}
                      isValid={values.scopeTwoEmissionPercentage}
                      isInvalid={
                        errors.scopeTwoEmissionPercentage &&
                        touched.scopeTwoEmissionPercentage
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.scopeTwoEmissionPercentage}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-1">
                  <Col xs={12}>
                    % Impacted Emissions in Scope 3
                    <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                  </Col>
                </Row>
                <Row>
                  <Form.Group
                    controlId={`scopeThreeEmissionPercentage`}
                    className="mb-2"
                    as={Col}
                    xs={12}
                  >
                    <Field
                      name={`scopeThreeEmissionPercentage`}
                      as={Form.Control}
                      type="number"
                      size="sm"
                      onChange={(ev) => {
                        const reduction = ev.target.valueAsNumber;
                        setFieldValue(
                          `scopeThreeEmissionPercentage`,
                          !isNaN(reduction) ? reduction : ""
                        );
                      }}
                      isValid={values.scopeThreeEmissionPercentage}
                      isInvalid={
                        errors.scopeThreeEmissionPercentage &&
                        touched.scopeThreeEmissionPercentage
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.scopeThreeEmissionPercentage}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-1">
                  <Col xs={2}>Years</Col>
                  <Col xs={6}>
                    % Reduction in Grid Electricity Factor{" "}
                    <span>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip>
                            The % reduction for each year is based on the change
                            of emission factor compared to the previous year as
                            a percentage of the base emission factor.
                          </Tooltip>
                        }
                      >
                        <span className="material-icons-outlined md-18">
                          info
                        </span>
                      </OverlayTrigger>
                    </span>
                  </Col>
                  <Col xs={4} className="px-0">
                    <Form.Check
                      type="switch"
                      id="custom-switch-line"
                      label={<small className="text-muted">Copy Down</small>}
                      checked={isCopyDown}
                      onChange={(e) => {
                        setIsCopyDown(e.target.checked);
                      }}
                    />
                  </Col>
                </Row>
                {values.reduction.reduce((prev, curr, acc) => {
                  return curr?.s2_reduction + (prev || 0);
                }, 0) > 100 ? (
                  <div className="invalid-feedback d-block mb-3 mt-2">
                    Note: Cumulative % reduction is capped to 100. You have
                    entered values exceeding the cap.
                  </div>
                ) : null}
                <FieldArray
                  name="reduction"
                  render={(arrayHelpers) => (
                    <>
                      {values.reduction.map((r, index) => (
                        <Row key={index}>
                          <Form.Group
                            controlId={`reduction[${index}].year`}
                            className="mb-2 px-2"
                            as={Col}
                            xs={2}
                          >
                            <Field
                              name={`reduction[${index}].year`}
                              as={Form.Control}
                              type="number"
                              size="sm"
                              disabled={true}
                              className="p-1 text-center"
                            />
                          </Form.Group>
                          <Form.Group
                            controlId={`reduction[${index}].s2_reduction`}
                            className="mb-2"
                            as={Col}
                            xs={10}
                          >
                            <Field
                              name={`reduction[${index}].s2_reduction`}
                              as={Form.Control}
                              type="number"
                              size="sm"
                              onChange={(ev) => {
                                const reduction = ev.target.valueAsNumber;
                                setFieldValue(
                                  `reduction[${index}].s2_reduction`,
                                  !isNaN(reduction) ? reduction : ""
                                );

                                isCopyDown &&
                                  values.reduction.forEach((item, ii) => {
                                    if (index < ii)
                                      setFieldValue(
                                        `reduction[${ii}].s2_reduction`,
                                        !isNaN(reduction) ? reduction : ""
                                      );
                                  });
                              }}
                            />
                            {values?.reduction?.length &&
                              isNegative(
                                values?.reduction[index]?.s2_reduction
                              ) && (
                                <div className="invalid-feedback d-block">
                                  Note: A negative value represents an increase
                                  in emission factor.
                                </div>
                              )}
                          </Form.Group>
                        </Row>
                      ))}
                    </>
                  )}
                />
                <Col className="position-absolute fixed-bottom bg-light p-3">
                  <Button
                    size="sm"
                    type="submit"
                    className="float-end ms-2"
                    disabled={isLoading || !isValid}
                  >
                    {isLoading && (
                      <Spinner animation="border" size="sm" className="me-2" />
                    )}
                    Save
                  </Button>
                  <Button
                    size="sm"
                    variant="link"
                    className="float-end"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Col>
              </Form>
            )}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

ManageS2Reductions.propTypes = {
  onManageS2ReductionsUpdated: PropTypes.func,
};

export default ManageS2Reductions;
