import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Table, Tabs, Tab } from "react-bootstrap";

import { put, get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import OrganizationPermissioning from "components/Account/Permissioning/OrganizationPermissioning/OrganizationPermissioing";
import PortfolioPermissioning from "components/Account/Permissioning/PortfolioPermissioning/PortfolioPermissioning";

const MemberUpdate = ({
  account,
  member,
  organizations,
  portfolios,
  onMemberUpdated,
}) => {
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [role, setRole] = useState(member.role);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateMemberRole = (role) => {
    setError(null);
    setIsLoading(true);

    const updateMemberRole = put(`members/${member.id}`, {
      role: role,
      accountId: account.id,
    });

    updateMemberRole.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        setRole(response.data.role);
        onMemberUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShow}
        size="sm"
        id="updateMemberBtn"
        className="py-0"
      >
        <span className="material-icons-outlined md-18">tune</span>
      </Button>

      {show && (
        <Modal
          show={show}
          backdrop={"static"}
          onHide={handleClose}
          size="lg"
          className="right"
          fullscreen="sm-down"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>
                <small>
                  <i>{account.name}</i>
                </small>
              </b>
              <br />
              Manage Member <i>{member.email}</i>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading && <Loader />}
            {error && <ErrorHandler error={error} />}
            <div className="mb-3">
              <h4>Member's Role</h4>
              <div>
                <span id="memberRadioBtn">
                  <Form.Check
                    name="role"
                    type="radio"
                    label="Member"
                    inline
                    className="pe-5 text-dark"
                    checked={role === "member"}
                    onChange={() => updateMemberRole("member")}
                  />
                </span>

                <span id="adminRadioBtn">
                  <Form.Check
                    name="role"
                    type="radio"
                    label="Admin"
                    className="pe-5 text-dark"
                    inline
                    checked={role === "admin"}
                    onChange={() => updateMemberRole("admin")}
                  />
                </span>

                {account.member.role === "owner" && (
                  <span id="ownerRadioBtn">
                    <Form.Check
                      name="role"
                      type="radio"
                      label="Owner"
                      className="pe-5 text-dark"
                      inline
                      checked={role === "owner"}
                      onChange={() => updateMemberRole("owner")}
                    />
                  </span>
                )}
              </div>
            </div>

            {member.role === "member" && organizations && (
              <Tabs defaultActiveKey="organizations">
                <Tab
                  eventKey="organizations"
                  title={
                    <div id="organizationsAccessTab">Organizations Access</div>
                  }
                >
                  <OrganizationPermissioning
                    account={account}
                    member={member}
                    organizations={organizations}
                  />
                </Tab>
                <Tab
                  eventKey="profile"
                  title={<div id="portfoliosAccessTab">Portfolios Access</div>}
                >
                  <PortfolioPermissioning
                    account={account}
                    member={member}
                    portfolios={portfolios}
                  />
                </Tab>
              </Tabs>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              size="sm"
              variant="primary"
              className="px-3"
              onClick={handleClose}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

MemberUpdate.propTypes = {
  account: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
  portfolios: PropTypes.array.isRequired,
  onMemberUpdated: PropTypes.func.isRequired,
};

export default MemberUpdate;
