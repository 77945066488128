import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import GwpModels from "./GwpModels";
import OrganizationAttachments from "./OrganizationAttachments";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";

export default function Profile({ organization, onOrganizationUpdated }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get("tab") || "gwp");

  useEffect(() => {
    setTab(searchParams.get("tab") || "gwp");
  }, [searchParams]);

  const setTabKey = (value) => {
    setTab(value);
    setSearchParams({ ...searchParams, tab: value });
  };

  return (
    <>
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            { name: "Dashboard", link: "/" },
            {
              name: organization.name,
              link: `/organizations/${organization.id}`,
            },
            {
              name: "Profile",
              link: `/organizations/${organization.id}/gwp-models`,
              active: true,
            },
          ]}
        />
      </div>
      <Tabs
        defaultActiveKey="gwp"
        activeKey={tab}
        onSelect={(k) => setTabKey(k)}
        className="mb-3"
      >
        <Tab
          eventKey={"gwp"}
          title={<div id="orgGWPBasisTab">Organization's GWP Basis</div>}
        >
          <GwpModels
            organization={organization}
            onOrganizationUpdated={onOrganizationUpdated}
          />
        </Tab>
        <Tab
          eventKey={"attachments"}
          title={<div id="documentRepositoryTab">Document Repository</div>}
        >
          <OrganizationAttachments organization={organization} />
        </Tab>
      </Tabs>
    </>
  );
}

Profile.propTypes = {
  organization: PropTypes.object.isRequired,
};
