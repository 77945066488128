import Select from "react-select";
import PropTypes from "prop-types";

function SelectInputFormik({
  name,
  isSearchable = true,
  isClearable = true,
  form,
  field,
  options,
  values,
  onChange,
  onBlur,
  id,
  placeholder = "",
  isLoading,
  selectedOption,
  isDisabled,
  components,
  filterOption,
  autoSort,
  ...props
}) {
  return (
    <Select
      {...props}
      id={id}
      name={name || field.name}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isLoading={isLoading}
      isDisabled={isDisabled}
      placeholder={placeholder || "Search or select from options"}
      options={
        autoSort
          ? options.sort((a, b) => a.label.localeCompare(b.label, "en-US"))
          : options
      }
      value={
        options?.find((option) => option.value === field.value) ||
        (selectedOption?.value && selectedOption)
      }
      components={components}
      filterOption={filterOption}
      onChange={
        onChange ||
        ((option) => {
          option
            ? form.setFieldValue(field.name, option.value)
            : form.setFieldValue(field.name, "");
          return option;
        })
      }
      onBlur={onBlur || ((e) => form.setFieldTouched(field.name, true))}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#E9EFC0",
          primary: "#007a5f",
        },
      })}
      styles={{
        control: (ownStyles) => ({
          ...ownStyles,
          borderColor: values[name] ? "#007a5f" : "#EEEEEE",
        }),
        dropdownIndicator: (ownStyles) => ({
          ...ownStyles,
          color: "#212121",
        }),
      }}
    />
  );
}

SelectInputFormik.defaultProps = {
  isClearable: true,
  isSearchable: true,
  onChange: null,
  placeholder: "",
  isLoading: false,
  selectedOption: null,
  isReadOnly: false,
  components: null,
  filterOption: (candidate, input) =>
    candidate.data.__isNew__ ||
    candidate.label.toLowerCase().includes(input.toLowerCase()),
  autoSort: true,
};

SelectInputFormik.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  selectedOption: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  isReadOnly: PropTypes.bool,
  components: PropTypes.object,
  filterOption: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  autoSort: PropTypes.bool,
};

export default SelectInputFormik;
