import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Form,
  Row,
  Col,
  Modal,
  Table,
  DropdownButton,
  Dropdown,
  Collapse,
} from "react-bootstrap";

import Loader from "components/Loader/Loader";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import UserCategories from "utils/userCategories";

import "./Projections.scss";
import { get } from "utils/DeApi";
import {
  checkIfIsInBau,
  convertNegativeToZero,
  formatNumber,
  sum,
  unionByYear,
} from "../helper";
import EmissionProjectionsChart from "./EmissionProjectionsChart/EmissionProjectionsChart";
import InteractiveProject from "./InteractiveProject/InteractiveProject";
import {
  getMergedSTBi,
  getEconomicGrowth,
  getBusinessAsUsualHeatingFuels,
  getGridDecarbonizationBAU,
  getHeatingFuelsDecarbonizationBAU,
  getBusinessAsUsual,
  getTransportDecarbonizationBAU,
  getBusinessAsUsualTransport,
  getBusinessAsUsualMisc,
  getMiscDecarbonizationBAU,
} from "../forecastingModels";
import { projectCombinedStatusMap } from "../../Abatement/AbatementProjects/constants";
import { isArray, uniq } from "lodash";
import DataLayer from "utils/DataLayer";
import CsvDownloader from "components/CsvDownloader/CsvDownloader";
import { TableSortArrows } from "components/Shared/TableComponents";

const colorsPallete = [
  "#AFEEEE",
  "#D1E8E2",
  "#9DC4D6",
  "#D1E6FF",
  "#D4E4F7",
  "#AEC6CF",
  "#C5D9E9",
  "#E6E6FA",
  "#CBFDCB",
  "#E0FFFF",
  "#B0E0E6",
  "#D4DDE9",
  "#E7EBF1",
  "#CCE7EF",
  "#E7F5F8",
  "#CDEBED",
  "#E7F5F6",
  "#D9EDE4",
  "#EAF6F0",
  "#E5F1D9",
  "#F0F7EA",
];

const selectedProjections = {
  "BAU trend": true,
  "1.5C SBTi Target  Scope 1 + 2 (w/ constrain)": true,
  "WB2C SBTi Target  Scope 1 + 2 (w/ constrain)": true,
};

const Projections = ({
  isShow,
  organization,
  breadcrumbs,
  scopeOne,
  scopeTwo,
  scopeThree,
  targetScopeOne,
  targetScopeTwo,
  targetScopeThree,
  targetBaselineYear,
  baselineYear,
  sites = [],
  forecastingConfig,
  onChartRendered,
  setTotalCarbonSaving,
  setTotalRoadmapEmissions,
  sbtiConfig,
  endYear,
  isForecastingUpdate,
  isTargetUpdate,
  setIsForecastingUpdate,
  setIsTargetUpdate,
  orgProjects,
  orgEconomicGrowth,
  orgEconomicGrowthEmissionPercentage,
  orgGridDecarbonization,
  orgGridDecarbonizationEmissionPercentage,
  orgGridDecarbonizationEmissionPercentageThree,
  orgTargetEmissions,
  orgDecarbOfHeatingFuels,
  orgHeatingFuelDecarbEmissionPercentage,
  orgHeatingFuelDecarbEmissionPercentageThree,
  orgDecarbOfTransport,
  orgTransportDecarbEmissionPercentage,
  orgTransportDecarbEmissionPercentageThree,
  orgGroups,
  orgScenarios,
  orgLocations,
  orgBauDecarb,
}) => {
  const [emission, setEmission] = useState();
  const [projections, setProjections] = useState();
  const [decarbLever, setDecarbLever] = useState("");
  const [selectedScope, setSelectedScope] = useState("Scope 1+2+3");
  const tooltipRef = useRef(null);

  const [status, setStatus] = useState([
    {
      value: "Implemented - In Flight",
      key: 1,
    },
    {
      value: "Committed - Not Started",
      key: 3,
    },
    {
      value: "Implemented - Completed",
      key: 4,
    },
  ]);
  const [group, setGroup] = useState([]);
  const [site, setSite] = useState([]);
  const [scenario, setScenario] = useState([]);
  const [location, setLocation] = useState([]);

  const [projects, setProjects] = useState([]);
  const [groups, setGroups] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [locations, setLocations] = useState([]);
  const [economicGrowth, setEconomicGrowth] = useState([]);
  const [scienceBasedTarget, setScienceBasedTarget] = useState({});
  const [gridDecarbonization, setGridDecarbonization] = useState([]);
  const [decarbOfHeatingFuels, setDecarbOfHeatingFuels] = useState([]);
  const [decarbOfTransport, setDecarbOfTransport] = useState([]);
  const [miscDecarb, setMiscDecarb] = useState([]);
  const [showAllProjects, setShowAllProjects] = useState(true);
  const [showDecarbLevers, setShowDecarbLevers] = useState(false);
  const [bauDecarb, setBauDecarb] = useState([]);
  const [
    economicGrowthEmissionPercentage,
    setEconomicGrowthEmissionPercentage,
  ] = useState(100);
  const [
    gridDecarbonizationEmissionPercentage,
    setGridDecarbonizationEmissionPercentage,
  ] = useState([]);
  const [
    gridDecarbonizationEmissionPercentageThree,
    setGridDecarbonizationEmissionPercentageThree,
  ] = useState([]);
  const [
    heatingFuelDecarbEmissionPercentage,
    setHeatingFuelDecarbEmissionPercentage,
  ] = useState([]);
  const [
    heatingFuelDecarbEmissionPercentageThree,
    setHeatingFuelDecarbEmissionPercentageThree,
  ] = useState([]);
  const [
    transportDecarbEmissionPercentage,
    setTransportDecarbEmissionPercentage,
  ] = useState([]);
  const [
    miscDecarbEmissionPercentageScopeOne,
    setMiscDecarbEmissionPercentageScopeOne,
  ] = useState([]);
  const [
    miscDecarbEmissionPercentageScopeTwo,
    setMiscDecarbEmissionPercentageScopeTwo,
  ] = useState([]);
  const [
    transportDecarbEmissionPercentageThree,
    setTransportDecarbEmissionPercentageThree,
  ] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectionCSVData, setProjectionCSVData] = useState([]);
  const [show, setShow] = useState();
  const [error, setError] = useState(null);
  const [showMoreFilter, setShowMoreFilter] = useState(false);

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "project",
    updatedAt: true,
    sortAsc: false,
  });

  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  const userCategory = UserCategories();

  const currentPath = window.location.pathname.split("/").pop();

  const memoizedConfig = useMemo(() => {
    if (sbtiConfig) {
      return JSON.parse(sbtiConfig);
    } else {
      return [];
    }
  }, [sbtiConfig]);

  const memoizedForecastingConfig = useMemo(() => {
    if (forecastingConfig) {
      return JSON.parse(forecastingConfig);
    } else {
      return [];
    }
  }, [forecastingConfig]);

  const showTooltip = () => {
    tooltipRef.current.style.visibility = "visible";
    tooltipRef.current.style.opacity = 1;
  };

  const hideTooltip = () => {
    tooltipRef.current.style.visibility = "hidden";
    tooltipRef.current.style.opacity = 0;
  };

  const handleSelect = (eventKey, cb, valArr, prevVal) => {
    if (eventKey === "clear") {
      cb([]);
    } else {
      const option = valArr.find(
        (o) => o?.key == eventKey || o?.id == eventKey
      );
      const newSelectedOptions = [...prevVal];
      const index = newSelectedOptions.findIndex((o) => {
        return (
          (o?.key && o?.key === option?.key) || (o?.id && o?.id === option?.id)
        );
      });
      if (index !== -1) {
        newSelectedOptions.splice(index, 1);
      } else {
        newSelectedOptions.push(option);
      }
      cb(newSelectedOptions);
    }
  };
  const [selectedYear, setSelectedYear] = useState(
    Number(targetBaselineYear) < Number(baselineYear)
      ? targetBaselineYear
      : baselineYear
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [yearData, setYearData] = useState([]);
  const [allYearsData, setAllYearsData] = useState([]);
  const [selectedLegends, setSelectedLegends] = useState({
    ...selectedProjections,
  });

  const handleProjectUpdate = (data) => {
    setProjects(
      projects.map((p) => {
        if (p.id === data.id) return data;
        return p;
      })
    );
  };

  const toggleOffcanvas = () => {
    setShow(!show);
  };

  const projectFilter = (projects, type, status, site, scenario, location) => {
    if (!Array.isArray(projects)) return [];

    return projects
      .filter((project) => {
        if (!group.length) return true;
        if (group === "reset") return true;
        return group.filter((val) => project?.group?.id === val.id).length;
      })
      .filter((project) => {
        if (!status.length) return true;
        if (status === "reset") return true;
        return status.filter(
          (val) => parseInt(project?.projectStatus) === parseInt(val.key)
        ).length;
      })
      .filter((project) => {
        if (!site.length) return true;
        if (site === "reset") return true;
        return site.filter((val) => project?.site?.id === val.id).length;
      })
      ?.filter((project) => {
        if (!scenario.length) return true;
        if (scenario === "reset") return true;
        return scenario.filter((val) =>
          val.projects?.some((data) => project?.id === data?.id)
        ).length;
      })
      ?.filter((project) => {
        if (!location.length) return true;
        if (location === "reset") return true;
        return location.filter((val) => project?.location === val.name).length;
      });
  };

  const gettitle = (arr, filter) => {
    return arr.length === 0
      ? `Select ${filter}`
      : arr.map((option) => option?.value || option?.name).join(", ");
  };

  useEffect(() => {
    const getRecentYearValues = (arr) => {
      let array = [...arr];
      return array?.filter(
        (val) => Number(val.year) <= Number(endYear || "2050")
      );
    };
    function fetchDecarbBAU(organizationId) {
      const bauPromise = get(
        `organizations/${organizationId}/bau-decarbonization`
      );
      Promise.all([bauPromise.promise])
        .then((responses) => {
          setBauDecarb(getRecentYearValues(responses[0]?.data));
          setIsLoading(false);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            // setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(bauPromise);
    }
    function fetchMiscLever(organizationId) {
      let arr = [];
      if (memoizedForecastingConfig.length) {
        const config = memoizedForecastingConfig.filter((lever) => lever.type);
        config.forEach((lever) => {
          arr = [
            ...arr,
            get(
              `organizations/${organizationId}/miscellaneous-decarbonization`,
              { params: { type: lever.type } }
            ),
          ];
        });

        if (arr.length) {
          Promise.all(arr.map((promise) => promise.promise))
            .then((responses) => {
              const decarb = responses.filter(
                (response) => response.data.length
              );
              fetchDecarbBAU(organizationId);
              setMiscDecarb(decarb);
              setIsLoading(false);
            })
            .catch((error) => {
              if (!error.isCanceled) {
                setError(error);
                setIsLoading(false);
              }
            });
        } else {
          setIsLoading(false);
        }
      }

      subscribedPromises.current.push(...arr);
    }
    function fetchForecasting(organizationId) {
      // if (
      //   (isForecastingUpdate || isTargetUpdate) &&
      //   (currentPath === isForecastingUpdate?.path ||
      //     currentPath === isTargetUpdate?.path)
      // ) {
      setError(null);
      setIsLoading(true);

      const abatementProjects = get(
        `organizations/${organizationId}/abatement-projects`
      );

      const economicGrowthPromise = get(
        `organizations/${organizationId}/economic-growth`
      );

      const SBTiPromise = get(
        `organizations/${organizationId}/science-based-target`
      );

      const gridDecarbonizationPromise = get(
        `organizations/${organizationId}/grid-decarbonization`
      );

      const decarbOfHeatingFuelsPromise = get(
        `organizations/${organizationId}/heating-fuels-decarbonization`
      );

      const decarbOfTransportPromise = get(
        `organizations/${organizationId}/transport-decarbonization`
      );

      const groupPromise = get(`organizations/${organizationId}/groups`);

      const scenariosPromise = get(`organizations/${organizationId}/scenarios`);

      const locationsPromise = get(
        `organizations/${organizationId}/site-custom-locations?in_project=true`
      );

      Promise.all([
        abatementProjects.promise,
        economicGrowthPromise.promise,
        SBTiPromise.promise,
        gridDecarbonizationPromise.promise,
        decarbOfHeatingFuelsPromise.promise,
        decarbOfTransportPromise.promise,
        groupPromise.promise,
        scenariosPromise.promise,
        locationsPromise.promise,
      ])
        .then((responses) => {
          const [
            { data: projects = [] },
            { data: economicGrowth = [] },
            { data: scienceBasedTarget = [] },
            { data: gridDecarbonization = [] },
            { data: decarbOfHeatingFuels = [] },
            { data: decarbOfTransport = [] },
            { data: oppGroups = [] },
            { data: oppScenarios = [] },
            { data: oppLocations = [] },
          ] = responses || [];

          setProjects(
            projects.map((p) => ({
              show: true,
              ...p,
            }))
          );

          setEconomicGrowth(economicGrowth[0] ? economicGrowth[0]?.data : []);
          setEconomicGrowthEmissionPercentage(
            economicGrowth[0]
              ? economicGrowth[0]?.scopeThreeEmissionPercentage
              : []
          );
          setGridDecarbonization(
            gridDecarbonization[0] ? gridDecarbonization[0]?.data : []
          );
          setGridDecarbonizationEmissionPercentage(
            gridDecarbonization[0]
              ? gridDecarbonization[0]?.scopeTwoEmissionPercentage
              : []
          );
          setGridDecarbonizationEmissionPercentageThree(
            gridDecarbonization[0]
              ? gridDecarbonization[0]?.scopeThreeEmissionPercentage
              : []
          );
          setScienceBasedTarget(
            scienceBasedTarget[0] ? scienceBasedTarget[0] : {}
          );
          setDecarbOfHeatingFuels(
            decarbOfHeatingFuels[0] ? decarbOfHeatingFuels[0]?.data : []
          );
          setHeatingFuelDecarbEmissionPercentage(
            decarbOfHeatingFuels[0]
              ? decarbOfHeatingFuels[0]?.scopeOneEmissionPercentage
              : []
          );
          setHeatingFuelDecarbEmissionPercentageThree(
            decarbOfHeatingFuels[0]
              ? decarbOfHeatingFuels[0]?.scopeThreeEmissionPercentage
              : []
          );
          setDecarbOfTransport(
            decarbOfTransport[0] ? decarbOfTransport[0]?.data : []
          );
          setTransportDecarbEmissionPercentage(
            decarbOfTransport[0]
              ? decarbOfTransport[0]?.scopeOneEmissionPercentage
              : []
          );
          setTransportDecarbEmissionPercentageThree(
            decarbOfTransport[0]
              ? decarbOfTransport[0]?.scopeThreeEmissionPercentage
              : []
          );

          setGroups(oppGroups || []);
          setScenarios(oppScenarios || []);
          setLocations(
            oppLocations?.map((locationData, i) => ({
              id: i + 1,
              name: locationData,
            })) || []
          );

          fetchMiscLever(organizationId);
          fetchDecarbBAU(organizationId);
          if (
            !memoizedForecastingConfig?.filter((lever) => lever.type).length
          ) {
            fetchDecarbBAU(organizationId);
          } else {
            fetchMiscLever(organizationId);
          }
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(
        abatementProjects,
        economicGrowthPromise,
        SBTiPromise,
        gridDecarbonizationPromise,
        decarbOfHeatingFuelsPromise,
        decarbOfTransportPromise,
        groupPromise
      );
      //   } else {
      //     setIsForecastingUpdate();
      //     setIsTargetUpdate();
      //     setProjects(
      //       orgProjects.map((p) => ({
      //         show: true,
      //         ...p,
      //       }))
      //     );
      //     setEconomicGrowth(orgEconomicGrowth);
      //     setEconomicGrowthEmissionPercentage(
      //       orgEconomicGrowthEmissionPercentage
      //     );
      //     setGridDecarbonization(orgGridDecarbonization);
      //     setGridDecarbonizationEmissionPercentage(
      //       orgGridDecarbonizationEmissionPercentage
      //     );
      //     setGridDecarbonizationEmissionPercentageThree(
      //       orgGridDecarbonizationEmissionPercentageThree
      //     );
      //     setScienceBasedTarget(orgTargetEmissions);
      //     setDecarbOfHeatingFuels(orgDecarbOfHeatingFuels);
      //     setHeatingFuelDecarbEmissionPercentage(
      //       orgHeatingFuelDecarbEmissionPercentage
      //     );
      //     setHeatingFuelDecarbEmissionPercentageThree(
      //       orgHeatingFuelDecarbEmissionPercentageThree
      //     );
      //     setDecarbOfTransport(orgDecarbOfTransport);
      //     setTransportDecarbEmissionPercentage(
      //       orgTransportDecarbEmissionPercentage
      //     );
      //     setTransportDecarbEmissionPercentageThree(
      //       orgTransportDecarbEmissionPercentageThree
      //     );
      //     setGroups(orgGroups);
      //     setScenarios(orgScenarios);
      //     setLocations(
      //       orgLocations?.map((locationData, i) => ({
      //         id: i + 1,
      //         name: locationData,
      //       })) || []
      //     );
      //     setBauDecarb(getRecentYearValues(orgBauDecarb));
      //   }
    }
    if (!!memoizedConfig?.length || !!memoizedForecastingConfig.length) {
      fetchForecasting(organizationId);
    }

    const promises = subscribedPromises.current;

    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    organizationId,
    scopeOne,
    scopeTwo,
    scopeThree,
    baselineYear,
    targetScopeOne,
    targetScopeTwo,
    targetScopeThree,
    targetBaselineYear,
    endYear,
    // isForecastingUpdate,
    // isTargetUpdate,
    memoizedConfig,
    memoizedForecastingConfig,
    selectedScope,
    // orgBauDecarb,
  ]);

  useEffect(() => {
    setScenario(scenarios?.filter((scenario) => scenario?.active));
  }, [projects, scenarios]);

  useEffect(() => {
    const _projects = projectFilter(
      projects,
      group,
      status,
      site,
      scenario,
      location
    )
      .map((project, index) => {
        let obj = {
          ...project,
          lineStyle: {
            ...(project?.group?.color ? { color: project?.group?.color } : {}),
          },
          itemStyle: {
            ...(project?.group?.color ? { color: project?.group?.color } : {}),
          },
          areaStyle: {
            type: "default",
            ...(project?.group?.color ? { color: project?.group?.color } : {}),
            opacity: 1,
          },
        };

        return { ...obj };
      })
      .filter((p) => p?.show);

    let bau = [];
    let bauLever = "";

    const getRecentYearValues = (arr, hasYear = true) => {
      const difference = Number(baselineYear) - Number(targetBaselineYear);
      let array = [...arr];
      if (hasYear) {
        if (difference > 0) {
          const diffArr = Array(difference)
            .fill()
            .map((val, index) => {
              return {
                year: array[0]?.year - (index + 1),
                projects: [],
              };
            });
          array.splice(0, 0, ...diffArr.reverse());
        }
      } else {
        if (difference > 0) {
          return array?.map((val) => ({
            ...val,
            data: [...Array(difference).fill(), ...val.data],
          }));
        } else {
          return array?.map((val) => ({
            ...val,
            data: [...val.data],
          }));
        }
      }

      return array?.filter(
        (val) => Number(val.year) <= Number(endYear || "2050")
      );
    };

    const selectedScopeKey =
      selectedScope === "Scope 1+2"
        ? "s1s2"
        : selectedScope === "Scope 1"
        ? "s1"
        : selectedScope === "Scope 2"
        ? "s2"
        : selectedScope === "Scope 3"
        ? "s3"
        : "total";

    bau = [...bauDecarb];

    bauLever = `${selectedScopeKey}Bau`;
    setDecarbLever(`${selectedScopeKey}Bau`);

    function getAnnualSavings(data = [], bau) {
      const timeLine = createTimeline(data);
      const finalYear = getFinalYear(timeLine, bau);
      const projectsWithTimePeriod = updateTimeline(timeLine, finalYear);
      const startYear = parseInt(baselineYear);
      const numberOfYears =
        finalYear - startYear + 1 > 0 ? finalYear - startYear + 1 : 0;

      let prevAnnualSaving = undefined;

      if (data.length <= 0) return [];

      return Array(numberOfYears)
        .fill("")
        .map((item, index) => {
          const year = (startYear + index) % (startYear + numberOfYears);
          const projects = projectsWithTimePeriod.filter(
            ({ startYear, finalYear }) => {
              return year >= startYear && year <= finalYear;
            }
          );

          const bauDecarb = bau?.find(({ year: y }) => y === year) || {};
          const sumOfAnnualSaving = projects.reduce(
            (
              aggregate,
              { annualCarbonEmissionReduction, projectYearData, scope }
            ) => {
              let reduction = 0;
              if (scope && selectedScope.includes(scope)) {
                if (projectYearData?.length) {
                  const obj = projectYearData?.filter(
                    (year) => year?.year === bau[index]?.year
                  );
                  if (obj.length) {
                    reduction =
                      Number(obj[0]?.annual_carbon_emission_reduction) || 0;
                  } else {
                    reduction = Number(
                      projectYearData.at(-1)?.annual_carbon_emission_reduction
                    );
                  }
                } else {
                  reduction = annualCarbonEmissionReduction;
                }
              }
              return sum(aggregate, reduction);
            },
            0
          );

          prevAnnualSaving =
            prevAnnualSaving === undefined
              ? sumOfAnnualSaving
              : sum(prevAnnualSaving, sumOfAnnualSaving);

          prevAnnualSaving = sumOfAnnualSaving;
          return {
            year: year,
            projects: projects,
            sumOfAnnualSaving: !isNaN(bauDecarb?.[`${bauLever}`])
              ? bauDecarb?.[`${bauLever}`] - prevAnnualSaving
              : "N/A",
          };
        });
    }

    function getEmissionProjections(data = [], bau) {
      const timeLine = createTimeline(data);
      const finalYear = getFinalYear(timeLine, bau);
      const projectsWithTimePeriod = updateTimeline(timeLine, finalYear).sort(
        (a, b) => {
          if (a.startYear > b.startYear) return 1;
          if (a.startYear < b.startYear) return -1;
          return 0;
        }
      );

      const startYear = parseInt(baselineYear);
      const numberOfYears = finalYear - startYear + 1;
      const projects = [];
      const prev = {};
      const _projectsWithTimePeriod = projectsWithTimePeriod.filter(
        (project) => project.annualCarbonEmissionReduction
      );

      for (let index = 0; index < numberOfYears; index++) {
        const year = (startYear + index) % (startYear + numberOfYears);
        let startOffset = undefined;
        let startOffsetS1 = undefined;
        let startOffsetS2 = undefined;
        let startOffsetS3 = undefined;
        if (startOffset === undefined)
          startOffset = bau.find(({ year: y }) => y === year)?.[`${bauLever}`];
        if (startOffsetS1 === undefined)
          startOffsetS1 = bau.find(({ year: y }) => y === year)?.s1Bau;
        if (startOffsetS2 === undefined)
          startOffsetS2 = bau.find(({ year: y }) => y === year)?.s2Bau;
        if (startOffsetS3 === undefined)
          startOffsetS3 = bau.find(({ year: y }) => y === year)?.s3Bau;
        let prevReduction = startOffset;
        let totalAnnualReduction = 0;
        let totalAnnualReductionS1 = 0;
        let totalAnnualReductionS2 = 0;
        let totalAnnualReductionS3 = 0;
        _projectsWithTimePeriod.forEach((p) => {
          const found = projects.find(({ id }) => id === p.id);

          const isWithinRange = year >= p.startYear && year <= p.finalYear;
          const annualCarbonEmissionReduction = isWithinRange
            ? p.annualCarbonEmissionReduction
            : 0;
          prev[p.id] =
            prev[p.id] === undefined
              ? annualCarbonEmissionReduction
              : sum(prev[p.id], annualCarbonEmissionReduction);

          let remainingReduction = 0;
          let carbonReduction = 0;
          if (p?.scope) {
            if (p?.projectYearData?.length && bau?.length) {
              const obj = p?.projectYearData?.filter(
                (year) => year?.year === bau[index]?.year
              );
              // console.log(obj);
              if (obj.length) {
                if (p?.scope === 1) {
                  totalAnnualReduction += Number(
                    obj[0]?.annual_carbon_emission_reduction
                  );
                  totalAnnualReductionS1 += Number(
                    obj[0]?.annual_carbon_emission_reduction
                  );
                }
                if (p?.scope === 2) {
                  totalAnnualReduction += Number(
                    obj[0]?.annual_carbon_emission_reduction
                  );
                  totalAnnualReductionS2 += Number(
                    obj[0]?.annual_carbon_emission_reduction
                  );
                }
                if (p?.scope === 3) {
                  totalAnnualReduction += Number(
                    obj[0]?.annual_carbon_emission_reduction
                  );
                  totalAnnualReductionS3 += Number(
                    obj[0]?.annual_carbon_emission_reduction
                  );
                }

                remainingReduction =
                  convertNegativeToZero(prevReduction) -
                  Number(obj[0]?.annual_carbon_emission_reduction);

                carbonReduction = Number(
                  obj[0]?.annual_carbon_emission_reduction
                );
                prevReduction = remainingReduction;
              } else {
                let reductionPerYear = isWithinRange
                  ? Number(
                      p?.projectYearData?.at(-1)
                        ?.annual_carbon_emission_reduction
                    )
                  : 0;
                if (p?.scope === 1) {
                  totalAnnualReduction += reductionPerYear;
                  totalAnnualReductionS1 += reductionPerYear;
                }
                if (p?.scope === 2) {
                  totalAnnualReduction += reductionPerYear;
                  totalAnnualReductionS2 += reductionPerYear;
                }
                if (p?.scope === 3) {
                  totalAnnualReduction += reductionPerYear;
                  totalAnnualReductionS3 += reductionPerYear;
                }

                remainingReduction =
                  convertNegativeToZero(prevReduction) - reductionPerYear;
                carbonReduction = reductionPerYear;
                prevReduction = remainingReduction;
              }
            } else {
              if (p?.scope === 1) {
                totalAnnualReduction += annualCarbonEmissionReduction;
                totalAnnualReductionS1 += annualCarbonEmissionReduction;
                // console.log(p);
              }
              if (p?.scope === 2) {
                totalAnnualReduction += annualCarbonEmissionReduction;
                totalAnnualReductionS2 += annualCarbonEmissionReduction;
                // console.log(p);
              }
              if (p?.scope === 3) {
                totalAnnualReduction += annualCarbonEmissionReduction;
                totalAnnualReductionS3 += annualCarbonEmissionReduction;
                // console.log(p);
              }
              remainingReduction =
                convertNegativeToZero(prevReduction) -
                annualCarbonEmissionReduction;

              carbonReduction = annualCarbonEmissionReduction;

              prevReduction = remainingReduction;
            }
          }

          if (!found) {
            projects.push({
              ...p,
              type: "line",
              stack: "x",
              ...(p?.group?.color ? { color: p?.group?.color } : {}),
              data: [p?.scope ? carbonReduction : 0],
            });
          } else {
            const index = projects.findIndex(({ id }) => id === found.id);
            projects[index].data = [
              ...found.data,
              p?.scope ? carbonReduction : 0,
            ];
          }
        });
        const found = projects.find(
          ({ name }) => name === "Unabated Emissions"
        );
        const foundS1 = projects.find(
          ({ name }) => name === "Scope 1 Unabated Emissions"
        );
        const foundS2 = projects.find(
          ({ name }) => name === "Scope 2 Unabated Emissions"
        );
        const foundS3 = projects.find(
          ({ name }) => name === "Scope 3 Unabated Emissions"
        );

        let emissisonsPathway = 0;

        if (!foundS1) {
          projects.push({
            name: "Scope 1 Unabated Emissions",
            type: "line",
            symbolSize: 0,
            color: "#C3C5C9",
            show: true,
            stack: "x",
            data: [startOffsetS1 - totalAnnualReductionS1],
          });
        } else {
          const index = projects.findIndex(
            ({ name }) => name === "Scope 1 Unabated Emissions"
          );
          projects[index].data = [
            ...foundS1.data,
            startOffsetS1 - totalAnnualReductionS1,
          ];
        }
        if (!foundS2) {
          projects.push({
            name: "Scope 2 Unabated Emissions",
            type: "line",
            symbolSize: 0,
            color: "#B7B2AA",
            show: true,
            stack: "x",
            data: [startOffsetS2 - totalAnnualReductionS2],
          });
        } else {
          const index = projects.findIndex(
            ({ name }) => name === "Scope 2 Unabated Emissions"
          );
          projects[index].data = [
            ...foundS2.data,
            startOffsetS2 - totalAnnualReductionS2,
          ];
        }
        if (!foundS3) {
          projects.push({
            name: "Scope 3 Unabated Emissions",
            type: "line",
            symbolSize: 0,
            color: "#858A7F",
            show: true,
            stack: "x",
            data: [startOffsetS3 - totalAnnualReductionS3],
          });
        } else {
          const index = projects.findIndex(
            ({ name }) => name === "Scope 3 Unabated Emissions"
          );
          projects[index].data = [
            ...foundS3.data,
            startOffsetS3 - totalAnnualReductionS3,
          ];
        }

        emissisonsPathway =
          (selectedScope?.includes("1")
            ? Math.max(0, startOffsetS1 - totalAnnualReductionS1)
            : 0) +
          (selectedScope?.includes("2")
            ? Math.max(0, startOffsetS2 - totalAnnualReductionS2)
            : 0) +
          (selectedScope?.includes("3")
            ? Math.max(0, startOffsetS3 - totalAnnualReductionS3)
            : 0);
        if (!found) {
          projects.push({
            name: "Unabated Emissions",
            type: "line",
            symbolSize: 0,
            color: "#A6A6A6",
            show: true,
            data: [emissisonsPathway],
          });
        } else {
          const index = projects.findIndex(
            ({ name }) => name === "Unabated Emissions"
          );
          projects[index].data = [...found.data, emissisonsPathway];
        }
      }
      return projects.reverse();
    }
    setProjections(
      getRecentYearValues(getEmissionProjections(_projects, bau), false)
    );

    setEmission(
      getRecentYearValues(unionByYear(bau, getAnnualSavings(_projects, bau)))
    );
  }, [
    bauDecarb,
    economicGrowth,
    scienceBasedTarget,
    gridDecarbonization,
    decarbOfHeatingFuels,
    decarbOfTransport,
    miscDecarb,
    scopeOne,
    scopeTwo,
    scopeThree,
    baselineYear,
    endYear,
    projects,
    group,
    status,
    site,
    scenario,
    location,
    show,
    memoizedForecastingConfig,
    targetScopeOne,
    targetScopeTwo,
    targetScopeThree,
    targetBaselineYear,
    memoizedConfig,
    economicGrowthEmissionPercentage,
    gridDecarbonizationEmissionPercentage,
    gridDecarbonizationEmissionPercentageThree,
    heatingFuelDecarbEmissionPercentage,
    heatingFuelDecarbEmissionPercentageThree,
    transportDecarbEmissionPercentage,
    transportDecarbEmissionPercentageThree,
  ]);

  useEffect(() => {
    const filteredProjectionData = projections
      ?.filter((data) => !data?.name?.includes("Unabated Emissions"))
      ?.map((projection) => ({
        Project: projection?.name,
        Scope: projection?.scope,
        "Annual Reduction": projection?.annualCarbonEmissionReduction,
      }));
    setProjectionCSVData(filteredProjectionData);
  }, [projections]);

  useEffect(() => {
    const totalEmissions = emission?.length;
    const total = projections?.map((project, index) => {
      const { annualCarbonEmissionReduction } = project;
      return annualCarbonEmissionReduction;
    });
    setTotalCarbonSaving &&
      setTotalCarbonSaving(
        total
          ?.filter((data) => !!data)
          ?.reduce((a, b) => Number(a) + Number(b), 0)
      );
    setTotalRoadmapEmissions && setTotalRoadmapEmissions(totalEmissions);
  }, [projections, emission]);

  const sortEmission = (column, objValue) => {
    projections?.sort((a, b) => {
      if (!a.name.includes("Unabated Emissions")) {
        let macA = objValue ? a[column]?.[objValue] : a[column];
        let macB = objValue ? b[column]?.[objValue] : b[column];

        if (
          macA === null ||
          macA === undefined ||
          macA === "n/a" ||
          macA === ""
        ) {
          return tableSortState[column] ? 1 : -1;
        }

        if (
          macB === null ||
          macB === undefined ||
          macB === "n/a" ||
          macA === ""
        ) {
          return tableSortState[column] ? -1 : 1;
        }

        if (typeof macA === "number" && typeof macB === "number") {
          if (!tableSortState[column]) return macB - macA;
          return macA - macB;
        } else {
          if (!tableSortState[column])
            return macA.localeCompare(macB, "en-us") <= 0 ? 1 : -1;
          else return macA.localeCompare(macB, "en-us") >= 0 ? 1 : -1;
        }
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setProjections([...projections]);
  };

  return (
    <>
      <DataLayer />
      {isShow && (
        <div className="my-3">
          <BreadCrumbs
            breadcrumbs={[
              ...breadcrumbs,
              {
                name: "Roadmap",
                link: `/organizations/${organization.id}/projections/1/roadmap`,
                active: true,
              },
            ]}
          />
        </div>
      )}
      {isShow && <h2 className="mb-4 pt-2">Roadmap</h2>}
      {!!memoizedConfig?.length || !!memoizedForecastingConfig.length ? (
        <div id={userCategory} className="my-3 p-3 border">
          <div className="d-flex flex-row mb-3">
            <h3 className="flex-fill">{!isShow ? "Roadmap" : "Overview"}</h3>{" "}
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                size="sm"
                className="bg-primary bg-opacity-25 text-dark border-0 me-2"
              >
                {selectedScope === "All"
                  ? "Filter by All Scopes"
                  : `Filter by ${selectedScope}`}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[
                  "Scope 1+2+3",
                  "Scope 1+2",
                  "Scope 1",
                  "Scope 2",
                  "Scope 3",
                ].map((scope, index) => (
                  <Dropdown.Item
                    key={index}
                    className={selectedScope === scope ? "active" : ""}
                    onClick={() => {
                      setSelectedScope((prev) => {
                        if (prev === scope) return scope;
                        return scope;
                      });
                    }}
                  >
                    {scope}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {/* {isShow && (
              <Button
                onClick={toggleOffcanvas}
                size="sm"
                variant="primary"
                className="border-0"
              >
                Interactive projections
              </Button>
            )} */}
          </div>
          <hr />
          <Row>
            <Form.Group
              as={Col}
              xs={12}
              md={4}
              controlId="Sectors"
              className="mb-3"
            >
              <Form.Label>Filter by status</Form.Label>
              <DropdownButton
                title={gettitle(status, "status")}
                id="multi-select-dropdown"
                onSelect={(e) =>
                  handleSelect(e, setStatus, projectCombinedStatusMap, status)
                }
                variant="outline-dark"
                className="no-hover"
                autoClose="outside"
              >
                {projectCombinedStatusMap.map(({ key, value }) => (
                  <Dropdown.Item
                    key={key}
                    eventKey={key}
                    active={status.some((o) => o.value === value)}
                  >
                    {status.some((o) => o.value === value) ? (
                      <span className="material-icons-outlined md-16 me-1">
                        check_box
                      </span>
                    ) : (
                      <span className="material-icons-outlined md-16 me-1">
                        check_box_outline_blank
                      </span>
                    )}
                    {value}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item eventKey="clear" disabled={status.length === 0}>
                  Clear selections
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              md={4}
              controlId="Sectors"
              className="mb-3"
            >
              <Form.Label>Filter by group</Form.Label>
              <DropdownButton
                title={gettitle(group, "group")}
                id="multi-select-dropdown"
                onSelect={(e) => handleSelect(e, setGroup, groups, group)}
                variant="outline-dark"
                className="no-hover"
                autoClose="outside"
              >
                {groups?.map(({ id, name }) => (
                  <Dropdown.Item
                    key={id}
                    eventKey={id}
                    active={group.some((o) => o.name === name)}
                  >
                    {group.some((o) => o.name === name) ? (
                      <span className="material-icons-outlined md-16 me-1">
                        check_box
                      </span>
                    ) : (
                      <span className="material-icons-outlined md-16 me-1">
                        check_box_outline_blank
                      </span>
                    )}
                    {name}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item eventKey="clear" disabled={group.length === 0}>
                  Clear selections
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              md={4}
              controlId="site"
              className="mb-3"
            >
              <Form.Label>Filter by site</Form.Label>
              <DropdownButton
                title={gettitle(site, "site")}
                id="multi-select-dropdown"
                onSelect={(e) => handleSelect(e, setSite, sites, site)}
                variant="outline-dark"
                className="no-hover"
                autoClose="outside"
              >
                {sites
                  ?.sort((a, b) =>
                    a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
                  )
                  ?.map(({ id, name }) => (
                    <Dropdown.Item
                      key={id}
                      eventKey={id}
                      active={site.some((o) => o.name === name)}
                    >
                      {site.some((o) => o.name === name) ? (
                        <span className="material-icons-outlined md-16 me-1">
                          check_box
                        </span>
                      ) : (
                        <span className="material-icons-outlined md-16 me-1">
                          check_box_outline_blank
                        </span>
                      )}
                      {name}
                    </Dropdown.Item>
                  ))}
                <Dropdown.Divider />
                <Dropdown.Item eventKey="clear" disabled={site.length === 0}>
                  Clear selections
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
            <div className="py-1">
              <Button
                variant="link"
                onClick={() => {
                  setShowMoreFilter(!showMoreFilter);
                }}
                className="p-0 mt-n3"
              >
                Show more filters
              </Button>
              <Collapse in={showMoreFilter}>
                <div>
                  <Alert
                    variant="dark"
                    className="bg-light"
                    dismissible
                    onClose={() => setShowMoreFilter(!showMoreFilter)}
                  >
                    <Row>
                      <Form.Group
                        as={Col}
                        xs={12}
                        md={6}
                        controlId="scenarios"
                        className="mb-3"
                      >
                        <Form.Label>Filter by scenario</Form.Label>
                        <DropdownButton
                          title={gettitle(scenario, "scenario")}
                          id="multi-select-dropdown"
                          onSelect={(e) =>
                            handleSelect(e, setScenario, scenarios, scenario)
                          }
                          variant="outline-dark"
                          className="no-hover"
                          autoClose="outside"
                        >
                          {scenarios?.map(({ id, name }) => (
                            <Dropdown.Item
                              key={id}
                              eventKey={id}
                              active={scenario.some((o) => o.name === name)}
                            >
                              {scenario.some((o) => o.name === name) ? (
                                <span className="material-icons-outlined md-16 me-1">
                                  check_box
                                </span>
                              ) : (
                                <span className="material-icons-outlined md-16 me-1">
                                  check_box_outline_blank
                                </span>
                              )}
                              {name}
                            </Dropdown.Item>
                          ))}
                          <Dropdown.Divider />
                          <Dropdown.Item
                            eventKey="clear"
                            disabled={scenario.length === 0}
                          >
                            Clear selections
                          </Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        xs={12}
                        md={6}
                        controlId="locations"
                        className="mb-3"
                      >
                        <Form.Label>Filter by location</Form.Label>
                        <DropdownButton
                          title={gettitle(location, "location")}
                          id="multi-select-dropdown"
                          onSelect={(e) =>
                            handleSelect(e, setLocation, locations, location)
                          }
                          variant="outline-dark"
                          className="no-hover"
                          autoClose="outside"
                        >
                          {locations
                            ?.sort((a, b) =>
                              a?.name
                                ?.toLowerCase()
                                .localeCompare(b?.name?.toLowerCase())
                            )
                            ?.map(({ id, name }) => (
                              <Dropdown.Item
                                key={id}
                                eventKey={id}
                                active={location.some((o) => o.name === name)}
                              >
                                {location.some((o) => o.name === name) ? (
                                  <span className="material-icons-outlined md-16 me-1">
                                    check_box
                                  </span>
                                ) : (
                                  <span className="material-icons-outlined md-16 me-1">
                                    check_box_outline_blank
                                  </span>
                                )}
                                {name}
                              </Dropdown.Item>
                            ))}
                          <Dropdown.Divider />
                          <Dropdown.Item
                            eventKey="clear"
                            disabled={location.length === 0}
                          >
                            Clear selections
                          </Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>
                    </Row>
                  </Alert>
                </div>
              </Collapse>
            </div>
          </Row>
          <div className="d-flex justify-content-between align-items-center">
            <Form.Group
              as={Col}
              controlId="chartSwitch"
              className="mb-3 d-flex justify-content-start"
            >
              <Form.Check
                type="switch"
                id="custom-switch-line"
                label={
                  <small className="text-uppercase text-muted fw-semibold">
                    Show individual projects
                  </small>
                }
                checked={showAllProjects}
                onChange={(e) => {
                  setShowAllProjects(e.target.checked);
                }}
              />
              <Form.Check
                type="switch"
                id="custom-switch-line-2"
                className="ms-3"
                label={
                  <small className="text-uppercase text-muted fw-semibold">
                    Display External Decarb Levers
                  </small>
                }
                checked={showDecarbLevers}
                onChange={(e) => {
                  setShowDecarbLevers(e.target.checked);
                }}
              />
              <div
                className="customOverlay ms-3 text-muted"
                onMouseOver={showTooltip}
                onFocus={showTooltip}
                onMouseOut={hideTooltip}
                onBlur={hideTooltip}
              >
                <span className="material-icons-outlined md-16 customFilterPointer me-1">
                  info
                </span>
                <div ref={tooltipRef} className="customTooltip">
                  {!!status?.length && (
                    <div>
                      <div>Filter by status</div>
                      {status?.map((data, index) => (
                        <li key={index}>{data?.value}</li>
                      ))}
                    </div>
                  )}
                  {!!group?.length && (
                    <div>
                      <div>Filter by group</div>
                      {group?.map((data, index) => (
                        <li key={index}>{data?.name}</li>
                      ))}
                    </div>
                  )}
                  {!!site?.length && (
                    <div>
                      <div>Filter by site</div>
                      {site?.map((data, index) => (
                        <li key={index}>{data?.name}</li>
                      ))}
                    </div>
                  )}
                  {!!scenario?.length && (
                    <div>
                      <div>Filter by scenario</div>
                      {scenario?.map((data) => (
                        <li>{data?.name}</li>
                      ))}
                    </div>
                  )}
                  {!!location?.length && (
                    <div>
                      <div>Filter by location</div>
                      {location?.map((data) => (
                        <li>{data?.name}</li>
                      ))}
                    </div>
                  )}
                  {!status?.length &&
                    !group?.length &&
                    !site?.length &&
                    !scenario?.length &&
                    !location?.length &&
                    "N/A"}
                </div>
              </div>
              <span className="text-muted">Applied Filters</span>
            </Form.Group>
            <Button
              size="sm"
              title="Reset Filters"
              className="mb-3 downloadChart px-0"
              onClick={() => {
                setStatus([]);
                setGroup([]);
                setSite([]);
                setScenario([]);
                setLocation([]);
              }}
            >
              <span className="material-icons-outlined md-18">restart_alt</span>
            </Button>
          </div>
          <div className="my-3">
            {!isLoading && !error && (
              <>
                <EmissionProjectionsChart
                  selectedScope={selectedScope}
                  selectedLegends={selectedLegends}
                  setSelectedLegends={setSelectedLegends}
                  data={emission || []}
                  projections={projections}
                  scopeTwo={scopeTwo}
                  showAllProjects={showAllProjects}
                  showDecarbLevers={showDecarbLevers}
                  sbtiConfig={memoizedConfig}
                  decarbLever={decarbLever}
                  onChartRendered={onChartRendered}
                  emission={scienceBasedTarget}
                  targetBaselineYear={targetBaselineYear}
                  forecastingYear={gridDecarbonization?.[0]?.year}
                  setYearData={setYearData}
                  setAllYearsData={setAllYearsData}
                  selectedYear={selectedYear}
                  selectedIndex={selectedIndex}
                  yearData={yearData}
                  forecastingConfig={memoizedForecastingConfig}
                  organization={organization}
                  baselineYear={baselineYear}
                  targetEmissions={
                    (selectedScope.includes("1") ? targetScopeOne : 0) +
                    (selectedScope.includes("2") ? targetScopeTwo : 0) +
                    (selectedScope.includes("3") ? targetScopeThree : 0)
                  }
                />
                <small className="text-muted">
                  Only the projects with identified scopes are shown on the
                  roadmap graph
                </small>
              </>
            )}
          </div>
          {error && !isLoading && <ErrorHandler error={error} />}
          {isLoading && <Loader />}
          {isShow && !isLoading && !error && (
            <>
              <div className="mt-5 d-flex justify-content-between">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    size="sm"
                    className="bg-primary bg-opacity-25 text-dark border-0"
                  >
                    Selected Year {selectedYear}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflow: "auto" }}
                  >
                    {emission?.map(({ year }, index) => (
                      <Dropdown.Item
                        key={year}
                        onClick={() => {
                          setSelectedIndex(index);
                          setSelectedYear(year);
                        }}
                      >
                        {year}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {!!allYearsData?.length && (
                  <CsvDownloader
                    data={allYearsData}
                    fileName={`${organization?.name} - Roadmap Table.csv`}
                    className="downloadChart"
                    title="Export Data To Excel (.csv)"
                  />
                )}
              </div>
              <div>
                <h3 className="text-center mt-5">{selectedYear}</h3>
                <Table size="sm" responsive className="my-3">
                  <tbody>
                    {yearData
                      ?.filter((val) => val.name)
                      .map((data, index) => (
                        <tr key={index}>
                          <td className="text-start text-nowrap px-3">
                            <span
                              style={{
                                display: "inline-block",
                                height: 12,
                                width: 12,
                                borderRadius: "50%",
                                marginRight: 10,
                                backgroundColor: `${data?.color}`,
                              }}
                            ></span>{" "}
                            {data?.name}
                          </td>
                          <td className="text-end text-nowrap px-3">
                            {isArray(data?.data)
                              ? formatNumber(Number(data?.data[1])?.toFixed())
                              : formatNumber(Number(data?.data)?.toFixed())}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
          {isShow && (
            <>
              <div className="d-flex justify-content-between">
                <h4>Emissions Reduction by Project</h4>
                {!!projectionCSVData?.length && (
                  <CsvDownloader
                    data={projectionCSVData}
                    fileName={`${organization?.name} - Emissions Reduction Table.csv`}
                    className="downloadChart"
                    title="Export Data To Excel (.csv)"
                  />
                )}
              </div>
              <Table size="sm" responsive striped className="my-3">
                <thead>
                  <tr>
                    <th
                      className="text-nowrap px-3 table-sorter"
                      onClick={() => sortEmission("name")}
                    >
                      Project{" "}
                      <TableSortArrows
                        sortColumn={tableSortState.sortColumn}
                        colName={"name"}
                        sortAsc={tableSortState.sortAsc}
                      />
                    </th>
                    <th
                      className="text-nowrap text-end px-3 table-sorter"
                      onClick={() => sortEmission("scope")}
                    >
                      Scope{" "}
                      <TableSortArrows
                        sortColumn={tableSortState.sortColumn}
                        colName={"scope"}
                        sortAsc={tableSortState.sortAsc}
                      />
                    </th>
                    <th
                      className="text-nowrap text-end px-3 table-sorter"
                      onClick={() =>
                        sortEmission("annualCarbonEmissionReduction")
                      }
                    >
                      Annual Reduction
                      <TableSortArrows
                        sortColumn={tableSortState.sortColumn}
                        colName={"annualCarbonEmissionReduction"}
                        sortAsc={tableSortState.sortAsc}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projections?.map((project, index) => {
                    const { id, name, annualCarbonEmissionReduction, scope } =
                      project;
                    if (!name.includes("Unabated Emissions")) {
                      return (
                        <tr key={id}>
                          <td className="text-nowrap px-3">{name}</td>
                          <td className="text-nowrap text-end px-3">{scope}</td>
                          <td className="text-nowrap text-end px-3">
                            {annualCarbonEmissionReduction}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </>
          )}
          {isShow && !isLoading && !projections?.length && (
            <Alert variant="info">
              There is currently nothing to show here.
            </Alert>
          )}
          <Modal show={show} fullscreen={true} onHide={toggleOffcanvas}>
            <Modal.Header closeButton>
              <Modal.Title>Interactive projections</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={7} md={12} xs={12}>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      controlId="Sectors"
                      className="mb-3"
                    >
                      <Form.Label>Filter by status</Form.Label>
                      <DropdownButton
                        title={gettitle(status, "status")}
                        id="multi-select-dropdown"
                        onSelect={(e) =>
                          handleSelect(
                            e,
                            setStatus,
                            projectCombinedStatusMap,
                            status
                          )
                        }
                        variant="outline-dark"
                        className="no-hover"
                        autoClose="outside"
                      >
                        {projectCombinedStatusMap.map(({ key, value }) => (
                          <Dropdown.Item
                            key={key}
                            eventKey={key}
                            active={status.some((o) => o.value === value)}
                          >
                            {status.some((o) => o.value === value) ? (
                              <span className="material-icons-outlined md-16 me-1">
                                check_box
                              </span>
                            ) : (
                              <span className="material-icons-outlined md-16 me-1">
                                check_box_outline_blank
                              </span>
                            )}
                            {value}
                          </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="clear"
                          disabled={status.length === 0}
                        >
                          Clear selections
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      controlId="Sectors"
                      className="mb-3"
                    >
                      <Form.Label>Filter by group</Form.Label>
                      <DropdownButton
                        title={gettitle(group, "group")}
                        id="multi-select-dropdown"
                        onSelect={(e) =>
                          handleSelect(e, setGroup, groups, group)
                        }
                        variant="outline-dark"
                        className="no-hover"
                        autoClose="outside"
                      >
                        {groups
                          ?.sort((a, b) =>
                            a?.name
                              ?.toLowerCase()
                              .localeCompare(b?.name?.toLowerCase())
                          )
                          ?.map(({ id, name }) => (
                            <Dropdown.Item
                              key={id}
                              eventKey={id}
                              active={group.some((o) => o.name === name)}
                            >
                              {group.some((o) => o.name === name) ? (
                                <span className="material-icons-outlined md-16 me-1">
                                  check_box
                                </span>
                              ) : (
                                <span className="material-icons-outlined md-16 me-1">
                                  check_box_outline_blank
                                </span>
                              )}
                              {name}
                            </Dropdown.Item>
                          ))}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="clear"
                          disabled={group.length === 0}
                        >
                          Clear selections
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={4}
                      controlId="site"
                      className="mb-3"
                    >
                      <Form.Label>Filter by site</Form.Label>
                      <DropdownButton
                        title={gettitle(site, "site")}
                        id="multi-select-dropdown"
                        onSelect={(e) => handleSelect(e, setSite, sites, site)}
                        variant="outline-dark"
                        className="no-hover"
                        autoClose="outside"
                      >
                        {sites
                          ?.sort((a, b) =>
                            a?.name
                              ?.toLowerCase()
                              .localeCompare(b?.name?.toLowerCase())
                          )
                          ?.map(({ id, name }) => (
                            <Dropdown.Item
                              key={id}
                              eventKey={id}
                              active={site.some((o) => o.name === name)}
                            >
                              {site.some((o) => o.name === name) ? (
                                <span className="material-icons-outlined md-16 me-1">
                                  check_box
                                </span>
                              ) : (
                                <span className="material-icons-outlined md-16 me-1">
                                  check_box_outline_blank
                                </span>
                              )}
                              {name}
                            </Dropdown.Item>
                          ))}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="clear"
                          disabled={site.length === 0}
                        >
                          Clear selections
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form.Group>
                    <div className="py-1">
                      <Button
                        variant="link"
                        onClick={() => {
                          setShowMoreFilter(!showMoreFilter);
                        }}
                        className="p-0 mt-n3"
                      >
                        Show more filters
                      </Button>
                      <Collapse in={showMoreFilter}>
                        <div>
                          <Alert
                            variant="dark"
                            className="bg-light"
                            dismissible
                            onClose={() => setShowMoreFilter(!showMoreFilter)}
                          >
                            <Row>
                              <Form.Group
                                as={Col}
                                xs={12}
                                md={6}
                                controlId="scenarios"
                                className="mb-3"
                              >
                                <Form.Label>Filter by scenario</Form.Label>
                                <DropdownButton
                                  title={gettitle(scenario, "scenario")}
                                  id="multi-select-dropdown"
                                  onSelect={(e) =>
                                    handleSelect(
                                      e,
                                      setScenario,
                                      scenarios,
                                      scenario
                                    )
                                  }
                                  variant="outline-dark"
                                  className="no-hover"
                                  autoClose="outside"
                                >
                                  {scenarios?.map(({ id, name }) => (
                                    <Dropdown.Item
                                      key={id}
                                      eventKey={id}
                                      active={scenario.some(
                                        (o) => o.name === name
                                      )}
                                    >
                                      {scenario.some((o) => o.name === name) ? (
                                        <span className="material-icons-outlined md-16 me-1">
                                          check_box
                                        </span>
                                      ) : (
                                        <span className="material-icons-outlined md-16 me-1">
                                          check_box_outline_blank
                                        </span>
                                      )}
                                      {name}
                                    </Dropdown.Item>
                                  ))}
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    eventKey="clear"
                                    disabled={scenario.length === 0}
                                  >
                                    Clear selections
                                  </Dropdown.Item>
                                </DropdownButton>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                xs={12}
                                md={6}
                                controlId="locations"
                                className="mb-3"
                              >
                                <Form.Label>Filter by location</Form.Label>
                                <DropdownButton
                                  title={gettitle(location, "location")}
                                  id="multi-select-dropdown"
                                  onSelect={(e) =>
                                    handleSelect(
                                      e,
                                      setLocation,
                                      locations,
                                      location
                                    )
                                  }
                                  variant="outline-dark"
                                  className="no-hover"
                                  autoClose="outside"
                                >
                                  {locations
                                    ?.sort((a, b) =>
                                      a?.name
                                        ?.toLowerCase()
                                        .localeCompare(b?.name?.toLowerCase())
                                    )
                                    ?.map(({ id, name }) => (
                                      <Dropdown.Item
                                        key={id}
                                        eventKey={id}
                                        active={location.some(
                                          (o) => o.name === name
                                        )}
                                      >
                                        {location.some(
                                          (o) => o.name === name
                                        ) ? (
                                          <span className="material-icons-outlined md-16 me-1">
                                            check_box
                                          </span>
                                        ) : (
                                          <span className="material-icons-outlined md-16 me-1">
                                            check_box_outline_blank
                                          </span>
                                        )}
                                        {name}
                                      </Dropdown.Item>
                                    ))}
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    eventKey="clear"
                                    disabled={location.length === 0}
                                  >
                                    Clear selections
                                  </Dropdown.Item>
                                </DropdownButton>
                              </Form.Group>
                            </Row>
                          </Alert>
                        </div>
                      </Collapse>
                    </div>
                  </Row>
                  <div className="d-flex justify-content-between align-items-center">
                    <Form.Group
                      as={Col}
                      controlId="chartSwitch"
                      className="mb-3 d-flex justify-content-start"
                    >
                      <Form.Check
                        type="switch"
                        id="custom-switch-line"
                        label={
                          <small className="text-uppercase text-muted fw-semibold">
                            Show individual projects
                          </small>
                        }
                        checked={showAllProjects}
                        onChange={(e) => {
                          setShowAllProjects(e.target.checked);
                        }}
                      />
                      <Form.Check
                        type="switch"
                        id="custom-switch-line-2"
                        className="ms-3"
                        label={
                          <small className="text-uppercase text-muted fw-semibold">
                            Display External Decarb Levers
                          </small>
                        }
                        checked={showDecarbLevers}
                        onChange={(e) => {
                          setShowDecarbLevers(e.target.checked);
                        }}
                      />
                      <div
                        className="customOverlay ms-3 text-muted"
                        onMouseOver={showTooltip}
                        onFocus={showTooltip}
                        onMouseOut={hideTooltip}
                        onBlur={hideTooltip}
                      >
                        <span className="material-icons-outlined md-16 customFilterPointer me-1">
                          info
                        </span>
                        <div ref={tooltipRef} className="customTooltip">
                          {!!status?.length && (
                            <div>
                              <div>Filter by status</div>
                              {status?.map((data, index) => (
                                <li key={index}>{data?.value}</li>
                              ))}
                            </div>
                          )}
                          {!!group?.length && (
                            <div>
                              <div>Filter by group</div>
                              {group?.map((data, index) => (
                                <li key={index}>{data?.name}</li>
                              ))}
                            </div>
                          )}
                          {!!site?.length && (
                            <div>
                              <div>Filter by site</div>
                              {site?.map((data, index) => (
                                <li key={index}>{data?.name}</li>
                              ))}
                            </div>
                          )}
                          {!!scenario?.length && (
                            <div>
                              <div>Filter by scenario</div>
                              {scenario?.map((data) => (
                                <li>{data?.name}</li>
                              ))}
                            </div>
                          )}
                          {!!location?.length && (
                            <div>
                              <div>Filter by location</div>
                              {location?.map((data) => (
                                <li>{data?.name}</li>
                              ))}
                            </div>
                          )}
                          {!status?.length &&
                            !group?.length &&
                            !site?.length &&
                            !scenario?.length &&
                            !location?.length &&
                            "N/A"}
                        </div>
                      </div>
                      <span className="text-muted">Applied Filters</span>
                    </Form.Group>
                    <Button
                      size="sm"
                      title="Reset Filters"
                      className="mb-3 downloadChart px-0"
                      onClick={() => {
                        setStatus([]);
                        setGroup([]);
                        setSite([]);
                        setScenario([]);
                        setLocation([]);
                      }}
                    >
                      <span className="material-icons-outlined md-18">
                        restart_alt
                      </span>
                    </Button>
                  </div>
                  <div className="border rounded-3 py-3 mb-3">
                    {!isLoading && !error && (
                      <>
                        <EmissionProjectionsChart
                          showDecarbLevers={showDecarbLevers}
                          selectedScope={selectedScope}
                          selectedLegends={selectedLegends}
                          setSelectedLegends={setSelectedLegends}
                          data={emission || []}
                          projections={projections}
                          scopeTwo={scopeTwo}
                          showAllProjects={showAllProjects}
                          sbtiConfig={memoizedConfig}
                          decarbLever={decarbLever}
                          onChartRendered={onChartRendered}
                          forecastingConfig={memoizedForecastingConfig}
                          organization={organization}
                          setAllYearsData={setAllYearsData}
                        />
                        <small className="text-muted">
                          Only the projects with identified scopes are shown on
                          the roadmap graph
                        </small>
                      </>
                    )}
                  </div>
                </Col>
                <Col lg={5} md={12} xs={12}>
                  <h2>Abatement projects</h2>
                  <hr />
                  {projectFilter(
                    projects,
                    group,
                    status,
                    site,
                    scenario,
                    location
                  )
                    .map((project, index) => {
                      let obj = {
                        ...project,
                        lineStyle: {
                          color: project?.group?.color || colorsPallete[index],
                        },
                        itemStyle: {
                          color: project?.group?.color || colorsPallete[index],
                        },
                        areaStyle: {
                          type: "default",
                          color: project?.group?.color || colorsPallete[index],
                          opacity: 1,
                        },
                      };
                      return { ...obj };
                    })
                    .map((p) => {
                      return (
                        <InteractiveProject
                          project={p}
                          onProjectUpdate={handleProjectUpdate}
                          key={p.id}
                        />
                      );
                    })}
                  <>
                    {!error &&
                      !isLoading &&
                      projectFilter(
                        projects,
                        group,
                        status,
                        site,
                        scenario,
                        location
                      ).length === 0 && (
                        <Alert variant="info">
                          There is currently nothing to show here.
                        </Alert>
                      )}
                  </>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
    </>
  );
};

function createTimeline(data = []) {
  return data
    .filter(
      ({ projectLifetime, emissionSavingsStartDate }) =>
        !isNaN(Number(projectLifetime)) && !!emissionSavingsStartDate
    )
    .map(({ projectLifetime, emissionSavingsStartDate, ...rest }) => {
      const startYear = parseInt(
        new Date(emissionSavingsStartDate).getFullYear()
      );
      return {
        ...rest,
        projectLifetime,
        startYear: startYear,
        finalYear: sum(projectLifetime - 1, startYear),
      };
    });
}

function updateTimeline(data = [], finalYear) {
  return data.map(({ finalYear: year, autoRenew, ...rest }) => ({
    ...rest,
    finalYear: !!autoRenew ? finalYear : year,
  }));
}

function getFinalYear(timeLine = [], emission = []) {
  return 2050;
}

export default Projections;
