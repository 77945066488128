import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { Form, Alert, Table, Spinner } from "react-bootstrap";

import { put, get } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import SitePermissioning from "../SitePermissioning/SitePermissioning";

const OrganizationPermissioning = ({ account, member, organizations }) => {
  const subscribedPromises = useRef([]);

  const [memberOrganizations, setMemberOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetchMemberOrganizations = useCallback(() => {
    setError(null);
    setIsLoading(true);
    let fetchMemberOrganizations = get(`members/${member.id}/organizations`, {
      params: { accountId: account.id },
    });

    fetchMemberOrganizations.promise
      .then((response) => {
        setMemberOrganizations(response.data);
        setIsLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(fetchMemberOrganizations);
  }, [member.id, account.id]);

  const updateMemberOrganization = (memberOrgs) => {
    setError(null);
    setIsLoading(true);

    const updateOrganizations = put(`members/${member.id}/organizations`, {
      accountId: account.id,
      organizationIds: memberOrgs.map((org) => org.id),
    });

    updateOrganizations.promise
      .then((response) => {
        setMemberOrganizations([...memberOrgs]);
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchMemberOrganizations();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchMemberOrganizations]);

  return (
    <>
      <div className="p-3 border-start border-end border-bottom">
        <p>Select organizations and sites to provide access to the member.</p>
        {!organizations.length ? (
          <Alert variant="info">
            There are currently no organizations to show. Add organizations to
            assign access.
          </Alert>
        ) : (
          <Table responsive hover size="sm" id="organizationAccessTable">
            <thead>
              <tr>
                <th>Organization</th>
                <th className="text-end">Can Access</th>
              </tr>
            </thead>

            <tbody>
              {(() => {
                let allOrgsAccess =
                  memberOrganizations.length === organizations.length;
                return (
                  <tr className="bg-light border-dark border-2">
                    <td>All Organizations</td>
                    <td className="text-end">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        value={allOrgsAccess}
                        checked={allOrgsAccess}
                        onChange={(ev) => {
                          if (allOrgsAccess) updateMemberOrganization([]);
                          else updateMemberOrganization([...organizations]);
                        }}
                      />
                      {isLoading && <Spinner />}
                      {error && <ErrorHandler error={error} />}
                    </td>
                  </tr>
                );
              })()}
              {organizations.map((org) => {
                let canAcess =
                  memberOrganizations &&
                  !!memberOrganizations.find((item) => item.id === org.id);
                return (
                  <Fragment key={org.id + canAcess}>
                    <tr>
                      <td>{org.name}</td>
                      <td className="text-end">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          value={canAcess}
                          checked={canAcess}
                          onChange={(ev) => {
                            if (canAcess)
                              updateMemberOrganization([
                                ...memberOrganizations.filter(
                                  (item) => item.id !== org.id
                                ),
                              ]);
                            else
                              updateMemberOrganization([
                                ...memberOrganizations,
                                org,
                              ]);
                          }}
                        />
                        {isLoading && <Spinner />}
                        {error && <ErrorHandler error={error} />}
                      </td>
                    </tr>
                    {canAcess && (
                      <tr className="bg-light">
                        <td colSpan={2}>
                          <SitePermissioning
                            member={member}
                            organization={org}
                            onSitesUpdated={console.log}
                          />
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};

OrganizationPermissioning.propTypes = {
  account: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
};

export default OrganizationPermissioning;
