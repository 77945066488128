import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./EmissionsBreakDown.scss";
import * as echarts from "echarts";

import { getFormattedEmission } from "utils/StringUtils";

const EmissionsBreakDown = ({ sitesSummary, onChartRendered }) => {
  const el = useRef(null);

  const emissionsByYear = sitesSummary.reduce((acc, item) => {
    const year = item.yearEnded;
    if (!acc[year]) {
      acc[year] = {
        scopeOneEmissions: 0,
        scopeTwoEmissions: 0,
        scopeThreeEmissions: 0,
      };
    }
    acc[year].scopeOneEmissions += item.scopeOneEmissions || 0;
    acc[year].scopeTwoEmissions += item.scopeTwoEmissions || 0;
    acc[year].scopeThreeEmissions += item.scopeThreeEmissions || 0;
    return acc;
  }, {});

  const years = Object.keys(emissionsByYear);

  const scopeOneSeries = [
    {
      name: "Scope 1",
      type: "bar",
      stack: "emissions",
      data: years.map((year) => emissionsByYear[year].scopeOneEmissions),
      barMaxWidth: 60,
      barMinWidth: 10,
      emphasis: { focus: "series", blurScope: "coordinateSystem" },
    },
  ];

  const scopeTwoSeries = [
    {
      name: "Scope 2",
      type: "bar",
      stack: "emissions",
      data: years.map((year) => emissionsByYear[year].scopeTwoEmissions),
      barMaxWidth: 60,
      barMinWidth: 10,
      emphasis: { focus: "series", blurScope: "coordinateSystem" },
    },
  ];

  const scopeThreeSeries = [
    {
      name: "Scope 3",
      type: "bar",
      stack: "emissions",
      data: years.map((year) => emissionsByYear[year].scopeThreeEmissions),
      barMaxWidth: 60,
      barMinWidth: 10,
      emphasis: { focus: "series", blurScope: "coordinateSystem" },
    },
  ];

  const series = [...scopeOneSeries, ...scopeTwoSeries, ...scopeThreeSeries];

  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value ? `${getFormattedEmission(value)} tCO₂e` : "N/A",
        axisPointer: { type: "shadow" },
      },
      color: ["#b5c334", "#fac858", "#eb8146"],
      legend: {
        backgroundColor: "white",
        left: "3%",
        data: [
          {
            name: "Scope 1",
          },
          {
            name: "Scope 2",
          },
          {
            name: "Scope 3",
          },
        ],
      },
      xAxis: {
        type: "category",
        data: years,
      },
      yAxis: {
        type: "value",
        max: (value) => value.max + value.max * 0.3,
        axisLabel: {
          formatter: (value) =>
            Intl.NumberFormat("en-US", { notation: "compact" }).format(value) +
            " tCO₂e",
        },
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          restore: {},
          saveAsImage: {
            name: `Emissons By Scope`,
          },
        },
      },
      dataZoom: [{ minValueSpan: 1 }],
      grid: { left: "3%", right: "4%", bottom: "50px", containLabel: true },
      series: series,
    };

    const chart = echarts.init(el.current);
    chart.setOption(option);

    chart.on("finished", () => {
      let src = chart.getDataURL({
        pixelRatio: 2,
        backgroundColor: "#fff",
      });

      onChartRendered(src);
    });

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, sitesSummary]);

  return (
    <div className="EmissionsBySiteChart">
      <div className="emissions-site-chart-container me-auto" ref={el} />
    </div>
  );
};

EmissionsBreakDown.propTypes = {
  sitesSummary: PropTypes.array.isRequired,
};

export default EmissionsBreakDown;
