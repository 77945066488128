import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./EmissionsByYear.scss";
import * as echarts from "echarts";
import { getFormattedEmission } from "utils/StringUtils";

function EmissionsByYear({ organizationInfo, onChartRendered }) {
  const el = useRef(null);

  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "item",
        valueFormatter: (value) =>
          value ? `${getFormattedEmission(value)} tCO₂e` : "N/A",
        axisPointer: { type: "shadow" },
      },
      legend: {
        data: [
          {
            name: "Emissions",
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 1, color: "#b5c334" },
                  { offset: 0, color: "#eb8146" },
                ],
                global: false,
              },
            },
          },
          { name: "Revenue" },
        ],
      },
      grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      yAxis: [
        {
          name: "Emissions",
          type: "value",
          scale: false,
          splitNumber: 3,
          axisLabel: {
            formatter: function (value) {
              return (
                Intl.NumberFormat("en-US", { notation: "compact" }).format(
                  value
                ) + " tCO₂e"
              );
            },
          },
        },
        {
          type: "value",
          name: "Revenue",
          scale: false,
          splitNumber: 3,
          axisLabel: {
            formatter: (value) =>
              `${Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(value)}k`,
          },
        },
      ],
      xAxis: {
        type: "category",
        data: organizationInfo.map((item) => item.yearEnded.substring(0, 4)),
      },
      visualMap: {
        show: false,
        min: Math.min(
          ...organizationInfo.map((item) => item.organizationEmissions)
        ),
        max: Math.max(
          ...organizationInfo.map((item) => item.organizationEmissions)
        ),
        dimension: 1,
        inRange: { color: ["#b5c334", "#FFCE34", "#eb8146"] },
      },
      series: [
        {
          name: "Emissions",
          type: "bar",
          barMaxWidth: 60,
          data: organizationInfo.map((item) => {
            return {
              value: item.organizationEmissions,
              itemStyle: { opacity: 0.8 },
            };
          }),
        },
        {
          name: "Revenue",
          yAxisIndex: 1,
          type: "line",
          tooltip: {
            valueFormatter: (value) =>
              `${Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(value)}k`,
          },
          data: organizationInfo.map((item) => item.revenue),
          lineStyle: { color: "#27727b", opacity: 0.8 },
          symbol: "circle",
          symbolSize: 8,
          itemStyle: {
            borderWidth: 2,
            borderColor: "#27727b",
            opacity: 0.5,
          },
        },
      ],
    };
    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetHeight + 50,
    });

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetHeight,
      });
    }

    chart.on("finished", () => {
      let src = chart.getDataURL({
        pixelRatio: 2,
        backgroundColor: "#fff",
      });

      onChartRendered(src);
    });

    chart.setOption(option);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, organizationInfo]);

  return (
    <div className="EmissionsByYearChart">
      <div className="emissions-by-year-chart-container me-auto" ref={el} />
    </div>
  );
}

EmissionsByYear.propTypes = {
  organizationInfo: PropTypes.array.isRequired,
};

export default EmissionsByYear;
