import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import "./OrganizationDetail.scss";

import {
  Col,
  Row,
  Button,
  Nav,
  Alert,
  Badge,
  Accordion,
  Dropdown,
} from "react-bootstrap";
import {
  Link,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { groupBy } from "lodash";

import { get } from "utils/DeApi";
import { getFormattedEmission } from "utils/StringUtils";
import { formatNumber } from "./Forecasting/helper";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import OrganizationUpdate from "../OrganizationUpdate/OrganizationUpdate";
import OrganizationDelete from "../OrganizationDelete/OrganizationDelete";
import EmissionsBySite from "./BottomUpAnalysis/EmissionsBySite/EmissionsBySite";
import TopDownAnalysis from "./TopDownAnalysis/TopDownAnalysis";
import { AccountContext } from "contexts/AccountProvider";
import BottomUpAnalysis from "./BottomUpAnalysis/BottomUpAnalysis";
import EmissionsByYear from "./TopDownAnalysis/EmissionsByYear/EmissionsByYear";
import Abatement from "./Abatement/AbatementProjects/Abatement";
import CarbonIntensity from "./CarbonIntensity/CarbonIntensity";
import AbatementChart from "./Abatement/AbatementProjects/AbatementChart/AbatementChart";
import CarbonIntensityByYear from "./CarbonIntensity/CarbonIntensityByYear/CarbonIntensityByYear";
import OrganizationExport from "../OrganizationExport/OrganizationExport";
import ManageEmissions from "./Forecasting/ManageEmissions/ManageEmissions";
import ForecastingOverview from "./Forecasting/ForecastingOverview/ForecastingOverview";
import EconomicGrowth from "./Forecasting/EconomicGrowth/EconomicGrowth";
import GridDecarbonization from "./Forecasting/GridDecarbonization/GridDecarbonization";
import Projections from "./Forecasting/Projections/Projections";
import SBTinitiative from "./Forecasting/SBTinitiative/SBTinitiative";
import HeatingFuelDecarbonization from "./Forecasting/HeatingFuelDecarbonization/HeatingFuelDecarbonization";
import OrganizationActivities from "./BottomUpAnalysis/OrganizationActivities/OrganizationActivities";
import ScopeTwoPreferenceToggler from "../Site/ScopeTwoPreferenceToggler/ScopeTwoPreferenceToggler";
import NzifDetail from "./NZIF/NzifDetail";
import TransportDecarbonization from "./Forecasting/TransportDecarbonization/TransportDecarbonization";
import ManageTargetEmissions from "./Forecasting/SBTinitiative/ManageTargetEmissions/ManageTargetEmissions";
import { post } from "utils/DeApi";
import UserDefinedSBTinitiative from "./Forecasting/UserDefinedSBTinitiative/UserDefinedSBTinitiative";
import AbatementActions from "./Abatement/AbatementActions/AbatementActions";
import ReductionPotential from "./Abatement/ReductionPotential/ReductionPotential";
import OrganizationQodScoreSummary from "components/Organization/OrganizationDetail/QodScoreSummary/OrganizationQodScoreSummary";
import MiscLever from "./Forecasting/MiscLever/MiscLever";
import Profile from "./Profile/Profile";

import UserCategories from "utils/userCategories";
import SBTiOverview from "./Forecasting/SBTinitiative/SBTiOverview/SBTiOverview";
import DataLayer from "utils/DataLayer";
import ScenarioPlanning from "./Abatement/ScenarioPlanning/ScenarioPlanning";

import AuthRoute from "auth/authRoute";
import OverviewSetting from "./OverviewSetting/OverviewSetting";
import EmissionsBreakDown from "./BottomUpAnalysis/EmissionsBreakDown/EmissionsBreakDown";

const OrganizationDetail = ({ organization, onOrganizationUpdated }) => {
  const subscribedPromises = useRef([]);
  const [sites, setSites] = useState([]);
  const [emissions, setEmissions] = useState();
  const [targetEmissions, setTargetEmissions] = useState();
  const [isTargetUpdate, setIsTargetUpdate] = useState();
  const [isForecastingUpdate, setIsForecastingUpdate] = useState();
  const [organizationInfo, setOrganizationInfo] = useState([]);
  const [bottomUpSummary, setBottomUpSummary] = useState([]);
  const location = useLocation();

  const [topDownChart, setTopDownChart] = useState();
  const [bottomUpChart, setBottomUpChart] = useState();
  const [emissionByScopeChart, setEmissionByScopeChart] = useState();
  const [carbonIntensityChart, setCarbonIntensityChart] = useState();
  const [abatementChart, setAbatementChart] = useState();
  const [forecastingChart, setForecastingChart] = useState();
  const [sbtiOverviewChart, setSbtiOverviewChart] = useState();
  const [roadmapChart, setRoadmapChart] = useState();

  const [totalCarbonSaving, setTotalCarbonSaving] = useState();
  const [totalRoadmapEmissions, setTotalRoadmapEmissions] = useState([]);

  const [abatementStatus, setAbatementStatus] = useState([
    {
      value: "Implemented - In Flight",
      key: 1,
    },
    {
      value: "Not Committed",
      key: 2,
    },
    {
      value: "Committed - Not Started",
      key: 3,
    },
    {
      value: "Implemented - Completed",
      key: 4,
    },
    {
      value: "To be Evaluated",
      key: 6,
    },
  ]);
  const [abatementGroup, setAbatementGroup] = useState();
  const [abatementSite, setAbatementSite] = useState();
  const [abatementScenario, setAbatementScenario] = useState();
  const [abatementLocation, setAbatementLocation] = useState();

  const [organizationPortfolios, setOrganizationPortfolios] = useState([]);

  const [projects, setProjects] = useState([]);
  const [carbonIntensity, setCarbonIntensity] = useState([]);
  const [carbonIntensityEnergy, setCarbonIntensityEnergy] = useState([]);

  const [economicGrowth, setEconomicGrowth] = useState([]);
  const [gridDecarbonization, setGridDecarbonization] = useState([]);
  const [gridDecarbonizationData, setGridDecarbonizationData] = useState([]);
  const [decarbOfHeatingFuels, setDecarbOfHeatingFuels] = useState([]);
  const [decarbOfHeatingFuelsData, setDecarbOfHeatingFuelsData] = useState([]);
  const [decarbOfTransport, setDecarbOfTransport] = useState([]);
  const [decarbOfTransportData, setDecarbOfTransportData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [locations, setLocations] = useState([]);
  const [siteLocations, setSiteLocations] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const recentYear = new Date().getFullYear() - 1;
  const [orgPcafSummary, setOrgPcafSummary] = useState([]);
  const [orgSummary, setOrgSummary] = useState();
  const [miscDecarb, setMiscDecarb] = useState([]);
  const [bauDecarb, setBauDecarb] = useState([]);

  const [
    economicGrowthEmissionPercentage,
    setEconomicGrowthEmissionPercentage,
  ] = useState(100);
  const [
    gridDecarbonizationEmissionPercentage,
    setGridDecarbonizationEmissionPercentage,
  ] = useState([]);
  const [
    gridDecarbonizationEmissionPercentageThree,
    setGridDecarbonizationEmissionPercentageThree,
  ] = useState([]);
  const [
    heatingFuelDecarbEmissionPercentage,
    setHeatingFuelDecarbEmissionPercentage,
  ] = useState([]);
  const [
    heatingFuelDecarbEmissionPercentageThree,
    setHeatingFuelDecarbEmissionPercentageThree,
  ] = useState([]);
  const [
    transportDecarbEmissionPercentage,
    setTransportDecarbEmissionPercentage,
  ] = useState([]);
  const [
    transportDecarbEmissionPercentageThree,
    setTransportDecarbEmissionPercentageThree,
  ] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const account = useContext(AccountContext);
  const [scopeTwoPreference, setScopeTwoPreference] = useState(0); // market based is preference 1 or true and location based is preference 0 or false

  const [activityTypes, setActivityTypes] = useState([]);

  const [showSummaryDropdown, setShowSummaryDropdown] = useState(false);

  const [buttonLoadingState, setButtonLoadingState] = useState(false);

  const breadcrumbs = [
    { name: "Dashboard", link: "/" },
    {
      name: organization.name,
      link: `/organizations/${organization.id}`,
    },
  ];

  const userCategory = UserCategories();

  const memoizedConfig = useMemo(() => {
    if (emissions?.forecastingConfig) {
      return JSON.parse(emissions?.forecastingConfig);
    } else {
      return [];
    }
  }, [emissions?.forecastingConfig]);

  const toggleForecastingAccordion = () => {
    const p = location.pathname;
    if (p.includes("projections")) return 1;
    if (p.includes("economic-growth")) return 1;
    if (p.includes("grid-decarbonization")) return 1;
    if (p.includes("heating-fuels-decarbonization")) return 1;
    if (p.includes("roadmap")) return 1;

    return 0;
  };

  const toggleTargetSettingsAccordion = () => {
    const p = location.pathname;
    const currentType =
      targetEmissions?.sbtiConfig &&
      JSON.parse(targetEmissions?.sbtiConfig).find(
        (target) => target.type !== "sbti"
      )?.type;

    if (p.includes("sbti-overview")) return 1;
    if (p.includes("science-based-target-initiative")) return 1;
    if (p.includes(encodeURIComponent(currentType))) return 1;

    return 0;
  };

  const toggleAbatementAccordion = () => {
    const p = location.pathname;
    if (p.includes("abatement")) return 1;
    if (p.includes("reduction-potential")) return 1;
    if (p.includes("scenario")) return 1;

    return 0;
  };

  const toggleGHGAccordion = () => {
    const p = location.pathname;
    if (p.includes("top-down")) return 1;
    if (p.includes("bottom-up")) return 1;
    if (p.includes("carbon-intensity")) return 1;

    return 0;
  };

  const navigate = useNavigate();

  function fetchDecarbBAU(organizationId) {
    const bauPromise = get(
      `organizations/${organizationId}/bau-decarbonization`
    );
    Promise.all([bauPromise.promise])
      .then((responses) => {
        setBauDecarb(responses[0]?.data || []);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(bauPromise);
  }
  const fetchMiscLever = useCallback(
    (organizationId) => {
      let arr = [];
      if (!!memoizedConfig.length) {
        const config = memoizedConfig.filter((lever) => lever.type);
        config.forEach((lever) => {
          arr = [
            ...arr,
            get(
              `organizations/${organizationId}/miscellaneous-decarbonization`,
              {
                params: { type: lever.type },
              }
            ),
          ];
        });

        if (arr.length) {
          Promise.all(arr.map((promise) => promise.promise))
            .then((responses) => {
              const decarb = responses.filter(
                (response) => response.data.length
              );
              fetchDecarbBAU(organizationId);
              setMiscDecarb(decarb);
            })
            .catch((error) => {
              if (!error.isCanceled) {
                setError(error);
                setIsLoading(false);
              }
            });
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }

      subscribedPromises.current.push(...arr);
    },
    [memoizedConfig]
  );

  const fetchOrganizationDetails = useCallback(() => {
    setIsLoading(true);
    setError(null);

    const initialPromises = [
      get(`organizations/${organization.id}/sites`, {
        params: { "filter[preference]": scopeTwoPreference },
      }),
      get(`organizations/${organization.id}/organization-details`, {
        params: { "filter[preference]": scopeTwoPreference },
      }),
      get(`organizations/${organization.id}/sites-summary`, {
        params: { "filter[preference]": scopeTwoPreference },
      }),
      get(`organizations/${organization.id}/abatement-projects`),
      get(`organizations/${organization.id}/carbon-intensity`),
      get(`organizations/${organization.id}/carbon-intensity-energy`),
      get(`/organizations/${organization.id}/portfolios`),
      get(`organizations/${organization.id}/emissions`, {
        params: { "filter[preference]": scopeTwoPreference },
      }),
      get("activity-types"),
      get(`organizations/${organization.id}/science-based-target`),
      // get(`organizations/${organization.id}/economic-growth`),
      // get(`organizations/${organization.id}/grid-decarbonization`),
      // get(`organizations/${organization.id}/heating-fuels-decarbonization`),
      // get(`organizations/${organization.id}/transport-decarbonization`),
      get(`organizations/${organization.id}/groups`),
      get(`organizations/${organization.id}/scenarios`),
      get(
        `organizations/${organization.id}/site-custom-locations?in_project=true`
      ),
      get(`organizations/${organization.id}/site-custom-locations`),
      get(`/organizations/${organization.id}/pcaf-rollup`, {
        params: { yearEnded: `${recentYear}-12-31` },
      }),
      get(`organizations/${organization.id}/custom-fields`),
    ];

    subscribedPromises.current.push(...initialPromises);
    Promise.all(initialPromises.map((p) => p.promise))
      .then((responses) => {
        setError(null);
        setSites(responses[0].data);
        setOrganizationInfo(
          responses[1].data
            .filter((item) => !!item.emissionFactor)
            .sort((a, b) => a.yearEnded.localeCompare(b.yearEnded))
        );
        setBottomUpSummary(responses[2].data);
        setProjects(responses[3].data);
        setCarbonIntensity(responses[4].data.reverse());
        setCarbonIntensityEnergy(responses[5].data);
        setOrganizationPortfolios(responses[6].data);
        setEmissions(responses[7].data[0] || null);
        setActivityTypes(responses[8].data);
        setTargetEmissions(responses[9]?.data.at(0) || {});
        // setEconomicGrowth(
        //   responses[10]?.data?.[0] ? responses[10]?.data?.[0]?.data : []
        // );
        // setEconomicGrowthEmissionPercentage(
        //   responses[10]?.data?.[0]
        //     ? responses[10]?.data?.[0]?.scopeThreeEmissionPercentage
        //     : []
        // );
        // setGridDecarbonization(
        //   responses[11]?.data?.[0] ? responses[11]?.data?.[0]?.data : []
        // );
        // setGridDecarbonizationData(responses[11]?.data?.[0] || []);
        // setGridDecarbonizationEmissionPercentage(
        //   responses[11]?.data?.[0]
        //     ? responses[11]?.data?.[0]?.scopeTwoEmissionPercentage
        //     : []
        // );
        // setGridDecarbonizationEmissionPercentageThree(
        //   responses[11]?.data?.[0]
        //     ? responses[11]?.data?.[0]?.scopeThreeEmissionPercentage
        //     : []
        // );
        // setDecarbOfHeatingFuels(
        //   responses[12]?.data?.[0] ? responses[12]?.data?.[0]?.data : []
        // );
        // setDecarbOfHeatingFuelsData(responses[12]?.data?.[0] || []);
        // setHeatingFuelDecarbEmissionPercentage(
        //   responses[12]?.data?.[0]
        //     ? responses[12]?.data?.[0]?.scopeOneEmissionPercentage
        //     : []
        // );
        // setHeatingFuelDecarbEmissionPercentageThree(
        //   responses[12]?.data?.[0]
        //     ? responses[12]?.data?.[0]?.scopeThreeEmissionPercentage
        //     : []
        // );
        // setDecarbOfTransport(
        //   responses[13]?.data?.[0] ? responses[13]?.data?.[0]?.data : []
        // );
        // setDecarbOfTransportData(responses[13]?.data?.[0] || []);
        // setTransportDecarbEmissionPercentage(
        //   responses[13]?.data?.[0]
        //     ? responses[13]?.data?.[0]?.scopeOneEmissionPercentage
        //     : []
        // );
        // setTransportDecarbEmissionPercentageThree(
        //   responses[13]?.data?.[0]
        //     ? responses[13]?.data?.[0]?.scopeThreeEmissionPercentage
        //     : []
        // );
        setGroups(responses[10]?.data || []);
        setScenarios(responses[11]?.data || []);
        setLocations(responses[12]?.data || []);
        setSiteLocations(responses[13]?.data || []);
        setOrgSummary({ ...responses[14].summary });
        setOrgPcafSummary([...responses[14].data]);
        setCustomFields(responses[15]?.data || []);
        setIsLoading(false);
        // if (
        //   !memoizedConfig?.length ||
        //   !memoizedConfig.some((lever) => lever?.type)
        // ) {
        //   setIsLoading(false);
        // }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  }, [organization.id, scopeTwoPreference]);

  // useEffect(() => {
  //   if (!!memoizedConfig?.length) {
  //     fetchMiscLever(organization.id);
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, [organization.id, memoizedConfig, scopeTwoPreference, fetchMiscLever]);

  useEffect(() => {
    const years = [2021, 2022, 2023];
    if (emissions === null && bottomUpSummary.length) {
      const filterSitesByYear = (sites = [], year = "") =>
        sites.filter(({ yearEnded }) => parseInt(yearEnded) === parseInt(year));

      const sumOfScopes = (sites, year) => {
        return filterSitesByYear(sites, year).reduce(
          (prev, curr) => {
            return {
              scopeOneEmissions:
                prev.scopeOneEmissions + curr.scopeOneEmissions,
              scopeTwoEmissions:
                prev.scopeTwoEmissions + curr.scopeTwoEmissions,
              scopeThreeEmissions:
                prev.scopeThreeEmissions + curr.scopeThreeEmissions,
              year: year,
            };
          },
          {
            scopeOneEmissions: 0,
            scopeTwoEmissions: 0,
            scopeThreeEmissions: 0,
          }
        );
      };
      let currObj = [];
      for (const element of bottomUpSummary) {
        const { yearEnded } = element;
        if (yearEnded && !currObj.includes(yearEnded)) {
          currObj.push(yearEnded);
        }
      }

      currObj.sort((a, b) => Number(a) - Number(b));
      if (
        currObj.length &&
        years.includes(Number(currObj[currObj.length - 1]))
      ) {
        let { scopeOneEmissions, scopeTwoEmissions, scopeThreeEmissions } =
          sumOfScopes(bottomUpSummary, currObj[currObj.length - 1]);
        const data = {
          organization_id: organization.id,
          emissions_scope_one: !isNaN(Number(scopeOneEmissions))
            ? Number(scopeOneEmissions)
            : "",
          emissions_scope_two: !isNaN(Number(scopeTwoEmissions))
            ? Number(scopeTwoEmissions)
            : "",
          emissions_scope_three: !isNaN(Number(scopeThreeEmissions))
            ? Number(scopeThreeEmissions)
            : "",
          limit_reduction_percentage: 50,
          baseline_year: currObj[currObj.length - 1],
          forecastingConfig: JSON.stringify([
            {
              title: "Growth in Production/Turnover",
              link: "economic-growth",
              scopeOne: true,
              scopeTwo: true,
              scopeThree: false,
              isInForecasting: true,
              isInBau: true,
            },
          ]),
        };
        const organizationPromise = post(
          `organizations/${organization.id}/emissions`,
          data
        );
        organizationPromise.promise.then((response) => {
          setEmissions(response.data);
        });
        subscribedPromises.current.push(organizationPromise);
      }
    }
    setTimeout(() => {
      setButtonLoadingState(false);
    }, 10000);
  }, [emissions, bottomUpSummary, organization.id]);

  useEffect(() => {
    fetchOrganizationDetails();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchOrganizationDetails]);

  useEffect(() => {
    setButtonLoadingState(true);
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  function getAbatementPotential() {
    if (projects.length === 0) return 0;
    return projects
      .filter(
        ({ annualCarbonEmissionReduction }) =>
          !isNaN(annualCarbonEmissionReduction)
      )
      .reduce(
        (previous, { annualCarbonEmissionReduction: current }) =>
          previous + current,
        0
      );
  }

  const getModuleName = (bauModule) => {
    if (bauModule.link === "grid-decarbonization") {
      return "Decarb of Grid Electricity";
    } else if (bauModule.link === "heating-fuels-decarbonization") {
      return "Decarb of Heating Fuels";
    } else if (bauModule.link === "transport-decarbonization") {
      return "Decarb of Transport";
    } else if (bauModule.link === "miscellaneous-decarbonization") {
      return "Other External Measures";
    } else {
      return bauModule.title;
    }
  };

  // Group carbon intensity energy by year.
  let energyByYear = groupBy(
    [...carbonIntensityEnergy, ...carbonIntensity],
    (item) => item.yearEnded
  );
  let energyYears = Object.keys(energyByYear).sort((a, b) => a - b);

  return (
    <Row id={userCategory} className="OrganizationDetail">
      <DataLayer />
      <Col xs={12} md={3} className="border-end py-3 scroller">
        <div className="my-3">
          <h1 id="organizationName">{organization.name}</h1>
          <p>
            {organization.description && (
              <span>
                {organization.description} <br />
              </span>
            )}
            {organizationPortfolios.map((portfolio) => {
              return account.member.role === "member" ? (
                <Badge
                  className="bg-light-green text-nowrap text-primary me-1"
                  key={portfolio.id}
                >
                  {portfolio.name}
                </Badge>
              ) : (
                <Badge
                  className="bg-light-green text-nowrap text-primary me-1"
                  key={portfolio.id}
                  as={Link}
                  to={`/portfolios/${portfolio.id}`}
                >
                  {portfolio.name}
                </Badge>
              );
            })}
          </p>
          <p className="mb-0">
            <small>
              Last updated at{" "}
              {new Date(organization.updatedAt).toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
              })}
            </small>
          </p>
          {account.member.role !== "member" && (
            <>
              <OrganizationUpdate
                organization={organization}
                onOrganizationUpdated={onOrganizationUpdated}
              />{" "}
              <OrganizationDelete
                organization={organization}
                onOrganizationDeleted={() => navigate(`/`)}
              />
              <Button
                variant="outline-info"
                id="nzifBtn"
                onClick={() =>
                  navigate(`/organizations/${organization.id}/nzif`)
                }
                size="sm"
                className="ms-1 py-0"
              >
                NZIF
              </Button>
              <Button
                variant="outline-primary"
                id="profileBtn"
                onClick={() =>
                  navigate(`/organizations/${organization.id}/profile`)
                }
                size="sm"
                className="ms-1 py-0"
              >
                Profile
              </Button>
            </>
          )}
        </div>
        <hr />
        <Nav className="flex-column" variant="pills">
          <Nav.Item>
            <Nav.Link
              as={NavLink}
              end={true}
              to={`/organizations/${organization.id}`}
            >
              Overview
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Accordion flush defaultActiveKey={`${toggleGHGAccordion()}`}>
          <Accordion.Item eventKey="1" className="border-0">
            <Accordion.Header as={"span"} id="ghg-inventory-button">
              GHG Inventory
            </Accordion.Header>
            <Accordion.Body className="p-2">
              <Nav className="flex-column" variant="pills">
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to={`/organizations/${organization.id}/top-down`}
                    id="top-down-estimation-button"
                  >
                    Top-Down Estimation
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to={`/organizations/${organization.id}/bottom-up`}
                    id="bottom-up-ghg-inventory-button"
                  >
                    Bottom-Up GHG Inventory
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to={`/organizations/${organization.id}/carbon-intensity`}
                    id="carbon-intensity-button"
                  >
                    Carbon Intensity
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {(account.member.role === "admin" ||
          account.member.role === "owner") && (
          <Accordion flush defaultActiveKey={`${toggleForecastingAccordion()}`}>
            <Accordion.Item eventKey="1" className="border-0">
              <Accordion.Header as={"span"} id="forecasting-button">
                Projections
              </Accordion.Header>
              <Accordion.Body className="p-2">
                <div className="mb-3">
                  <p className="mb-2 px-2">
                    <strong>Emissions</strong>
                    <span className="float-end">
                      <strong>
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                      </strong>
                    </span>
                  </p>
                  <p className="py-0 my-2 px-2">
                    Scope 1{" "}
                    <span className="float-end">
                      {formatNumber(emissions?.emissionsScopeOne)}
                    </span>
                  </p>
                  <p className="py-0 my-2 px-2">
                    Scope 2{" "}
                    <span className="float-end">
                      {formatNumber(emissions?.emissionsScopeTwo)}
                    </span>
                  </p>
                  <p className="py-0 my-2 px-2">
                    Scope 3{" "}
                    <span className="float-end">
                      {formatNumber(emissions?.emissionsScopeThree)}
                    </span>
                  </p>
                  <p className="py-0 my-2 px-2">
                    Current Year{" "}
                    <span className="float-end">
                      {emissions?.baselineYear
                        ? emissions?.baselineYear
                        : "N/A"}
                    </span>
                  </p>
                  <p className="px-2">
                    <ManageEmissions
                      emissions={emissions}
                      sites={bottomUpSummary}
                      onManageEmissionsUpdated={(data) => setEmissions(data)}
                      setIsForecastingUpdate={setIsForecastingUpdate}
                      targetEmissions={targetEmissions}
                    />
                  </p>
                </div>
                <hr className="mb-0" />
                <Nav className="flex-column" variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      as={NavLink}
                      to={`/organizations/${organization.id}/projections`}
                      id="forecasting-overview-button"
                    >
                      Overview
                    </Nav.Link>
                  </Nav.Item>
                  {emissions?.forecastingConfig ? (
                    JSON.parse(emissions?.forecastingConfig)
                      .filter((bauModule) => bauModule.title !== "SBTi")
                      .map((bauModule, index) => (
                        <Nav.Item key={bauModule.title + index}>
                          <Nav.Link
                            as={NavLink}
                            to={`/organizations/${organization.id}/${bauModule.link}`}
                            id={`${getModuleName(bauModule)?.replace(
                              /\s+/g,
                              "-"
                            )}-button`}
                          >
                            {getModuleName(bauModule)}
                          </Nav.Link>
                        </Nav.Item>
                      ))
                  ) : (
                    <Nav.Item>
                      <Nav.Link
                        as={NavLink}
                        to={`/organizations/${organization.id}/economic-growth`}
                      >
                        Growth in Production/Turnover
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {(account.member.role === "admin" ||
          account.member.role === "owner") && (
          <Accordion
            flush
            defaultActiveKey={`${toggleTargetSettingsAccordion()}`}
          >
            <Accordion.Item eventKey="1" className="border-0">
              <Accordion.Header as={"span"}>Target Setting</Accordion.Header>
              <Accordion.Body className="p-2">
                <div className="mb-3">
                  <p className="mb-2 px-2">
                    <strong>Emissions</strong>
                    <span className="float-end">
                      <strong>
                        <small>
                          (tCO<sub>2</sub>e)
                        </small>
                      </strong>
                    </span>
                  </p>
                  <p className="py-0 my-2 px-2">
                    Scope 1{" "}
                    <span className="float-end">
                      {formatNumber(targetEmissions?.emissionsScopeOne)}
                    </span>
                  </p>
                  <p className="py-0 my-2 px-2">
                    Scope 2{" "}
                    <span className="float-end">
                      {formatNumber(targetEmissions?.emissionsScopeTwo)}
                    </span>
                  </p>
                  <p className="py-0 my-2 px-2">
                    Scope 3{" "}
                    <span className="float-end">
                      {formatNumber(targetEmissions?.emissionsScopeThree)}
                    </span>
                  </p>
                  <p className="py-0 my-2 px-2">
                    Base Year{" "}
                    <span className="float-end">
                      {targetEmissions?.baselineYear
                        ? targetEmissions?.baselineYear
                        : "N/A"}
                    </span>
                  </p>
                  <p className="px-2">
                    <ManageTargetEmissions
                      emissions={targetEmissions}
                      sites={bottomUpSummary}
                      onManageEmissionsUpdated={(data) =>
                        setTargetEmissions(data)
                      }
                      setIsTargetUpdate={setIsTargetUpdate}
                    />
                  </p>
                </div>
                <hr className="mb-0" />
                <Nav className="flex-column" variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      as={NavLink}
                      to={`/organizations/${organization.id}/sbti-overview`}
                    >
                      Overview
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={NavLink}
                      to={`/organizations/${organization.id}/science-based-target-initiative`}
                    >
                      Science Based Target initiative (SBTi)
                    </Nav.Link>
                  </Nav.Item>
                  {targetEmissions?.sbtiConfig
                    ? JSON.parse(targetEmissions?.sbtiConfig)
                        .filter((target) => target.type !== "sbti")
                        .map((target) => (
                          <Nav.Item key={target.type}>
                            <Nav.Link
                              as={NavLink}
                              to={`/organizations/${
                                organization.id
                              }/user-defined-target=${encodeURIComponent(
                                target.type
                              )}`}
                            >
                              {target.type}
                            </Nav.Link>
                          </Nav.Item>
                        ))
                    : null}
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {(account.member.role === "admin" ||
          account.member.role === "owner") && (
          <Accordion flush defaultActiveKey={`${toggleAbatementAccordion()}`}>
            <Accordion.Item eventKey="1" className="border-0">
              <Accordion.Header as={"span"}>
                Abatement Planning
              </Accordion.Header>
              <Accordion.Body className="p-2">
                <Nav className="flex-column" variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      as={NavLink}
                      to={`/organizations/${organization.id}/abatement-actions`}
                    >
                      Opportunity Strategies{" "}
                      <small>
                        <Badge className="ms-1" bg="danger">
                          BETA
                        </Badge>
                      </small>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Nav className="flex-column" variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      as={NavLink}
                      to={`/organizations/${organization.id}/reduction-potential`}
                    >
                      Reduction Potential{" "}
                      <small>
                        <Badge className="ms-1" bg="danger">
                          BETA
                        </Badge>
                      </small>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Nav className="flex-column" variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      as={NavLink}
                      to={`/organizations/${organization.id}/abatement`}
                    >
                      Projects, Actions, Policies
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Nav className="flex-column" variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      as={NavLink}
                      to={`/organizations/${organization.id}/scenario-analysis`}
                    >
                      Scenario Analysis{" "}
                      <small>
                        <Badge className="ms-1" bg="danger">
                          BETA
                        </Badge>
                      </small>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {(account.member.role === "admin" ||
          account.member.role === "owner") && (
          <Nav className="flex-column" variant="pills">
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to={`/organizations/${organization.id}/roadmap`}
              >
                Roadmap
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}
      </Col>
      <Col xs={12} md={9} className="py-3">
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <div className="my-3">
                  <BreadCrumbs
                    breadcrumbs={[
                      { name: "Dashboard", link: "/" },
                      {
                        name: organization.name,
                        link: `/organizations/${organization.id}`,
                        active: true,
                      },
                    ]}
                  />
                </div>
                <div>
                  <span className="float-end d-flex">
                    <Dropdown
                      show={showSummaryDropdown}
                      onClose={() => setShowSummaryDropdown(false)}
                    >
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                        size="sm"
                        className={`bg-primary text-white border-0 ${
                          buttonLoadingState && "disabled"
                        }`}
                        onClick={() =>
                          setShowSummaryDropdown(!showSummaryDropdown)
                        }
                      >
                        {buttonLoadingState
                          ? "Loading..."
                          : "Download Summary Report"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <OrganizationExport
                          organization={organization}
                          sites={sites}
                          topDownSummary={organizationInfo}
                          bottomUpSummary={bottomUpSummary}
                          carbonIntensitySummary={carbonIntensity}
                          topDownChart={topDownChart}
                          bottomUpChart={bottomUpChart}
                          emissionByScopeChart={emissionByScopeChart}
                          carbonIntensityChart={carbonIntensityChart}
                          abatementChart={abatementChart}
                          forecastingChart={forecastingChart}
                          sbtiOverviewChart={sbtiOverviewChart}
                          roadmapChart={roadmapChart}
                          totalRoadmapEmissions={totalRoadmapEmissions}
                          totalCarbonSaving={totalCarbonSaving}
                          projects={projects}
                          group={abatementGroup}
                          status={abatementStatus}
                          site={abatementSite}
                          abatementPotential={getAbatementPotential()}
                          targetEmissions={targetEmissions}
                          scopeOne={targetEmissions?.emissionsScopeOne}
                          scopeTwo={targetEmissions?.emissionsScopeTwo}
                          scopeTwoPreference={scopeTwoPreference}
                          setShowSummaryDropdown={setShowSummaryDropdown}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                    {(account.member.role === "admin" ||
                      account.member.role === "owner") && (
                      <OverviewSetting
                        organization={organization}
                        fetchOrganizationDetails={fetchOrganizationDetails}
                        onOrganizationUpdated={onOrganizationUpdated}
                      />
                    )}
                  </span>
                  <h2>Overview</h2>
                  {/* <Row style={{ height: "800px" }}>
                    <OrganizationExport
                      organization={organization}
                      sites={sites}
                      topDownSummary={organizationInfo}
                      bottomUpSummary={bottomUpSummary}
                      topDownChart={topDownChart}
                      bottomUpChart={bottomUpChart}
                    />
                  </Row> */}
                </div>

                <Row className="my-3">
                  {organization?.featureVisibility?.topDown && (
                    <Col xs={12}>
                      <div className="border p-3 mb-3">
                        {!!organizationInfo.length && (
                          <div className="mx-1 px-3 bg-danger text-danger bg-opacity-10 float-end">
                            <span className="fs-1">
                              {getFormattedEmission(
                                organizationInfo[organizationInfo.length - 1]
                                  .organizationEmissions
                              )}{" "}
                            </span>
                            <small>
                              tCO<sub>2</sub>e
                            </small>{" "}
                            {organizationInfo[
                              organizationInfo.length - 1
                            ].yearEnded.substring(0, 4) || ""}{" "}
                            Emissions
                          </div>
                        )}

                        <h2 className="mb-3">Top-Down Estimation</h2>
                        <p>
                          Uses ‘top-down’ industry average factors to provide an
                          indicative estimate of emissions intensity per unit of
                          revenue for various industries.
                        </p>
                        <hr />
                        {!organizationInfo.length ? (
                          <Alert variant="info">
                            There's currently no data to show. Report top down
                            emissions to view the distribution chart.
                          </Alert>
                        ) : (
                          <EmissionsByYear
                            organizationInfo={organizationInfo}
                            onChartRendered={(chart) => setTopDownChart(chart)}
                          />
                        )}
                        <p className="text-end mb-0 mt-5">
                          <Button
                            variant="primary"
                            size="sm"
                            className="px-3"
                            onClick={() =>
                              navigate(
                                `/organizations/${organization.id}/top-down`
                              )
                            }
                          >
                            Manage Top-Down Estimation
                          </Button>
                        </p>
                      </div>
                    </Col>
                  )}
                  {organization?.featureVisibility?.bottomUp && (
                    <Col xs={12}>
                      <div className="border p-3 mb-3">
                        <div className="d-inline-flex float-end">
                          <div className="mx-1 px-3 bg-info text-primary bg-opacity-10">
                            <span className="fs-1 me-2">{sites.length}</span>
                            Sites
                          </div>
                          <div className="mx-1 px-3 bg-danger text-danger bg-opacity-10">
                            {(() => {
                              let latestEmisssions = bottomUpSummary.filter(
                                (item) =>
                                  item.yearEnded ===
                                  Math.max(
                                    ...bottomUpSummary.map(
                                      (item) => item.yearEnded
                                    )
                                  )
                              );

                              return (
                                <>
                                  <span className="fs-1">
                                    {getFormattedEmission(
                                      latestEmisssions.reduce(
                                        (sum, item) => item.emissions + sum,
                                        0
                                      )
                                    )}
                                  </span>{" "}
                                  <small>
                                    tCO<sub>2</sub>e
                                  </small>{" "}
                                  {latestEmisssions[0]?.yearEnded || ""}{" "}
                                  Emissions
                                </>
                              );
                            })()}
                          </div>
                          <div className="d-inline-flex align-items-center mx-1 align-center">
                            <ScopeTwoPreferenceToggler
                              scopeTwoPreference={scopeTwoPreference}
                              setScopeTwoPreference={setScopeTwoPreference}
                            />
                          </div>
                        </div>
                        <h2 className="mb-3">Bottom-Up GHG Inventory</h2>
                        <p>
                          Uses site specific inventory of scope 1, scope 2 and
                          scope 3 activities.
                        </p>
                        {!bottomUpSummary.length ? (
                          <>
                            <hr />
                            <Alert variant="info">
                              There's currently no data to show. Report bottom
                              up emissions to view the summary chart.
                            </Alert>
                          </>
                        ) : (
                          <>
                            <Col xs={12} className="border p-3">
                              <h3 className="mb-3">Emissions By Scope</h3>
                              <hr />
                              <EmissionsBreakDown
                                sitesSummary={bottomUpSummary}
                                onChartRendered={(chart) =>
                                  setEmissionByScopeChart(chart)
                                }
                              />
                            </Col>
                            <Col xs={12} className="border p-3 mt-2">
                              <h3 className="mb-3">Emissions By Site</h3>
                              <hr />
                              <EmissionsBySite
                                sitesSummary={bottomUpSummary}
                                sites={sites}
                                onChartRendered={(chart) =>
                                  setBottomUpChart(chart)
                                }
                              />
                            </Col>
                          </>
                        )}

                        <p className="text-end mb-0 mt-3">
                          <Button
                            variant="primary"
                            size="sm"
                            className="px-3"
                            onClick={() =>
                              navigate(
                                `/organizations/${organization.id}/bottom-up`
                              )
                            }
                          >
                            Manage Bottom-Up GHG Inventory
                          </Button>
                        </p>
                      </div>
                    </Col>
                  )}
                  {organization?.featureVisibility?.pcaf && (
                    <Col xs={12}>
                      <div className="border p-3 mb-3">
                        <OrganizationQodScoreSummary
                          organization={organization}
                          pcafSummaryOrg={orgPcafSummary}
                          summaryOrg={orgSummary}
                        />
                      </div>
                    </Col>
                  )}
                  {organization?.featureVisibility?.carbonIntensity && (
                    <Col xs={12} md={12}>
                      <div className="border p-3 mb-3">
                        <h3>Carbon Intensity</h3>
                        <hr />
                        {energyYears.length ? (
                          <CarbonIntensityByYear
                            carbonIntensity={carbonIntensity}
                            carbonIntensityEnergy={carbonIntensityEnergy}
                            onChartRendered={(chart) =>
                              setCarbonIntensityChart(chart)
                            }
                          />
                        ) : (
                          <Alert variant="info">
                            There is currently nothing to show here.
                          </Alert>
                        )}

                        <Button
                          variant="primary"
                          size="sm"
                          className="align-self-center px-3 mt-3"
                          as={Link}
                          to={`/organizations/${organization.id}/carbon-intensity`}
                        >
                          Manage Carbon Intensity
                        </Button>
                      </div>
                    </Col>
                  )}
                  {organization?.featureVisibility?.forecasting && (
                    <Col md={12}>
                      <ForecastingOverview
                        isShow={false}
                        scopeOne={emissions?.emissionsScopeOne}
                        scopeTwo={emissions?.emissionsScopeTwo}
                        scopeThree={emissions?.emissionsScopeThree}
                        targetScopeOne={targetEmissions?.emissionsScopeOne}
                        targetScopeTwo={targetEmissions?.emissionsScopeTwo}
                        targetScopeThree={targetEmissions?.emissionsScopeThree}
                        baselineYear={emissions?.baselineYear}
                        endYear={emissions?.endYear}
                        targetBaselineYear={targetEmissions?.baselineYear}
                        organization={organization}
                        breadcrumbs={breadcrumbs}
                        forecastingConfig={emissions?.forecastingConfig || "[]"}
                        sbtiConfig={targetEmissions?.sbtiConfig || "[]"}
                        onChartRendered={(chart) => setForecastingChart(chart)}
                        isForecastingUpdate={isForecastingUpdate}
                        isTargetUpdate={isTargetUpdate}
                        setIsForecastingUpdate={setIsForecastingUpdate}
                        setIsTargetUpdate={setIsTargetUpdate}
                        orgEconomicGrowth={economicGrowth}
                        orgEconomicGrowthEmissionPercentage={
                          economicGrowthEmissionPercentage
                        }
                        orgGridDecarbonization={gridDecarbonization}
                        orgGridDecarbonizationEmissionPercentage={
                          gridDecarbonizationEmissionPercentage
                        }
                        orgGridDecarbonizationEmissionPercentageThree={
                          gridDecarbonizationEmissionPercentageThree
                        }
                        orgTargetEmissions={targetEmissions}
                        orgDecarbOfHeatingFuels={decarbOfHeatingFuels}
                        orgHeatingFuelDecarbEmissionPercentage={
                          heatingFuelDecarbEmissionPercentage
                        }
                        orgHeatingFuelDecarbEmissionPercentageThree={
                          heatingFuelDecarbEmissionPercentageThree
                        }
                        orgDecarbOfTransport={decarbOfTransport}
                        orgTransportDecarbEmissionPercentage={
                          transportDecarbEmissionPercentage
                        }
                        orgTransportDecarbEmissionPercentageThree={
                          transportDecarbEmissionPercentageThree
                        }
                        orgBauDecarb={bauDecarb}
                      />
                    </Col>
                  )}
                  {organization?.featureVisibility?.sbti && (
                    <Col md={12}>
                      <SBTiOverview
                        isShow={false}
                        scopeOne={targetEmissions?.emissionsScopeOne}
                        scopeTwo={targetEmissions?.emissionsScopeTwo}
                        scopeThree={targetEmissions?.emissionsScopeThree}
                        baselineYear={targetEmissions?.baselineYear}
                        organization={organization}
                        breadcrumbs={breadcrumbs}
                        sbtiConfig={targetEmissions?.sbtiConfig || "[]"}
                        isTargetUpdate={isTargetUpdate}
                        setIsTargetUpdate={setIsTargetUpdate}
                        onChartRendered={(chart) => setSbtiOverviewChart(chart)}
                        orgTargetEmissions={targetEmissions}
                      />
                    </Col>
                  )}
                  {organization?.featureVisibility?.abatement && (
                    <Col xs={12} md={12}>
                      <div className="border p-3 mb-3">
                        {!projects.length ? (
                          <Alert variant="info">
                            There's currently no data to show. Add abatement
                            projects to view the MACC chart.
                          </Alert>
                        ) : (
                          <Abatement
                            organization={organization}
                            sites={sites}
                            onAbatementUpdated={fetchOrganizationDetails}
                            status={abatementStatus}
                            setStatus={setAbatementStatus}
                            group={abatementGroup}
                            setGroup={setAbatementGroup}
                            site={abatementSite}
                            setSite={setAbatementSite}
                            scenario={abatementScenario}
                            setScenario={setAbatementScenario}
                            location={abatementLocation}
                            setLocation={setAbatementLocation}
                            onChartRendered={(chart) =>
                              setAbatementChart(chart)
                            }
                            isShow={false}
                            isForecastingUpdate={isForecastingUpdate}
                            setIsForecastingUpdate={setIsForecastingUpdate}
                            orgScenarios={scenarios}
                            orgLocations={locations}
                            orgGroups={groups}
                            orgProjects={projects}
                            orgCustomFields={customFields}
                            orgEmissions={emissions}
                            orgSiteLocations={siteLocations}
                          />
                        )}
                        <p className="text-end my-0">
                          <Button
                            variant="primary"
                            size="sm"
                            className="align-self-center px-3"
                            as={Link}
                            to={`/organizations/${organization.id}/abatement`}
                          >
                            Manage Abatement Planning
                          </Button>
                        </p>
                      </div>{" "}
                    </Col>
                  )}
                  {organization?.featureVisibility?.roadmap && (
                    <Col md={12}>
                      <Projections
                        isShow={false}
                        scopeOne={emissions?.emissionsScopeOne}
                        scopeTwo={emissions?.emissionsScopeTwo}
                        scopeThree={emissions?.emissionsScopeThree}
                        targetScopeOne={targetEmissions?.emissionsScopeOne}
                        targetScopeTwo={targetEmissions?.emissionsScopeTwo}
                        targetScopeThree={targetEmissions?.emissionsScopeThree}
                        baselineYear={emissions?.baselineYear}
                        endYear={emissions?.endYear}
                        targetBaselineYear={targetEmissions?.baselineYear}
                        organization={organization}
                        breadcrumbs={breadcrumbs}
                        sites={sites}
                        onChartRendered={(chart) => setRoadmapChart(chart)}
                        setTotalCarbonSaving={setTotalCarbonSaving}
                        setTotalRoadmapEmissions={setTotalRoadmapEmissions}
                        forecastingConfig={emissions?.forecastingConfig || "[]"}
                        sbtiConfig={targetEmissions?.sbtiConfig || "[]"}
                        isForecastingUpdate={isForecastingUpdate}
                        isTargetUpdate={isTargetUpdate}
                        setIsForecastingUpdate={setIsForecastingUpdate}
                        setIsTargetUpdate={setIsTargetUpdate}
                        orgProjects={projects}
                        orgEconomicGrowth={economicGrowth}
                        orgEconomicGrowthEmissionPercentage={
                          economicGrowthEmissionPercentage
                        }
                        orgGridDecarbonization={gridDecarbonization}
                        orgGridDecarbonizationEmissionPercentage={
                          gridDecarbonizationEmissionPercentage
                        }
                        orgGridDecarbonizationEmissionPercentageThree={
                          gridDecarbonizationEmissionPercentageThree
                        }
                        orgTargetEmissions={targetEmissions}
                        orgDecarbOfHeatingFuels={decarbOfHeatingFuels}
                        orgHeatingFuelDecarbEmissionPercentage={
                          heatingFuelDecarbEmissionPercentage
                        }
                        orgHeatingFuelDecarbEmissionPercentageThree={
                          heatingFuelDecarbEmissionPercentageThree
                        }
                        orgDecarbOfTransport={decarbOfTransport}
                        orgTransportDecarbEmissionPercentage={
                          transportDecarbEmissionPercentage
                        }
                        orgTransportDecarbEmissionPercentageThree={
                          transportDecarbEmissionPercentageThree
                        }
                        orgGroups={groups}
                        orgScenarios={scenarios}
                        orgLocations={locations}
                        orgBauDecarb={bauDecarb}
                      />
                    </Col>
                  )}
                  {!organization?.featureVisibility?.topDown &&
                    !organization?.featureVisibility?.bottomUp &&
                    !organization?.featureVisibility?.pcaf &&
                    !organization?.featureVisibility?.carbonIntensity &&
                    !organization?.featureVisibility?.forecasting &&
                    !organization?.featureVisibility?.sbti &&
                    !organization?.featureVisibility?.abatement &&
                    !organization?.featureVisibility?.roadmap && (
                      <Col md={12}>
                        <Alert variant="info">
                          There's currently no data to show.
                        </Alert>
                      </Col>
                    )}
                </Row>
              </div>
            }
          />
          <Route
            path="/top-down"
            element={
              <TopDownAnalysis
                organization={organization}
                organizationInfo={organizationInfo}
                onOrganizationInfoUpdated={(orgInfo) => {
                  let indexOfItem = organizationInfo.findIndex(
                    (item) => item.yearEnded === orgInfo.yearEnded
                  );

                  if (indexOfItem >= 0) organizationInfo[indexOfItem] = orgInfo;
                  else organizationInfo.push(orgInfo);

                  organizationInfo.sort((a, b) =>
                    a.yearEnded.localeCompare(b.yearEnded)
                  );
                  setOrganizationInfo([...organizationInfo]);
                }}
                onOrganizationInfoDeleted={(orgInfo) => {
                  setOrganizationInfo([
                    ...organizationInfo.filter(
                      (item) => item.id !== orgInfo.id
                    ),
                  ]);
                }}
              />
            }
          />
          <Route
            path="/bottom-up"
            element={
              <BottomUpAnalysis
                organization={organization}
                sites={sites}
                sitesSummary={bottomUpSummary}
                onSiteCreated={fetchOrganizationDetails}
                activityTypes={activityTypes}
                scopeTwoPreference={scopeTwoPreference}
                setScopeTwoPreference={setScopeTwoPreference}
              />
            }
          />
          <Route
            path="/abatement"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <Abatement
                  organization={organization}
                  sites={sites}
                  onAbatementUpdated={fetchOrganizationDetails}
                  status={abatementStatus}
                  setStatus={setAbatementStatus}
                  group={abatementGroup}
                  setGroup={setAbatementGroup}
                  site={abatementSite}
                  setSite={setAbatementSite}
                  scenario={abatementScenario}
                  setScenario={setAbatementScenario}
                  location={abatementLocation}
                  setLocation={setAbatementLocation}
                  isShow={true}
                  isForecastingUpdate={isForecastingUpdate}
                  setIsForecastingUpdate={setIsForecastingUpdate}
                  orgScenarios={scenarios}
                  orgLocations={locations}
                  orgGroups={groups}
                  orgProjects={projects}
                  orgCustomFields={customFields}
                  orgEmissions={emissions}
                  orgSiteLocations={siteLocations}
                />
              </AuthRoute>
            }
          />
          <Route
            path="/abatement-actions"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <AbatementActions organization={organization} />
              </AuthRoute>
            }
          />
          <Route
            path="/reduction-potential"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <ReductionPotential
                  emissions={emissions}
                  organization={organization}
                  baselineYear={emissions?.baselineYear}
                  forecastingConfig={emissions?.forecastingConfig || "[]"}
                  sbtiConfig={targetEmissions?.sbtiConfig}
                  targetBaselineYear={targetEmissions?.baselineYear}
                  orgTargetEmissions={targetEmissions}
                  orgBauDecarb={bauDecarb}
                  isForecastingUpdate={isForecastingUpdate}
                  isTargetUpdate={isTargetUpdate}
                  setIsForecastingUpdate={setIsForecastingUpdate}
                  setIsTargetUpdate={setIsTargetUpdate}
                />
              </AuthRoute>
            }
          />
          <Route
            path="/scenario-analysis"
            element={<ScenarioPlanning organization={organization} />}
          />
          <Route
            path="/carbon-intensity"
            element={
              <CarbonIntensity
                organization={organization}
                organizationInfo={organizationInfo}
                carbonIntensity={carbonIntensity}
                carbonIntensityEnergy={carbonIntensityEnergy}
                bottomUpSummary={bottomUpSummary}
                onCarbonIntensityUpdated={(ci, deleted) => {
                  let indexOfItem = carbonIntensity.findIndex(
                    (item) => item.yearEnded === ci.yearEnded
                  );

                  if (indexOfItem >= 0 && deleted)
                    carbonIntensity.splice(indexOfItem, 1);
                  else if (indexOfItem >= 0) carbonIntensity[indexOfItem] = ci;
                  else carbonIntensity.push(ci);

                  carbonIntensity.sort((a, b) =>
                    a.yearEnded < b.yearEnded ? -1 : 0
                  );

                  setCarbonIntensity([...carbonIntensity]);
                }}
                onCarbonIntensityEnergyUpdated={(ci, deleted) => {
                  let indexOfItem = carbonIntensityEnergy.findIndex(
                    (item) =>
                      item.yearEnded === ci.yearEnded &&
                      item.carbonIntensityFactorId ===
                        ci.carbonIntensityFactorId
                  );

                  if (indexOfItem >= 0 && deleted)
                    carbonIntensityEnergy.splice(indexOfItem, 1);
                  else if (indexOfItem >= 0)
                    carbonIntensityEnergy[indexOfItem] = ci;
                  else carbonIntensityEnergy.push(ci);

                  carbonIntensityEnergy.sort((a, b) =>
                    a.yearEnded < b.yearEnded ? -1 : 0
                  );

                  setCarbonIntensityEnergy([...carbonIntensityEnergy]);
                }}
              />
            }
          />
          <Route
            path="/activities"
            element={
              <OrganizationActivities
                breadcrumbs={breadcrumbs}
                organization={organization}
                sites={sites}
                selectedScopeTwoPreference={scopeTwoPreference}
              />
            }
          />
          <Route
            path="/projections"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <ForecastingOverview
                  isShow={true}
                  scopeOne={emissions?.emissionsScopeOne}
                  scopeTwo={emissions?.emissionsScopeTwo}
                  scopeThree={emissions?.emissionsScopeThree}
                  targetScopeOne={targetEmissions?.emissionsScopeOne}
                  targetScopeTwo={targetEmissions?.emissionsScopeTwo}
                  targetScopeThree={targetEmissions?.emissionsScopeThree}
                  baselineYear={emissions?.baselineYear}
                  endYear={emissions?.endYear}
                  targetBaselineYear={targetEmissions?.baselineYear}
                  organization={organization}
                  breadcrumbs={breadcrumbs}
                  forecastingConfig={emissions?.forecastingConfig || "[]"}
                  sbtiConfig={targetEmissions?.sbtiConfig || "[]"}
                  isForecastingUpdate={isForecastingUpdate}
                  isTargetUpdate={isTargetUpdate}
                  setIsForecastingUpdate={setIsForecastingUpdate}
                  setIsTargetUpdate={setIsTargetUpdate}
                  orgEconomicGrowth={economicGrowth}
                  orgEconomicGrowthEmissionPercentage={
                    economicGrowthEmissionPercentage
                  }
                  orgGridDecarbonization={gridDecarbonization}
                  orgGridDecarbonizationEmissionPercentage={
                    gridDecarbonizationEmissionPercentage
                  }
                  orgGridDecarbonizationEmissionPercentageThree={
                    gridDecarbonizationEmissionPercentageThree
                  }
                  orgTargetEmissions={targetEmissions}
                  orgDecarbOfHeatingFuels={decarbOfHeatingFuels}
                  orgHeatingFuelDecarbEmissionPercentage={
                    heatingFuelDecarbEmissionPercentage
                  }
                  orgHeatingFuelDecarbEmissionPercentageThree={
                    heatingFuelDecarbEmissionPercentageThree
                  }
                  orgDecarbOfTransport={decarbOfTransport}
                  orgTransportDecarbEmissionPercentage={
                    transportDecarbEmissionPercentage
                  }
                  orgTransportDecarbEmissionPercentageThree={
                    transportDecarbEmissionPercentageThree
                  }
                  orgBauDecarb={bauDecarb}
                />
              </AuthRoute>
            }
          />
          <Route
            path="/roadmap"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <Projections
                  isShow={true}
                  scopeOne={emissions?.emissionsScopeOne}
                  scopeTwo={emissions?.emissionsScopeTwo}
                  scopeThree={emissions?.emissionsScopeThree}
                  targetScopeOne={targetEmissions?.emissionsScopeOne}
                  targetScopeTwo={targetEmissions?.emissionsScopeTwo}
                  targetScopeThree={targetEmissions?.emissionsScopeThree}
                  baselineYear={emissions?.baselineYear}
                  endYear={emissions?.endYear}
                  targetBaselineYear={targetEmissions?.baselineYear}
                  organization={organization}
                  breadcrumbs={breadcrumbs}
                  sites={sites}
                  forecastingConfig={emissions?.forecastingConfig || "[]"}
                  sbtiConfig={targetEmissions?.sbtiConfig || "[]"}
                  isForecastingUpdate={isForecastingUpdate}
                  isTargetUpdate={isTargetUpdate}
                  setIsForecastingUpdate={setIsForecastingUpdate}
                  setIsTargetUpdate={setIsTargetUpdate}
                  orgProjects={projects}
                  orgEconomicGrowth={economicGrowth}
                  orgEconomicGrowthEmissionPercentage={
                    economicGrowthEmissionPercentage
                  }
                  orgGridDecarbonization={gridDecarbonization}
                  orgGridDecarbonizationEmissionPercentage={
                    gridDecarbonizationEmissionPercentage
                  }
                  orgGridDecarbonizationEmissionPercentageThree={
                    gridDecarbonizationEmissionPercentageThree
                  }
                  orgTargetEmissions={targetEmissions}
                  orgDecarbOfHeatingFuels={decarbOfHeatingFuels}
                  orgHeatingFuelDecarbEmissionPercentage={
                    heatingFuelDecarbEmissionPercentage
                  }
                  orgHeatingFuelDecarbEmissionPercentageThree={
                    heatingFuelDecarbEmissionPercentageThree
                  }
                  orgDecarbOfTransport={decarbOfTransport}
                  orgTransportDecarbEmissionPercentage={
                    transportDecarbEmissionPercentage
                  }
                  orgTransportDecarbEmissionPercentageThree={
                    transportDecarbEmissionPercentageThree
                  }
                  orgGroups={groups}
                  orgScenarios={scenarios}
                  orgLocations={locations}
                  orgBauDecarb={bauDecarb}
                />
              </AuthRoute>
            }
          />
          <Route
            path="/economic-growth"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <EconomicGrowth
                  scopeOne={emissions?.emissionsScopeOne}
                  scopeTwo={emissions?.emissionsScopeTwo}
                  scopeThree={emissions?.emissionsScopeThree}
                  baselineYear={emissions?.baselineYear}
                  organization={organization}
                  breadcrumbs={breadcrumbs}
                  isForecastingUpdate={isForecastingUpdate}
                />
              </AuthRoute>
            }
          />
          <Route
            path="/grid-decarbonization"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <GridDecarbonization
                  scopeOne={emissions?.emissionsScopeOne}
                  scopeTwo={emissions?.emissionsScopeTwo}
                  scopeThree={emissions?.emissionsScopeThree}
                  baselineYear={emissions?.baselineYear}
                  organization={organization}
                  breadcrumbs={breadcrumbs}
                  isForecastingUpdate={isForecastingUpdate}
                  setIsForecastingUpdate={setIsForecastingUpdate}
                  orgEconomicGrowth={economicGrowth}
                  orgEconomicGrowthEmissionPercentage={
                    economicGrowthEmissionPercentage
                  }
                  orgGridDecarbonization={gridDecarbonizationData}
                  orgGridDecarbonizationEmissionPercentage={
                    gridDecarbonizationEmissionPercentage
                  }
                  orgGridDecarbonizationEmissionPercentageThree={
                    gridDecarbonizationEmissionPercentageThree
                  }
                />
              </AuthRoute>
            }
          />
          <Route
            path="/science-based-target-initiative"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <SBTinitiative
                  scopeOne={targetEmissions?.emissionsScopeOne}
                  scopeTwo={targetEmissions?.emissionsScopeTwo}
                  scopeThree={targetEmissions?.emissionsScopeThree}
                  baselineYear={targetEmissions?.baselineYear}
                  organization={organization}
                  breadcrumbs={breadcrumbs}
                  sbtiConfig={targetEmissions?.sbtiConfig || "[]"}
                  isTargetUpdate={isTargetUpdate}
                />
              </AuthRoute>
            }
          />
          <Route
            path="/heating-fuels-decarbonization"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <HeatingFuelDecarbonization
                  scopeOne={emissions?.emissionsScopeOne}
                  scopeTwo={emissions?.emissionsScopeTwo}
                  scopeThree={emissions?.emissionsScopeThree}
                  baselineYear={emissions?.baselineYear}
                  organization={organization}
                  breadcrumbs={breadcrumbs}
                  isForecastingUpdate={isForecastingUpdate}
                  setIsForecastingUpdate={setIsForecastingUpdate}
                  orgEconomicGrowth={economicGrowth}
                  orgEconomicGrowthEmissionPercentage={
                    economicGrowthEmissionPercentage
                  }
                  orgDecarbOfHeatingFuels={decarbOfHeatingFuelsData}
                  orgHeatingFuelDecarbEmissionPercentage={
                    heatingFuelDecarbEmissionPercentage
                  }
                  orgHeatingFuelDecarbEmissionPercentageThree={
                    heatingFuelDecarbEmissionPercentageThree
                  }
                />
              </AuthRoute>
            }
          />
          <Route
            path="/transport-decarbonization"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <TransportDecarbonization
                  scopeOne={emissions?.emissionsScopeOne}
                  scopeTwo={emissions?.emissionsScopeTwo}
                  scopeThree={emissions?.emissionsScopeThree}
                  baselineYear={emissions?.baselineYear}
                  organization={organization}
                  breadcrumbs={breadcrumbs}
                  isForecastingUpdate={isForecastingUpdate}
                  setIsForecastingUpdate={setIsForecastingUpdate}
                  orgEconomicGrowth={economicGrowth}
                  orgEconomicGrowthEmissionPercentage={
                    economicGrowthEmissionPercentage
                  }
                  orgDecarbOfTransport={decarbOfTransportData}
                  orgTransportDecarbEmissionPercentage={
                    transportDecarbEmissionPercentage
                  }
                  orgTransportDecarbEmissionPercentageThree={
                    transportDecarbEmissionPercentageThree
                  }
                />
              </AuthRoute>
            }
          />
          <Route
            path="/user-defined-target=:type"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <UserDefinedSBTinitiative
                  scopeOne={targetEmissions?.emissionsScopeOne}
                  scopeTwo={targetEmissions?.emissionsScopeTwo}
                  scopeThree={targetEmissions?.emissionsScopeThree}
                  baselineYear={targetEmissions?.baselineYear}
                  organization={organization}
                  breadcrumbs={breadcrumbs}
                  sbtiConfig={targetEmissions?.sbtiConfig || "[]"}
                  isTargetUpdate={isTargetUpdate}
                />
              </AuthRoute>
            }
          />
          <Route
            path="/sbti-overview"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <SBTiOverview
                  isShow={true}
                  scopeOne={targetEmissions?.emissionsScopeOne}
                  scopeTwo={targetEmissions?.emissionsScopeTwo}
                  scopeThree={targetEmissions?.emissionsScopeThree}
                  baselineYear={targetEmissions?.baselineYear}
                  organization={organization}
                  breadcrumbs={breadcrumbs}
                  sbtiConfig={targetEmissions?.sbtiConfig || "[]"}
                  isTargetUpdate={isTargetUpdate}
                  setIsTargetUpdate={setIsTargetUpdate}
                  orgTargetEmissions={targetEmissions}
                />
              </AuthRoute>
            }
          />
          <Route
            path="/:type"
            element={
              <AuthRoute roles={["admin", "owner"]}>
                <MiscLever
                  scopeOne={emissions?.emissionsScopeOne}
                  scopeTwo={emissions?.emissionsScopeTwo}
                  scopeThree={emissions?.emissionsScopeThree}
                  baselineYear={emissions?.baselineYear}
                  organization={organization}
                  breadcrumbs={breadcrumbs}
                  emissions={emissions}
                  forecastingConfig={emissions?.forecastingConfig || "[]"}
                  onLeverNameUpdated={(data) => setEmissions(data)}
                  isForecastingUpdate={isForecastingUpdate}
                  setIsForecastingUpdate={setIsForecastingUpdate}
                  orgEconomicGrowth={economicGrowth}
                  orgEconomicGrowthEmissionPercentage={
                    economicGrowthEmissionPercentage
                  }
                  orgMiscDecarb={miscDecarb}
                />
              </AuthRoute>
            }
          />
          <Route
            path="/nzif"
            element={
              <NzifDetail
                breadcrumbs={breadcrumbs}
                organization={organization}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <Profile
                organization={organization}
                onOrganizationUpdated={onOrganizationUpdated}
              />
            }
          />
        </Routes>
      </Col>
    </Row>
  );
};

OrganizationDetail.propTypes = {
  organization: PropTypes.object.isRequired,
  onOrganizationUpdated: PropTypes.func.isRequired,
};

export default OrganizationDetail;
