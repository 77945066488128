import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { put } from "utils/DeApi";
import Loader from "components/Loader/Loader";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const InvitationUpdate = ({ account, member, onInvitationUpdated }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => {
    setError(null);
    setIsLoading(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({});

  const updateInvitation = ({ admin }) => {
    setError(null);
    setIsLoading(true);
    const updateInvitation = put(
      `/accounts/${account.id}/invitations/${member.invitation.token}`,
      {
        isAdmin: admin ? 1 : 0,
      }
    );

    updateInvitation.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onInvitationUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button
        variant="outline-primary"
        className="py-0"
        size="sm"
        id="resendBtnIcon"
        onClick={handleShow}
      >
        <span className="material-icons-outlined md-18">refresh</span>
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <b>
              <small>
                <i>{account.name}</i>
              </small>
            </b>
            <br />
            Resend Invitation
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateInvitation(values)}
          initialValues={{ admin: false }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <p>
                  Email - <i>{member.email}</i>
                </p>

                <Form.Group controlId="admin" className="my-3">
                  <Form.Label>Invite as an admin (optional).</Form.Label>

                  <Form.Check
                    name="admin"
                    type="switch"
                    label=""
                    value={values.admin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.admin}
                    isInvalid={errors.admin && touched.admin}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.admin}
                  </Form.Control.Feedback>
                </Form.Group>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  size="sm"
                  id="cancelInvitationBtn"
                  variant="link"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  id="resendInvitationBtn"
                  size="sm"
                  disabled={!isValid}
                >
                  Resend Invitation{" "}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

InvitationUpdate.propTypes = {
  account: PropTypes.object.isRequired,
  onInvitationUpdated: PropTypes.func.isRequired,
};

export default InvitationUpdate;
