import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Modal, Button, Form } from "react-bootstrap";

import { deleteFile } from "components/Organization/Site/SiteDetail/FileUpload/FileUpload";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

const DeleteDocument = ({ attachment, organization, onDocumentDeleted }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteActivity = () => {
    setError(null);
    setIsLoading(true);

    const deleteAttachmentPromise = deleteFile(
      "organizations",
      organization.id,
      attachment.id
    );

    deleteAttachmentPromise.promise
      .then(() => {
        setError(null);
        setIsLoading(false);
        onDocumentDeleted();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      <Button
        variant="outline-danger"
        id="deleteIconBtn"
        className="py-0"
        size="sm"
        onClick={handleShow}
      >
        <span className="material-icons-outlined md-18">delete</span>
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="deleteCommentHeader">
            Are you sure you want to delete this document?
          </Modal.Title>
        </Modal.Header>
        <Formik onSubmit={deleteActivity} initialValues={{}}>
          {({ handleSubmit }) => (
            <Form>
              <Modal.Body>
                <h5 id="deleteCommentPara">
                  Documents once deleted cannot be restored
                </h5>

                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  id="cancelDocBtn"
                  size="sm"
                  variant="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  id="deleteDocBtn"
                  onClick={handleSubmit}
                  size="sm"
                  variant="danger"
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

DeleteDocument.propTypes = {
  attachment: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  onDocumentDeleted: PropTypes.func.isRequired,
};

export default DeleteDocument;
