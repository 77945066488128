import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";

import { put } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import "./SiteUpdate.scss";

const SiteUpdate = ({ site, onSiteUpdated }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [libraries] = useState(["places"]);

  const [autocomplete, setAutocomplete] = useState(null);
  const [siteLocation, setSiteLocation] = useState(site.address);

  const [siteCountry, setSiteCountry] = useState("");

  const countryName = siteCountry?.address_components?.find((component) =>
    component?.types?.includes("country")
  );

  const [cordinates, setCordinates] = useState({
    latitude: site.latitude,
    longitude: site.longitude,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries,
  });

  const schema = yup.object().shape({
    name: yup.string().min(2, "Too Short!").max(100, "Too Long!").required(),
    description: yup.string().min(2, "Too Short!").max(256, "Too Long!"),
    address: yup.string().required(),
  });

  const updateSite = ({ name, description }) => {
    setError(null);
    setIsLoading(true);

    const sitePromise = put(`/sites/${site.id}`, {
      name: name,
      description: description,
      address: siteLocation,
      latitude: cordinates.lat,
      longitude: cordinates.lng,
      country: countryName?.long_name || site.country,
    });

    sitePromise.promise
      .then((response) => {
        setError(null);
        setIsLoading(false);
        handleClose();
        onSiteUpdated();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
  };

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    const lat = autocomplete.getPlace().geometry.location.lat();
    const lng = autocomplete.getPlace().geometry.location.lng();
    const siteAddress = autocomplete.getPlace().formatted_address;
    setSiteLocation(siteAddress);
    setCordinates({ lat, lng });
    setSiteCountry(autocomplete.getPlace());
  };

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShow}
        size="sm"
        className="py-0"
        id="update-button"
      >
        Update
      </Button>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Update Site <i>{site.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => updateSite(values)}
          initialValues={{
            name: site.name,
            description: site.description,
            address: site.address,
            country: site.country,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    placeholder="Enter site's name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="description" className="my-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={values.description}
                    placeholder="Enter site's description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.description}
                    isInvalid={errors.description && touched.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
                {isLoaded && (
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <Form.Group controlId="siteLocation" className="my-3">
                      <Form.Label>Site Location</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={
                          siteLocation === "" ? values.address : siteLocation
                        }
                        placeholder="Please enter city name"
                        onChange={(e) => {
                          handleChange(e);
                          setSiteLocation("");
                        }}
                        onBlur={handleBlur}
                        isValid={siteLocation}
                        isInvalid={errors.address && touched.address}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Autocomplete>
                )}

                <Form.Group controlid="siteCountry" className="my-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    disabled
                    value={
                      !countryName ? values.country : countryName?.long_name
                    }
                    placeholder="Country name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.country}
                    isInvalid={errors.country && touched.country}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                </Form.Group>
                {error && <ErrorHandler error={error} />}
                {isLoading && <Loader />}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  size="sm"
                  disabled={!isValid}
                  id="update-site-button"
                >
                  Update Site
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

SiteUpdate.propTypes = {
  site: PropTypes.object.isRequired,
  onSiteUpdated: PropTypes.func.isRequired,
};

export default SiteUpdate;
