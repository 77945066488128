import * as echarts from "echarts";
import { useEffect, useRef, useState } from "react";
import "./BusinessAsUsualChart.scss";

import { isArray } from "lodash";
import {
  checkIfIsInBau,
  convertNegativeToZero,
  formatNumber,
} from "../../helper";

const colorsPallete = [
  "#AFEEEE",
  "#D1E8E2",
  "#D1E6FF",
  "#9DC4D6",
  "#D4E4F7",
  "#C5D9E9",
  "#AEC6CF",
  "#E6E6FA",
  "#CBFDCB",
  "#E0FFFF",
  "#B0E0E6",
  "#D4DDE9",
  "#E7EBF1",
  "#CCE7EF",
  "#E7F5F8",
  "#CDEBED",
  "#E7F5F6",
  "#D9EDE4",
  "#EAF6F0",
  "#E5F1D9",
  "#F0F7EA",
];

const BusinessAsUsualChart = ({
  data = [],
  selectedScope,
  forecastingConfig,
  onChartRendered,
  sbtiConfig,
  emission,
  setYearData,
  yearData,
  selectedYear,
  selectedIndex,
  organization,
  baselineYear,
  targetBaselineYear,
  targetEmissions,
}) => {
  const el = useRef(null);
  const [max, setMax] = useState(0);

  const userDefinedColors = [
    "#436D5D",
    "#82A78D",
    "#B7B2AA",
    "#82887E",
    "#19FFBC",
    "#4682B4",
    "#87CEEB",
    "#132B15",
  ];

  const currentPreferences =
    emission?.sbtiConfig &&
    JSON.parse(emission?.sbtiConfig)?.filter((data) => data?.show);
  const targets = currentPreferences?.map((data) =>
    data?.type === "sbti" ? data?.label || data?.title : data?.type
  );

  const filteredScope =
    selectedScope === "Scope 1+2+3"
      ? "allScopes"
      : selectedScope === "Scope 1+2"
      ? "scopeOneAndTwo"
      : selectedScope === "Scope 1"
      ? "scopeOne"
      : selectedScope === "Scope 2"
      ? "scopeTwo"
      : "scopeThree";

  let firstLineData;
  let secondLineData;

  if (selectedScope === "Scope 3") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_three_no_fla_uncovered";
    } else firstLineData = "";
    if (targets?.includes("SBTi 1.5C S1+2 & WB2C S3")) {
      secondLineData = "scope_three_no_fla_wbc_uncovered";
    } else secondLineData = "";
  } else if (selectedScope === "Scope 1+2") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_one_and_two_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 1") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_one_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 2") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "scope_two_fla";
    } else firstLineData = "";
    secondLineData = "";
  } else if (selectedScope === "Scope 1+2+3") {
    if (targets?.includes("SBTi 1.5C Aligned")) {
      firstLineData = "all_scope_fla_uncovered";
    } else firstLineData = "";
    if (targets?.includes("SBTi 1.5C S1+2 & WB2C S3")) {
      secondLineData = "all_scope_no_fla_wbc_uncovered";
    } else secondLineData = "";
  } else {
    firstLineData = "";
    secondLineData = "";
  }

  const filteredTarget = "netZero";

  const filteredSbtiData = emission?.sbtiData?.[filteredTarget]?.calculations;

  const filterNewDataScopeOne = filteredSbtiData?.filter(
    (item) => item?.[firstLineData]
  );

  const filterNewDataScopeTwo = filteredSbtiData?.filter(
    (item) => item?.[secondLineData]
  );

  const scopeOneData = filterNewDataScopeOne?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[firstLineData]),
  ]);
  const scopeTwoData = filterNewDataScopeTwo?.map((item) => [
    item.year.toString(),
    convertNegativeToZero(item?.[secondLineData]),
  ]);

  const filteredUserDefinedData = emission?.settings?.filter((data) =>
    targets?.includes(data?.type)
  );

  const filteredUserDefinedDataMap = filteredUserDefinedData?.map(
    (data) => data?.calculations?.[filteredScope]?.["netZero"]
  );

  const userDefinedNames =
    filteredUserDefinedData?.map((data) => data?.type) || [];

  const userDefinedValues =
    filteredUserDefinedDataMap?.map(
      (data) =>
        data?.map((inData) => [
          inData?.year?.toString(),
          inData?.emission_reduction,
        ]) || []
    ) || [];

  useEffect(() => {
    const availableMiscLevers = (
      forecastingConfig.length
        ? forecastingConfig.filter((val) => {
            return (
              val.type &&
              checkIfIsInBau(forecastingConfig, val.type) &&
              data?.find((value) =>
                value?.miscData?.find((lever) => lever?.type === val?.type)
              )
            );
          })
        : []
    ).reverse();
    const selectedScopeKey =
      selectedScope === "Scope 1+2"
        ? "s1s2"
        : selectedScope === "Scope 1"
        ? "s1"
        : selectedScope === "Scope 2"
        ? "s2"
        : selectedScope === "Scope 3"
        ? "s3"
        : "total";
    const _data = data.reduce(
      (
        prev,
        {
          year,
          limitConstrainedReductionEmission,
          limitConstrainedReductionEmission25,
          ...rest
        }
      ) => {
        return {
          year: [...prev.year, `${year}`],
          economicGrowth: [
            ...prev.economicGrowth,
            selectedScopeKey === "total"
              ? rest?.growthProduction
              : rest?.[`${selectedScopeKey}GrowthProduction`],
          ],
          bau: [
            ...prev.bau,
            rest?.[
              `${
                selectedScopeKey === "s1s2" ? "s2" : selectedScopeKey
              }ReductionGridDecarb`
            ],
          ],
          bauHeatingFuels: [
            ...prev.bauHeatingFuels,
            rest?.[
              `${
                selectedScopeKey === "s1s2" ? "s1" : selectedScopeKey
              }ReductionHeatFuel`
            ],
          ],
          bauTransport: [
            ...prev.bauTransport,
            rest?.[
              `${
                selectedScopeKey === "s1s2" ? "s1" : selectedScopeKey
              }ReductionTransport`
            ],
          ],
          residualEmissions: [
            ...prev.residualEmissions,
            rest?.[`${selectedScopeKey}Bau`],
          ],
          scope1ResidualEmissions: [
            ...prev.scope1ResidualEmissions,
            rest?.[`s1Bau`],
          ],
          scope2ResidualEmissions: [
            ...prev.scope2ResidualEmissions,
            rest?.[`s2Bau`],
          ],
          scope3ResidualEmissions: [
            ...prev.scope3ResidualEmissions,
            rest?.[`s3Bau`],
          ],
          limitConstrainedReductionEmission: [
            ...prev.limitConstrainedReductionEmission,
            limitConstrainedReductionEmission,
          ],
          limitConstrainedReductionEmission25: [
            ...prev.limitConstrainedReductionEmission25,
            limitConstrainedReductionEmission25,
          ],
        };
      },
      {
        year: [],
        economicGrowth: [],
        bau: [],
        bauHeatingFuels: [],
        bauTransport: [],
        residualEmissions: [],
        scope1ResidualEmissions: [],
        scope2ResidualEmissions: [],
        scope3ResidualEmissions: [],
        limitConstrainedReductionEmission: [],
        limitConstrainedReductionEmission25: [],
      }
    );

    // const historicalTrend = () => {
    const historicalData = [];
    const difference = Number(baselineYear) - Number(targetBaselineYear);
    const values =
      (_data?.residualEmissions?.find((item) => item !== undefined) -
        targetEmissions) /
      difference;

    if (difference > 0) {
      let value = targetEmissions;
      let year = Number(targetBaselineYear);
      for (let index = 0; index <= difference; index++) {
        historicalData.push([year?.toString(), value]);
        value += values;
        year += 1;
      }
    }

    let option = {
      title: {
        show:
          data.length === 0 &&
          scopeOneData?.length === 0 &&
          scopeTwoData?.length === 0 &&
          userDefinedValues?.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: "15%",
        bottom: 100,
        right: 220,
        left: "10%",
      },
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          let tooltipContent = `<div class="mb-2">${params[0].axisValueLabel}</div>`; // Initialize the tooltip content
          let prevValue = 0;
          params?.reverse().forEach((legend) => {
            tooltipContent += `<div class="d-flex justify-content-between align-items-center mb-1"><div><span class="d-inline-block me-2" style="width: 10px; height: 10px; border-radius: 50%; background-color: ${
              legend.color
            }"></span>
            ${
              legend.seriesName === "Growth in Production/Turnover" ||
              legend.seriesName === "1.5C SBTi (w/ constrain)" ||
              legend.seriesName === "WB2C SBTi (w/ constrain)" ||
              legend.seriesName === "BAU" ||
              legend.seriesName === "Historical Trend" ||
              legend.seriesName === "Residual Emissions" ||
              legend.seriesName === "Scope 1 Residual Emissions" ||
              legend.seriesName === "Scope 2 Residual Emissions" ||
              legend.seriesName === "Scope 3 Residual Emissions"
                ? legend.seriesName
                : `<b>${legend.seriesName}</b>`
            }
            </div><div class="ps-4">${
              legend.seriesName === "Growth in Production/Turnover" ||
              legend.seriesName === "1.5C SBTi (w/ constrain)" ||
              legend.seriesName === "WB2C SBTi (w/ constrain)" ||
              legend.seriesName === "BAU" ||
              legend.seriesName === "Historical Trend" ||
              legend.seriesName === "Residual Emissions" ||
              legend.seriesName === "Scope 1 Residual Emissions" ||
              legend.seriesName === "Scope 2 Residual Emissions" ||
              legend.seriesName === "Scope 3 Residual Emissions"
                ? formatNumber(
                    typeof legend?.value === "number" ||
                      typeof legend?.value === "string"
                      ? Number(legend?.value)?.toFixed()
                      : Number(legend?.value?.[1])?.toFixed(),
                    0
                  )
                : `<b>${formatNumber(
                    typeof legend?.value === "number" ||
                      typeof legend?.value === "string"
                      ? Number(legend?.value)?.toFixed()
                      : Number(legend?.value?.[1])?.toFixed(),
                    0
                  )}</b>`
            }</div></div>`;
            prevValue = legend.value;
          });

          return tooltipContent;
        },
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {},
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          restore: {},
          saveAsImage: { name: `Projections Overview - ${organization?.name}` },
        },
      },
      dataZoom: [{ minValueSpan: 1 }],
      xAxis: {
        type: "category",
        name: "Year",
        nameLocation: "center",
        nameGap: "30",
        scale: true,
        data: _data.year,
      },
      yAxis: {
        type: "value",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: "50",
        min: 0,
        axisLine: { onZero: false },
        name: "Emission (tCO{subscript|2}e)",
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },

      series: [
        ...(scopeOneData && scopeOneData?.length !== 0
          ? [
              {
                name: currentPreferences?.[0]?.title || "SBTi 1.5C Aligned",
                data: scopeOneData,
                type: "line",
                color: "#32CD32",

                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                z: 105,
              },
            ]
          : []),

        ...(scopeTwoData && scopeTwoData?.length !== 0
          ? [
              {
                name:
                  currentPreferences?.[1]?.title || "SBTi 1.5C S1+2 & WB2C S3",
                data: scopeTwoData,
                type: "line",
                color: "#90EE90",
                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                z: 106,
              },
            ]
          : []),

        ...(userDefinedValues.length !== 0
          ? userDefinedValues
              .filter((filteredData) => filteredData)
              .map((data, i) => ({
                name: userDefinedNames[i],
                data: data,
                type: "line",
                color: userDefinedColors[i],
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                targetType: "userDefined",
                symbolSize: 0,
                z: 105,
              }))
          : []),

        // {
        //   name: "Residual Emissions",
        //   data: _data.residualEmissions?.map((val, idx) => {
        //     if (val === undefined) {
        //       return historicalData?.[idx]?.[1];
        //     } else {
        //       return val;
        //     }
        //   }),
        //   symbolSize: 0,
        //   color: "#A6A6A6",
        //   type: "line",
        //   stack: "x",
        //   areaStyle: {
        //     type: "default",
        //     opacity: 1,
        //   },
        //   lineStyle: {
        //     normal: {
        //       opacity: 0,
        //     },
        //   },
        //   z: 100,
        // },
        ...(selectedScope.includes("3")
          ? [
              {
                name: "Scope 3 Residual Emissions",
                data: _data.scope3ResidualEmissions?.map((val, idx) => {
                  if (val === undefined) {
                    return (
                      (historicalData?.[idx]?.[1] / 100) *
                      (selectedScope.includes("2") &&
                      selectedScope.includes("1")
                        ? 33.34
                        : (!selectedScope.includes("2") &&
                            selectedScope.includes("1")) ||
                          (selectedScope.includes("2") &&
                            !selectedScope.includes("1"))
                        ? 50
                        : 100)
                    );
                  } else {
                    return val;
                  }
                }),
                // data: _data.scope3ResidualEmissions,
                symbolSize: 0,
                color: "#858A7F",
                type: "line",
                stack: "x",
                areaStyle: {
                  type: "default",
                  opacity: 1,
                },
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                z: 100,
              },
            ]
          : []),
        ...(selectedScope.includes("2")
          ? [
              {
                name: "Scope 2 Residual Emissions",
                data: _data.scope2ResidualEmissions?.map((val, idx) => {
                  if (val === undefined) {
                    return (
                      (historicalData?.[idx]?.[1] / 100) *
                      (selectedScope.includes("1") &&
                      selectedScope.includes("3")
                        ? 33.34
                        : (!selectedScope.includes("1") &&
                            selectedScope.includes("3")) ||
                          (selectedScope.includes("1") &&
                            !selectedScope.includes("3"))
                        ? 50
                        : 100)
                    );
                  } else {
                    return val;
                  }
                }),
                // data: _data.scope2ResidualEmissions,
                symbolSize: 0,
                color: "#B7B2AA",
                type: "line",
                stack: "x",
                areaStyle: {
                  type: "default",
                  opacity: 1,
                },
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                z: 100,
              },
            ]
          : []),
        ...(selectedScope.includes("1")
          ? [
              {
                name: "Scope 1 Residual Emissions",
                data: _data.scope1ResidualEmissions?.map((val, idx) => {
                  if (val === undefined) {
                    return (
                      (historicalData?.[idx]?.[1] / 100) *
                      (selectedScope.includes("2") &&
                      selectedScope.includes("3")
                        ? 33.34
                        : (!selectedScope.includes("2") &&
                            selectedScope.includes("3")) ||
                          (selectedScope.includes("2") &&
                            !selectedScope.includes("3"))
                        ? 50
                        : 100)
                    );
                  } else {
                    return val;
                  }
                }),
                // data: _data.scope1ResidualEmissions,
                symbolSize: 0,
                color: "#C3C5C9",
                type: "line",
                stack: "x",
                areaStyle: {
                  type: "default",
                  opacity: 1,
                },
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                z: 100,
              },
            ]
          : []),
        ...[
          ...(availableMiscLevers.length
            ? availableMiscLevers.map((val, index) => {
                const chartData = data.map(
                  (curr) =>
                    curr?.miscData?.find((misc) => val?.type === misc?.type)?.[
                      selectedScopeKey === "total"
                        ? `total_reduction`
                        : selectedScopeKey === "s3"
                        ? "s3_reduction"
                        : "reduction"
                    ],
                  0
                );
                return {
                  name: val.title,
                  data: chartData,
                  type: "line",
                  symbolSize: 0,
                  scope: val.scopeOne
                    ? 1
                    : val.scopeThree
                    ? 3
                    : val.scopeTwo
                    ? 2
                    : 0,
                  stack: "x",
                  areaStyle: {
                    type: "default",
                    opacity: 1,
                  },
                  lineStyle: {
                    normal: {
                      opacity: 0,
                    },
                  },
                };
              })
            : []),
          {
            name: "Decarb of Transport",
            data: _data.bauTransport,
            type: "line",
            symbolSize: 0,
            scope: 1,
            stack: "x",
            areaStyle: {
              type: "default",
              opacity: 1,
            },
            lineStyle: {
              normal: {
                opacity: 0,
              },
            },
          },
          {
            name: "Decarb of Heating Fuels",
            data: _data.bauHeatingFuels,
            type: "line",
            symbolSize: 0,
            scope: 1,
            stack: "x",
            areaStyle: {
              type: "default",
              opacity: 1,
            },
            lineStyle: {
              normal: {
                opacity: 0,
              },
            },
          },
          {
            name: "Decarb of Grid Electricity",
            data: _data.bau,
            type: "line",
            symbolSize: 0,
            scope: 2,
            stack: "x",
            areaStyle: {
              type: "default",
              opacity: 1,
            },
            lineStyle: {
              normal: {
                opacity: 0,
              },
            },
          },
        ].sort((a, b) => b.scope - a.scope),
        {
          name: "BAU",
          data: _data.residualEmissions,
          type: "line",
          symbolSize: 0,
          itemStyle: {
            normal: {
              lineStyle: {
                width: 5,
              },
            },
          },
          color: "#FFDC60",
          z: 101,
        },
        ...(targetBaselineYear &&
        baselineYear &&
        Number(targetBaselineYear) < Number(baselineYear)
          ? [
              {
                name: "Historical Trend",
                historicalData: true,
                data: historicalData,
                type: "line",
                symbolSize: 0,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 5,
                    },
                  },
                },
                color: "#000000",
                z: 101,
              },
            ]
          : []),
        {
          name: "Growth in Production/Turnover",
          data: _data.economicGrowth,
          type: "line",
          symbolSize: 0,
          itemStyle: {
            normal: {
              lineStyle: {
                width: 5,
              },
            },
          },
          color: "#5470c6",
          z: 102,
        },
      ],
    };
    option.legend = {
      orient: "vertical",
      top: "center",
      selectedMode: false,
      right: 0,
      formatter: (value) =>
        value.length > 25 ? value.substring(0, 25) + "..." : value,
      tooltip: { show: true },
      data: option.series
        .map((item, index) => ({
          name: item.name,
          icon:
            item.name === "Growth in Production/Turnover" ||
            item.name === "BAU" ||
            item.name === "Historical Trend" ||
            item.name === "WB2C SBTi (w/ constrain)" ||
            item.name === "1.5C SBTi (w/ constrain)"
              ? ""
              : "rect",

          textStyle: {
            fontWeight:
              item.name === "Growth in Production/Turnover" ||
              item.name === "BAU" ||
              item.name === "Historical Trend" ||
              item.name === "WB2C SBTi (w/ constrain)" ||
              item.name === "1.5C SBTi (w/ constrain)" ||
              item.name === "Residual Emissions" ||
              item.name === "Scope 1 Residual Emissions" ||
              item.name === "Scope 2 Residual Emissions" ||
              item.name === "Scope 3 Residual Emissions"
                ? 400
                : 600,
          },
        }))
        .reverse(),
    };

    option.series = option.series.map((item, index) => {
      return {
        ...item,
        ...(item.name !== "Growth in Production/Turnover" &&
        item.name !== "BAU" &&
        item.name !== "Historical Trend" &&
        item.name !== "Residual Emissions" &&
        item.name !== "Scope 1 Residual Emissions" &&
        item.name !== "Scope 2 Residual Emissions" &&
        item.name !== "Scope 3 Residual Emissions" &&
        item.name !== "WB2C SBTi (w/ constrain)" &&
        item.name !== "1.5C SBTi (w/ constrain)" &&
        item.name !== "SBTi 1.5C Aligned" &&
        item.name !== currentPreferences?.[0]?.title &&
        item.name !== "SBTi 1.5C S1+2 & WB2C S3" &&
        item.name !== currentPreferences?.[1]?.title &&
        item?.targetType !== "userDefined"
          ? {
              color: colorsPallete[index],
            }
          : {}),
      };
    });

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 2,
    });

    if (selectedScope !== "Scope 1+2+3") {
      option = {
        ...option,
        series: option.series.filter((val) => {
          const bool = !!forecastingConfig?.find((lever) => {
            if (
              val?.name === "Growth in Production/Turnover" ||
              val?.name === "1.5C SBTi (w/ constrain)" ||
              val?.name === "BAU" ||
              val?.name === "Historical Trend" ||
              val?.name === "Residual Emissions" ||
              val?.name === "Scope 1 Residual Emissions" ||
              val?.name === "Scope 2 Residual Emissions" ||
              val?.name === "Scope 3 Residual Emissions" ||
              val?.name === "WB2C SBTi (w/ constrain)" ||
              val?.name === "SBTi 1.5C Aligned" ||
              val?.name === currentPreferences?.[0]?.title ||
              val?.name === "SBTi 1.5C S1+2 & WB2C S3" ||
              val?.name === currentPreferences?.[1]?.title ||
              val?.targetType === "userDefined"
            ) {
              return true;
            }
            if (
              selectedScope === "Scope 3" &&
              (val?.name === "Decarb of Grid Electricity" ||
                val?.name === "Decarb of Heating Fuels" ||
                val?.name === "Decarb of Transport" ||
                val?.name === "SBTi 1.5C Aligned" ||
                val?.name === currentPreferences?.[0]?.title ||
                val?.name === "SBTi 1.5C S1+2 & WB2C S3" ||
                val?.name === currentPreferences?.[1]?.title ||
                val?.targetType === "userDefined")
            ) {
              return true;
            }

            return (
              lever?.title === val?.name &&
              ((selectedScope === "Scope 1+2" &&
                (lever.scopeOne || lever.scopeTwo)) ||
                (selectedScope === "Scope 1" && lever.scopeOne) ||
                (selectedScope === "Scope 2" && lever.scopeTwo) ||
                (selectedScope === "Scope 3" && lever.scopeThree))
            );
          });

          return !!bool;
        }),
      };

      const idx = option.series.findIndex(
        (val) => val.name === "Residual Emissions"
      );
      const bauIdx = option.series.findIndex((val) => val.name === "BAU");
      option.series[bauIdx] = {
        ...option.series[bauIdx],
        data: data.reduce((prev, { ...rest }) => {
          return [...prev, rest?.[`${selectedScopeKey}Bau`]];
        }, []),
      };

      option.series[idx] = {
        ...option.series[idx],
        data: [...option.series[bauIdx].data]?.map((val, idx) => {
          if (val === undefined) {
            return historicalData?.[idx]?.[1];
          } else {
            return val;
          }
        }),
      };
    }

    if (forecastingConfig) {
      option = {
        ...option,
        series: option.series.filter((val) => {
          let isInBau = true;

          if (val.name === "Decarb of Heating Fuels") {
            isInBau = forecastingConfig.find((bauModule) => {
              return bauModule.link === "heating-fuels-decarbonization";
            })?.isInBau;
          } else if (val.name === "Decarb of Transport") {
            isInBau = forecastingConfig.find((bauModule) => {
              return bauModule.link === "transport-decarbonization";
            })?.isInBau;
          } else if (val.name === "Decarb of Grid Electricity") {
            isInBau = forecastingConfig.find((bauModule) => {
              return bauModule.link === "grid-decarbonization";
            })?.isInBau;
          }

          return isInBau;
        }),
      };
    } else {
      option = {
        ...option,
        series: option.series.filter((val) => {
          return !(
            val.name === "Decarb of Grid Electricity" ||
            val.name === "Decarb of Heating Fuels" ||
            val.name === "Decarb of Transport"
          );
        }),
      };
    }

    const maxValue = Math.max(
      ...[]
        .concat(...option.series.map((val) => val.data))
        .filter((item) => (isArray(item) ? !isNaN(item[1]) : !isNaN(item)))
        .map((item) => (isArray(item) ? item[1] : item))
    );
    if (
      !max ||
      max < 1 ||
      max < maxValue ||
      ![]
        .concat(...option.series.map((val) => val.data))
        .filter((item) => (isArray(item) ? !isNaN(item[1]) : !isNaN(item)))
        .map((item) => (isArray(item) ? item[1] : item))
        .includes(max)
    ) {
      setMax(maxValue);
    }
    option.yAxis.max = max;
    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    onChartRendered
      ? chart.on("finished", (params) => {
          let src = chart.getDataURL({
            pixelRatio: 2,
            backgroundColor: "#fff",
          });

          onChartRendered(src);
        })
      : chart.on("finished", (params) => {
          const data = option?.series
            .map((val) => {
              return {
                year: selectedYear,
                selectedScope: selectedScope,
                data: val.data.find((val, index) => {
                  return isArray(val)
                    ? Number(val[0]) == Number(selectedYear)
                    : index === selectedIndex;
                }),
                name: val.name,
                color: val.color,
              };
            })
            .reverse();
          if (
            !yearData?.length ||
            yearData[0]?.year !== selectedYear ||
            selectedScope !== yearData[0]?.selectedScope ||
            JSON.stringify(yearData) !== JSON.stringify(data)
          ) {
            setYearData(data);
          }
        });

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [
    el,
    data,
    forecastingConfig,
    sbtiConfig,
    max,
    emission,
    selectedYear,
    // onChartRendered,
    // scopeOneData,
    // scopeTwoData,
    selectedScope,
    filteredScope,
    // userDefinedNames,
    // userDefinedValues,
  ]);

  return (
    <div className="BusinessAsUsualChart">
      <div className="w-100" ref={el} />
    </div>
  );
};

export default BusinessAsUsualChart;
